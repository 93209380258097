import { Box } from '@resideo/blueprint-react';
import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { TableLoading } from '../../SharedComponentsModule';
import ServicesTable from './ServicesTable';
import PageHeader from 'SharedComponentsModule/PageHeader';

const ServicesPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageHeader title={<span data-test-services-page-title>{t('Services')}</span>} hideBorder />

      <Box marginX='medium'>
        <Suspense fallback={<TableLoading />}>
          <ServicesTable />
        </Suspense>
      </Box>
    </>
  );
};

export default ServicesPage;
