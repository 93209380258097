import React, { forwardRef } from 'react';
import { Form, Formik, FormikErrors } from 'formik';
import { useTranslation } from 'react-i18next';
import { InputField, SelectField } from '@resideo/blueprint-formik';
import { Box, Button, Flex } from '@resideo/blueprint-react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import PageHeader from 'SharedComponentsModule/PageHeader';

export interface ServiceFormValues {
  name?: string;
  programOffering?: string;
  variation?: string;
  billingCycle?: string;
  orderPrice?: string | number;
  recurringPrice?: string | number;
  recurringPriceDescription?: string;
  sku?: string;
  skuType?: string;
  sapProductCode?: string;
  sapProductId?: string;
  sapRatePlanId?: string;
  partnerProgramId?: string;
  parentProductId?: string;
}

export interface ServiceFormHandlers {
  setDisabled: (isDisabled: boolean) => void;
}

interface ServiceFormProps {
  initialValues?: ServiceFormValues;
  availablePrograms?: { id?: string | null; name?: string | null }[];
  onSubmit: (values: any) => void;
  onCancel?: (values: any) => void;
  create?: boolean;
  editMode?: boolean;
}

const Border = styled.div`
  border: 1px solid #0000001a;
  margin-top: 15px;
  margin-bottom: 15px;
`;

const ServiceForm = forwardRef<ServiceFormHandlers, ServiceFormProps>(
  ({
    onSubmit,
    onCancel,
    initialValues = {},
    availablePrograms = [],
    create = false,
    editMode,
  }: ServiceFormProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const validate = (values: ServiceFormValues) => {
      const errors: FormikErrors<ServiceFormValues> = {};
      if (!values.name || values.name.trim() === '') {
        errors.name = t('Product Name is required');
      }

      if (!values.programOffering || values.programOffering.trim() === '') {
        errors.programOffering = t('Product Offering is required');
      }

      if (!values.sku || values.sku.trim() === '') {
        errors.sku = t('Product SKU is required');
      }

      if (!values.skuType || values.skuType.trim() === '') {
        errors.skuType = t('SKU Type is required');
      }

      if (!values.orderPrice || `${values.orderPrice}`.trim() === '') {
        errors.orderPrice = t('Initial Order Price is required');
      }

      return errors;
    };

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validate}
        enableReinitialize>
        {({ values }) => (
          <Form role='form' noValidate>
            {editMode && (
              <PageHeader
                title={t('Edit Service Info')}
                controls={
                  <Flex>
                    <Button
                      data-test-cancel-btn
                      variant='secondary'
                      marginRight={'medium'}
                      type='button'
                      onClick={
                        create
                          ? () => {
                              navigate('/products');
                            }
                          : onCancel
                      }>
                      {t('Cancel')}
                    </Button>
                    <Button data-test-save-btn variant='primary' type='submit'>
                      {t('Save')}
                    </Button>
                  </Flex>
                }
              />
            )}
            <Box marginX='large'>
              <Box>
                <InputField
                  data-test-product-name-input
                  version='v2'
                  label={t('Product Name')}
                  marginBottom='medium'
                  name='name'
                  type='text'
                  required
                  disabled={!editMode}
                />
              </Box>
              <Flex>
                <Box paddingRight='small' width={[1 / 2]}>
                  <InputField
                    data-test-product-sku-input
                    version='v2'
                    label={t('Product SKU')}
                    marginBottom='medium'
                    name='sku'
                    type='text'
                    required
                    disabled={!editMode}
                  />
                </Box>
                <Box paddingLeft='small' width={[1 / 2]}>
                  <SelectField
                    version='v2'
                    label={t('SKU Type')}
                    marginBottom='medium'
                    name='skuType'
                    value={values.skuType}
                    required
                    disabled={!editMode}>
                    <option value=''>Choose one ...</option>
                    <option value='DIGITAL'>DIGITAL</option>
                    <option value='PHYSICAL'>PHYSICAL</option>
                  </SelectField>
                </Box>
              </Flex>
              <Border />
              <Flex marginBottom='medium'>
                <Box width={[1 / 2]} paddingRight='small'>
                  <SelectField
                    version='v2'
                    label={t('Partner Program')}
                    marginBottom='medium'
                    name='partnerProgramId'
                    value={values.partnerProgramId}
                    disabled={!editMode}>
                    <option value=''>Choose one ...</option>
                    {availablePrograms?.map(pgm => {
                      return (
                        <option key={`${pgm.id}`} value={`${pgm.id}`}>
                          {`${pgm.name}`}
                        </option>
                      );
                    })}
                  </SelectField>
                </Box>
                <Box width={[1 / 2]} paddingLeft='small'>
                  <SelectField
                    version='v2'
                    label={t('Parent Program Product')}
                    marginBottom='medium'
                    name='parentProductId'
                    value={values.parentProductId}
                    disabled={true}>
                    <option value=''>Choose one ...</option>
                  </SelectField>
                </Box>
              </Flex>
              <Border />
              <Flex marginBottom='medium'>
                <Box paddingRight='medium' width={[1 / 3]}>
                  <SelectField
                    version='v2'
                    label={t('Product Offering')}
                    marginBottom='medium'
                    name='programOffering'
                    value={values.programOffering}
                    required
                    disabled={!editMode}>
                    <option value=''>Choose one ...</option>
                    <option value='APP_BRANDING'>APP_BRANDING</option>
                    <option value='AIRCYCLE'>AIRCYCLE</option>
                  </SelectField>
                </Box>
                <Box width={[1 / 3]}>
                  <SelectField
                    version='v2'
                    label={t('Product Variation')}
                    marginBottom='medium'
                    name='variation'
                    value={values.variation}
                    disabled={!editMode}>
                    <option value=''>N/A</option>
                    <option value='BASIC'>BASIC</option>
                    <option value='ADVANCED'>ADVANCED</option>
                  </SelectField>
                </Box>
                <Box paddingLeft='medium' width={[1 / 3]}>
                  <SelectField
                    version='v2'
                    label={t('Billing Cycle')}
                    marginBottom='medium'
                    name='billingCycle'
                    value={values.billingCycle}
                    disabled={!editMode}>
                    <option value=''>N/A</option>
                    <option value='MONTHLY'>MONTHLY</option>
                    <option value='ANNUAL'>ANNUAL</option>
                  </SelectField>
                </Box>
              </Flex>
              <Border />
              <Flex marginBottom='medium'>
                <Box paddingRight='medium' width={[1 / 4]}>
                  <InputField
                    data-test-order-price-input
                    version='v2'
                    label={t('Initial Order Price')}
                    marginBottom='medium'
                    name='orderPrice'
                    type='text'
                    required
                    disabled={!editMode}
                  />
                </Box>
                <Box width={[1 / 4]}>
                  <InputField
                    data-test-recurring-price-input
                    version='v2'
                    label={t('Recurring Price')}
                    marginBottom='medium'
                    name='recurringPrice'
                    type='text'
                    disabled={!editMode}
                  />
                </Box>
                <Box paddingLeft='medium' width={[1 / 2]}>
                  <InputField
                    data-test-recurring-price-desc-input
                    version='v2'
                    label={t('Recurring Price Description')}
                    marginBottom='medium'
                    name='recurringPriceDescription'
                    type='text'
                    disabled={!editMode}
                  />
                </Box>
              </Flex>
              <Border />
              <Flex marginBottom='medium'>
                <Box paddingRight='medium' width={[1 / 4]}>
                  <InputField
                    data-test-product-code-input
                    version='v2'
                    label={t('SAP Product Code')}
                    marginBottom='medium'
                    name='sapProductCode'
                    type='text'
                    disabled={!editMode}
                  />
                </Box>
                <Box width={[1 / 2]}>
                  <InputField
                    data-test-product-uuid-input
                    version='v2'
                    label={t('SAP Product UUID')}
                    marginBottom='medium'
                    name='sapProductId'
                    type='text'
                    disabled={!editMode}
                  />
                </Box>
                <Box paddingLeft='medium' width={[1 / 2]}>
                  <InputField
                    data-test-product-rate-plan-input
                    version='v2'
                    label={t('SAP Rate Plan UUID')}
                    name='sapRatePlanId'
                    type='text'
                    disabled={!editMode}
                  />
                </Box>
              </Flex>
            </Box>
          </Form>
        )}
      </Formik>
    );
  },
);

ServiceForm.displayName = 'ServiceForm';

export default ServiceForm;
