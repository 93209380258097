import { useParams } from 'react-router-dom';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useRisClient } from 'hooks/useRisClient';

export function useUser() {
  const { client } = useRisClient();
  const { id = '' } = useParams<{ id: string }>();

  const { data } = useSuspenseQuery({
    queryKey: ['getPartnerAccount'],
    queryFn: async () => {
      const res = await client.companies.companiesGetCompany(id);
      return res?.data;
    },
  });
  const partnerUser = data;
  const partnerAccountId = partnerUser?.id;
  const companyName = partnerUser?.name;
  return [{ partnerAccountId, companyName }];
}
