import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Breadcrumbs,
  Button,
  SecondaryNavigation,
  TableV2,
  Text,
  useModal,
  Box,
  Heading,
} from '@resideo/blueprint-react';
import { useTranslation } from 'react-i18next';
import { MdEdit } from 'react-icons/md';
import { CompanyDetailBox } from './CompanyDetailBox';
import { formatDateTime } from '../../utils/common';
import DeleteUserModal from '../DeleteUserModalComponent/DeleteUserModal';
import { EditUserModal } from './EditUserModal';
import ResetUserPasswordModal from '../UsersLookupComponent/ResetUserPasswordModal';
import styled from 'styled-components';
import UserLocationDetails from './UserLocationDetails';
import PageHeader from 'SharedComponentsModule/PageHeader';
import { useRisClient } from 'hooks/useRisClient';
import { useSuspenseQuery } from '@tanstack/react-query';
import UserSearch from 'components/UserSearch';
import { ConsumerDeviceLocationModel } from '@resideo/web-integration-services-api-client';

const UserHeaderComponent = styled(Box)`
  display: flex;
  justify-content: space-between;
`;

const UserButtons = styled(Box)`
  display: flex;
  align-items: center;
`;

const UserInfoTab = styled(Box)`
  display: flex;
  flex-wrap: wrap;
`;

const UserInfoLabel = styled(Text)`
  font: normal normal bold 14px/18px Roboto;
`;

const UserInfoText = styled(Text)`
  font: normal normal normal 14px/18px Roboto;
`;

const UserInfoCompanyText = styled(Text)`
  font: normal normal normal 14px/18px Roboto;
  padding-top: 10px;
  display: flex;
`;

const UserInfoField = styled(Box)`
  padding-bottom: 24px;
  flex-basis: 35%;
`;

const UserCompanyField = styled(Box)`
  padding-bottom: 24px;
  flex-basis: 70%;
`;

const EditButton = styled(Button)`
  color: #1c6fb9;
  display: inline-flex;
  margin-right: -66px;
`;

const SearchContainer = styled.div`
  padding: 0 1rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    padding: 0 10rem 1rem 10rem;
  }
`;

const UserDetails = () => {
  const { id: userDetailsId } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const { client } = useRisClient();
  const [userLocations, setUserLocations] = useState<ConsumerDeviceLocationModel[]>([]);
  const [consumerAccountData, setConsumerAccountData] = useState<any[]>([]);
  // location in the drop-down needs to be preserved, when switching between User Info and Location tabs
  // so moving the setLocationName here, instead of having it in userLocationDetails.tsx
  const [locationName, setLocationName] = useState<string>('');
  const userNavObj = [
    {
      text: t('User Info'),
      ref: 'userInfo',
    },
    {
      text: t('Locations'),
      ref: 'locations',
    },
  ];
  const [tabSelected, updateTabSelected] = useState<string>(userNavObj[0].ref); // state for tab selection
  const navigate = useNavigate();
  const { state } = useLocation();

  const { data: userData } = useSuspenseQuery({
    queryKey: ['getUserById', userDetailsId],
    queryFn: async () => {
      const res = await client.users.usersGetUserById(userDetailsId || '');
      return res?.data;
    },
  });

  useEffect(() => {
    if (userData) {
      const tempUserLocations: any[] = [];
      const tempConsumerAccountData: any[] = [];

      userData.consumerUsers?.forEach(consumerUser => {
        consumerUser?.consumerAccount?.locations?.forEach(location => {
          tempUserLocations.push(location);
          tempConsumerAccountData.push(consumerUser?.consumerAccount);
        });
      });

      // Sorting user locations
      tempUserLocations.sort((a, b) => a.name.localeCompare(b.name));

      setUserLocations(tempUserLocations);
      setConsumerAccountData(tempConsumerAccountData);
    } else {
      navigate('/users');
    }
  }, [userData]);

  const {
    Modal: RemoveModal,
    openModal: openRemoveModal,
    closeModal: closeRemoveModal,
  } = useModal({ newDesign: true });

  const {
    Modal: ResetPasswordModal,
    openModal: openResetPasswordModal,
    closeModal: closeResetPasswordModal,
  } = useModal({ newDesign: true });

  const { Modal: EditModal, openModal: openEditModal, closeModal: closeEditModal } = useModal({
    newDesign: true,
  });

  const showUserTypes = () => {
    const consumerUsers = userData?.consumerUsers?.length || 0;
    const partnerUsers = userData?.partnerUsers?.length || 0;

    if (consumerUsers && partnerUsers) {
      return t('Partner, Consumer');
    } else if (consumerUsers) {
      return t('Consumer');
    } else if (partnerUsers) {
      return t('Partner');
    } else {
      return t('Undefined');
    }
  };

  return (
    <>
      <div>
        <SearchContainer>
          <UserSearch />
        </SearchContainer>
        <PageHeader
          title={`${userData?.firstName} ${userData?.lastName}`}
          breadcrumbs={
            <Breadcrumbs>
              <Link to='/users'>{t('User Accounts')}</Link>
              {state?.prevPath === '/users/results' && (
                <Link to={`${state.prevPath}${state.search || ''}`}>{t('Results')}</Link>
              )}
              <Link to='#'>{`${userData?.firstName} ${userData?.lastName}`}</Link>
            </Breadcrumbs>
          }
          controls={
            <UserButtons>
              <Button
                data-test-remove-user
                variant='destructiveSecondary'
                style={{ marginRight: '15px' }}
                onClick={openRemoveModal}>
                {t('Delete User')}
              </Button>

              <Button data-test-reset-password onClick={openResetPasswordModal}>
                {t('Reset Password')}
              </Button>
            </UserButtons>
          }
          secondaryNavigation={
            userLocations?.length ? (
              <SecondaryNavigation
                navItems={userNavObj}
                onTabSelect={tab => updateTabSelected(tab)}
              />
            ) : null
          }
        />
      </div>

      <>
        {tabSelected === 'userInfo' && (
          <>
            <Box marginX='large'>
              <UserHeaderComponent>
                <Heading
                  as='h2'
                  paddingTop='small'
                  paddingBottom='medium'
                  fontSize='21px'
                  fontFamily='normal normal bold 21px/32px Roboto'
                  letterSpacing='0.27px'>
                  {t('User Info')}
                </Heading>
                <EditButton data-test-edit variant='link' onClick={openEditModal}>
                  <MdEdit />
                  <Text color={'#1c6fb9'} paddingLeft={'5px'}>
                    {t('Edit')}
                  </Text>
                </EditButton>
              </UserHeaderComponent>
              <UserInfoTab>
                <UserInfoField>
                  <UserInfoLabel>{t('Name')}</UserInfoLabel>
                  <UserInfoText data-test-name>
                    {userData?.firstName} {userData?.lastName}
                  </UserInfoText>
                </UserInfoField>
                <UserInfoField>
                  <UserInfoLabel>{t('User Type')}</UserInfoLabel>
                  <UserInfoText data-test-user-type>{showUserTypes()}</UserInfoText>
                </UserInfoField>
                <UserInfoField>
                  <UserInfoLabel>{t('Email')}</UserInfoLabel>
                  <UserInfoText data-test-email>{userData?.contactEmail}</UserInfoText>
                </UserInfoField>
                <UserInfoField>
                  <UserInfoLabel>{t('Created')}</UserInfoLabel>
                  <UserInfoText data-test-created-at>
                    {formatDateTime(userData?.createdAt || '')}
                  </UserInfoText>
                </UserInfoField>
                <UserInfoField>
                  <UserInfoLabel>{t('Country')}</UserInfoLabel>
                  <UserInfoText data-test-country>{userData?.countryCode}</UserInfoText>
                </UserInfoField>
                <UserInfoField>
                  <UserInfoLabel>{t('Updated')}</UserInfoLabel>
                  <UserInfoText data-test-updated-at>
                    {formatDateTime(userData?.updatedAt || '')}
                  </UserInfoText>
                </UserInfoField>
                <UserInfoField>
                  <UserInfoLabel>{t('Phone')}</UserInfoLabel>
                  <UserInfoText data-test-phone>{userData?.primaryPhoneNumber}</UserInfoText>
                </UserInfoField>
                <UserCompanyField>
                  {userData?.partnerUsers?.length ? (
                    <>
                      <Heading
                        as='h2'
                        paddingTop='small'
                        paddingBottom='small'
                        fontSize='21px'
                        fontFamily='normal normal bold 21px/32px Roboto'
                        letterSpacing='0.27px'>
                        {t('Companies')}
                      </Heading>

                      <UserInfoCompanyText data-test-company>
                        {userData?.partnerUsers?.map(company => {
                          const randKey = Math.floor(Math.random() * 100);
                          return (
                            <CompanyDetailBox key={randKey} company={company} user={userData} />
                          );
                        })}
                      </UserInfoCompanyText>
                    </>
                  ) : (
                    ''
                  )}
                </UserCompanyField>
              </UserInfoTab>

              <Heading
                as='h2'
                paddingTop='small'
                paddingBottom='medium'
                fontSize='21px'
                fontFamily='normal normal bold 21px/32px Roboto'
                letterSpacing='0.27px'>
                {t('Terms')}
              </Heading>
            </Box>
            <Box marginX='medium'>
              <TableV2
                actionsButtonName={t('Options')}
                headers={[
                  { displayName: t('Version'), isSortable: false },
                  { displayName: t('Date Accepted'), isSortable: false },
                ]}
                rows={[
                  {
                    version: userData?.acceptedTermsVersion,
                    dateAccepted: formatDateTime(userData?.createdAt || 0),
                  },
                ]}></TableV2>
            </Box>

            <>
              <RemoveModal title={t('Delete User')}>
                <DeleteUserModal user={userData} closeModal={closeRemoveModal} />
              </RemoveModal>

              <EditModal title={t('Edit User Info')}>
                <EditUserModal user={userData} closeModal={closeEditModal} />
              </EditModal>

              <ResetPasswordModal title={t('Reset Password')}>
                <ResetUserPasswordModal user={userData} closeModal={closeResetPasswordModal} />
              </ResetPasswordModal>
            </>
          </>
        )}

        {tabSelected === 'locations' && (
          <UserLocationDetails
            consumerAccountData={consumerAccountData}
            userLocations={userLocations}
            setLocationName={setLocationName}
            locationName={locationName}
          />
        )}
      </>
    </>
  );
};

export default UserDetails;
