import React, { Suspense } from 'react';
import { Box } from '@resideo/blueprint-react';
import { useTranslation } from 'react-i18next';
import { TableLoading } from '../../SharedComponentsModule';
import ProductsTable from './ProductsTable';
import PageHeader from 'SharedComponentsModule/PageHeader';

const ProductsPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageHeader title={<span data-test-product-page-title>{t('Products')}</span>} hideBorder />

      <Box marginX='medium'>
        <Suspense fallback={<TableLoading />}>
          <ProductsTable />
        </Suspense>
      </Box>
    </>
  );
};

export default ProductsPage;
