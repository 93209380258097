import { Box } from '@resideo/blueprint-react';
import useStateData from 'hooks/useStateData';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

type LocationAddress = {
  address1: string;
  address2: string;
  city: string;
  state: string;
  country: string;
  zip: string;
};

type AddressPart = {
  long_name: string;
  short_name: string;
  types: string[];
};

const autocompleteOptions = {
  fields: ['address_components'],
};

const addressLookupMap = {
  streetNumber: ['street_number', 'long_name'],
  street: ['route', 'long_name'],
  address2: ['subpremise', 'long_name'],
  city: ['locality', 'long_name'],
  state: ['administrative_area_level_1', 'short_name'],
  country: ['country', 'short_name'],
  zip: ['postal_code', 'short_name'],
};

const AutocompleteWrapper = styled(Box)`
  display: grid;
`;

const AutocompleteStyles = styled.input`
  height: 44px;
  padding: 0 12px;
`;

const AutocompleteLabelStyles = styled.label`
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 8px;
`;

const AutocompleteError = styled(Box)`
  color: #d22730;
  font-size: 0.75rem;
  padding-top: 0.25rem;
`;

const Autocomplete = ({
  initialValue,
  setCountry,
  setStateValue,
  fieldName,
  label,
  setFieldValue,
  setFieldTouched,
  touched,
  errors,
  setAutoCompleteSelected,
}) => {
  const autoCompleteRef = useRef<any>();
  const inputRef = useRef<any>();
  const [autocompleteValue, setAutocompleteValue] = useState(initialValue || '');
  const { t } = useTranslation();
  const { stateData } = useStateData();

  const findAddressPart = (address: AddressPart[], lookup: string) => {
    const addressLookup = addressLookupMap[lookup];

    const addressPart = address?.find(
      part => part?.types?.findIndex(type => type === addressLookup[0]) > -1,
    );

    return addressPart ? (addressPart[addressLookup[1]] as string) : null;
  };

  const formatAddress = (address: AddressPart[] | null) => {
    if (!address) return null;

    const city = findAddressPart(address, 'city') || '';
    const country = findAddressPart(address, 'country') || '';
    const streetNumber = findAddressPart(address, 'streetNumber') || '';
    const street = findAddressPart(address, 'street') || '';
    const address2 = findAddressPart(address, 'address2') || '';
    const zip = findAddressPart(address, 'zip') || '';

    let state = findAddressPart(address, 'state') || '';

    const foundState = stateData?.find(
      s =>
        s.name.replace(/[\s-]+/g, '') === state.replace(/[\s-]+/g, '') ||
        s.iso2 === state.replace(/[\s-]+/g, ''),
    );

    state = foundState?.iso2 || null;

    const address1 = (streetNumber + ' ' + street).trim();

    return {
      address1,
      address2,
      city,
      state,
      country,
      zip,
    } as LocationAddress;
  };

  const handlePlaceChanged = setFieldValue => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    autoCompleteRef.current = new google.maps.places.Autocomplete(
      inputRef.current,
      autocompleteOptions,
    );
    autoCompleteRef?.current?.addListener('place_changed', async function() {
      const addressObject = await autoCompleteRef?.current?.getPlace();
      const addressComponents = addressObject?.address_components?.length
        ? (addressObject?.address_components as AddressPart[])
        : null;
      const formattedAddress = formatAddress(addressComponents);

      if (formattedAddress) {
        const fieldValues = Object.keys(formattedAddress);

        fieldValues.forEach(fieldValue => {
          setFieldValue(fieldValue, formattedAddress[fieldValue]);
        });
        setAutoCompleteSelected(true);
        setAutocompleteValue(formattedAddress?.address1);
        setCountry(formattedAddress?.country);
        setStateValue(formattedAddress?.state);
      }
    });
  };

  useEffect(() => {
    handlePlaceChanged(setFieldValue);
  }, [setFieldValue]);

  return (
    <AutocompleteWrapper data-test-address-address1 marginBottom={['medium']}>
      <AutocompleteLabelStyles htmlFor={fieldName}>{label} *</AutocompleteLabelStyles>
      <AutocompleteStyles
        style={
          touched[fieldName] && errors[fieldName]
            ? { borderColor: '#d22730', borderWidth: '1px' }
            : undefined
        }
        ref={inputRef}
        id={fieldName}
        name={fieldName}
        value={autocompleteValue}
        onChange={e => {
          setAutocompleteValue(e.target.value);
          setFieldValue(fieldName, e.target.value);
          setAutoCompleteSelected(false);
        }}
        onBlur={() => {
          setFieldTouched(fieldName, true, true);
        }}
        type='text'
        placeholder=''
        required
      />
      {touched[fieldName] && errors[fieldName] && (
        <AutocompleteError role='alert'>{label + t(' is required')}</AutocompleteError>
      )}
    </AutocompleteWrapper>
  );
};

export default Autocomplete;
