import React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Text, Button } from '@resideo/blueprint-react';
import styled from 'styled-components';

export const ButtonContainer = styled(Flex)`
  flex-direction: row;
  gap: 16px;
  justify-content: flex-end;
  padding-top: 35px;
`;

export const RemoveCustomerConfirmationModal = ({ onCancel, onSubmit }) => {
  const { t } = useTranslation();

  return (
    <Flex flexDirection='column'>
      <Text
        data-test-remove-customer-modal
        marginBottom='medium'
        fontSize='medium'
        data-test-customer-removal-modal-text>
        {t(
          'Removing a customer will remove all their data including locations, devices and services.',
        )}
      </Text>
      <ButtonContainer>
        <Button
          data-test-cancel-button
          variant='secondary'
          onClick={onCancel}
          data-test-customer-removal-modal-cancel>
          {t('Cancel')}
        </Button>
        <Button
          data-test-remove-button
          variant='destructivePrimary'
          onClick={onSubmit}
          data-test-customer-removal-modal-remove>
          {t('Remove')}
        </Button>
      </ButtonContainer>
    </Flex>
  );
};
