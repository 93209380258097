import React from 'react';
import { Outlet, Navigate, RouteObject } from 'react-router-dom';
import { withRoute } from '../src/RouterModule/Route';
import companiesRoutes, { CompaniesModule } from './CompaniesModule';
import usersRoutes, { UsersModule } from './UsersModule';
import productsRoutes, { ProductsModule } from './ProductsModule';
import servicesRoutes, { ServicesModule } from 'ServicesModule';
import partnerCustomersRoutes, {
  PartnerCustomersModule,
} from 'PartnerCustomersModule/PartnerCustomersModule';

export const ProtectedRoutesLayout = () => {
  return <Outlet />;
};

const routes: RouteObject[] = [
  {
    index: true,
    Component: () => withRoute(() => <Navigate to='/companies' replace />),
  },
  {
    path: 'companies/*',
    Component: () => withRoute(CompaniesModule),
    children: companiesRoutes,
  },
  {
    path: 'customers/*',
    Component: () => withRoute(PartnerCustomersModule),
    children: partnerCustomersRoutes,
  },
  {
    path: 'users/*',
    Component: () => withRoute(UsersModule),
    children: usersRoutes,
  },
  {
    path: 'products/*',
    Component: () => withRoute(ProductsModule),
    children: productsRoutes,
  },
  {
    path: 'services/*',
    Component: () => withRoute(ServicesModule),
    children: servicesRoutes,
  },
];

export default routes;
