import React, { FC } from 'react';
import styled from 'styled-components';
import { Box, Flex, Text } from '@resideo/blueprint-react';

const AvatarBox = styled(Box)`
  border-radius: ${({ theme }) => theme.radii.circle};
  background-color: ${({ theme }) => theme.colors.background};
  min-width: ${({ variant }) => (variant === 'small' ? '20px' : '65px')};
`;

const StyledText = styled(Text)`
  font-size: ${({ variant }) => (variant === 'small' ? '1rem' : '1.5rem')};
`;

type ProfileAvatarProps = {
  firstName?: string;
  lastName?: string;
  size?: number;
  variant?: string;
};

const ProfileAvatar: FC<ProfileAvatarProps> = ({ firstName, lastName, variant }): JSX.Element => {
  const firstInitial = firstName?.[0] || '';
  const lastInitial = lastName?.[0] || '';

  return (
    <Flex data-test-profile-avatar>
      <AvatarBox
        data-test-user-initials-avatar
        size={variant === 'small' ? '36px' : '65px'}
        variant={variant}>
        <Flex flexDirection='row' justifyContent='center' alignItems='center' height='100%'>
          <StyledText variant={variant}>{firstInitial}</StyledText>
          <StyledText variant={variant}>{lastInitial}</StyledText>
        </Flex>
      </AvatarBox>
    </Flex>
  );
};

export default ProfileAvatar;
