// This file should only contain routes to modules.
// Modules will represent building blocks (miniature apps) that compose the larger application.

import React, { ComponentType, lazy } from 'react';
import SignOut from './SignOut';
import { withAnonymousRoute } from '../src/RouterModule/Route';

import protectedRoutes, { RoutesWithNavigationLayout } from './RoutesWithNavigationLayout';
import { createBrowserRouter } from 'react-router-dom';
import Root from 'Root';

type LazyModulePromise = Promise<{ default: ComponentType<any> }>;
const SignIn = lazy((): LazyModulePromise => import('./SignIn'));

const router = createBrowserRouter([
  {
    element: <Root />,
    children: [
      {
        path: '/*',
        element: withAnonymousRoute(RoutesWithNavigationLayout),
        children: protectedRoutes,
      },
      {
        path: 'sign-out',
        element: withAnonymousRoute(SignOut),
      },
      {
        path: 'sign-in',
        Component: () => withAnonymousRoute(SignIn),
      },
    ],
  },
]);

export default router;
