import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { Breadcrumbs } from '@resideo/blueprint-react';
import ProductDetails from './ProductDetails';
import PageHeader from 'SharedComponentsModule/PageHeader';
import { useRisClient } from 'hooks/useRisClient';
import { useSuspenseQuery } from '@tanstack/react-query';

const ProductShow = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [data, setData] = useState<any>();

  const { client } = useRisClient();

  const { data: allProducts } = useSuspenseQuery({
    queryKey: ['getProducts'],
    queryFn: async () => (await client.core.coreGetProductBySku()).data as any,
  });

  const getSpecificData = allProducts?.find(p => p?.id === id);

  useEffect(() => {
    if (getSpecificData) {
      setData(getSpecificData);
    }
  }, [setData]);

  const getStringValue = value => {
    return value || '';
  };

  const getArrayValue = value => {
    return value || [];
  };

  const initialValues = {
    id: getStringValue(data?.id),
    productName: getStringValue(data?.productName),
    productCategories: getArrayValue(data?.productCategories),
    productFamily: getStringValue(data?.productFamily),
    productPlatform: getStringValue(data?.productPlatform),
    isDIY: data?.isDIY ? data?.isDIY.toString() : 'false',
    retireDate: getStringValue(data?.retireDate),
    sku: getStringValue(data?.sku),
    launchDate: getStringValue(data?.launchDate),
    internalProductName: getStringValue(data?.internalProductName),
  };

  return (
    <>
      <PageHeader
        title={data?.productName}
        breadcrumbs={
          <Breadcrumbs>
            <Link to='/products'>{t('Products')}</Link>
            <Link to='#'>{data?.productName}</Link>
          </Breadcrumbs>
        }
      />

      <ProductDetails initialValues={initialValues} />
    </>
  );
};
export default ProductShow;
