import { useEffect, useRef } from 'react';

export const ScrollToTop = () => {
  const mounted = useRef(false);

  useEffect(() => {
    if (!mounted.current) {
      //componentDidMount
      mounted.current = true;
    } else {
      //componentDidUpdate
      window.scrollTo(0, 0);
    }
  });

  return null;
};
