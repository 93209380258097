import React, { useEffect } from 'react';
import { withAnonymousRoute } from '../src/RouterModule/Route';
import { PageError } from './SharedComponentsModule';

import { Outlet, RouteObject, useLocation } from 'react-router-dom';
import NavigationLayout from './NavigationLayout';
import protectedRoutes, { ProtectedRoutesLayout } from './ProtectedRoutes';
import config from 'config';
import { useAuth } from 'utils/auth';
import { RedirectLoginOptions } from '@auth0/auth0-spa-js';

const { auth0Connection } = config;

export const RoutesWithNavigationLayout = () => {
  const { isAuthenticated, loginWithRedirect, isLoading } = useAuth();
  const location = useLocation();
  const defaultReturnTo = (): string => `${location.pathname}${location.search}`;

  const returnTo = defaultReturnTo;
  const loginOptions: RedirectLoginOptions = {
    connection: auth0Connection,
  };

  useEffect(() => {
    if (isLoading || isAuthenticated) {
      return;
    }
    const opts = {
      ...loginOptions,
      appState: {
        ...loginOptions.appState,
        returnTo: typeof returnTo === 'function' ? returnTo() : returnTo,
      },
    };
    (async (): Promise<void> => {
      await loginWithRedirect(opts);
    })();
  }, [isLoading, isAuthenticated, loginWithRedirect, loginOptions, returnTo]);

  return (
    <NavigationLayout>
      <Outlet />
    </NavigationLayout>
  );
};

const routes: RouteObject[] = [
  {
    path: '*',
    Component: () => withAnonymousRoute(ProtectedRoutesLayout),
    children: protectedRoutes,
  },
  {
    path: '*',
    Component: () => withAnonymousRoute(PageError),
  },
];

export default routes;
