import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Flex, TableV2, useModal, useToastContext } from '@resideo/blueprint-react';
import { formatDateTime } from '../../../../../utils/common';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation, useSuspenseQuery, useQueryClient } from '@tanstack/react-query';
import { useRisClient } from '../../../../../hooks/useRisClient';
import { PRO_PORTAL_URL, RESIDEO_ID_SIGNUP, RESIDEO_ID_URL } from '../../../../../config';

const MAX_EMPLOYEES_LOAD = 1000;
const RESULTS_PER_PAGE = 15;

const InvitedEmployeesTable: FC = () => {
  const { t } = useTranslation();
  const [touchedEmployee, setTouchedEmployee] = useState<any>({});
  const [page, setPage] = useState(0);
  const [orderBy, setOrderBy] = useState<any>([]);
  const { Modal, openModal, closeModal } = useModal({ newDesign: true });
  const { addToast } = useToastContext();
  const { client } = useRisClient();
  const queryClient = useQueryClient();

  const { id } = useParams<{ id: string }>();

  const { data } = useSuspenseQuery({
    queryKey: ['invitedPartnerUsers'],
    queryFn: async () =>
      (
        await client.companies.companiesSearchInvitedEmployees(
          id || '',
          '[{"status": "PENDING"}]',
          orderBy,
          MAX_EMPLOYEES_LOAD,
        )
      ).data,
  });

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: ['invitedPartnerUsers'] });
  }, [orderBy]);

  const VALUESTOTEXT = {
    TECHNICIAN: t('Technician'),
    COMPANY_ADMIN: t('Company Admin'),
    ARCHIVED: t('Archived'),
    ACTIVE: t('Active'),
    OWNER: t('Owner'),
  };

  const inviteSend = useMutation({
    mutationFn: async (vars: { inviteId }) => {
      const expiresAt = new Date();
      expiresAt.setDate(expiresAt.getDate() + 7);

      return client.companies.companiesSendEmployeeInvite(vars.inviteId, id || '', {
        templateLanguage: 'en',
        baseUrl: RESIDEO_ID_SIGNUP ? `${RESIDEO_ID_URL}/sign-up` : `${PRO_PORTAL_URL}/sign-up`,
        expiresAt: expiresAt.toISOString(),
      });
    },
    onError: () => {
      addToast({
        toastType: 'Error',
        message: t('Failed to send the invite. Please try again.'),
      });
    },
    onSuccess: () => {
      addToast({ toastType: 'Success', message: t('Invite sent.') });
      queryClient.invalidateQueries({ queryKey: ['invitedPartnerUsers'] });
    },
  });

  const inviteReject = useMutation({
    mutationFn: async (vars: { inviteId }) => {
      return client.companies.companiesCancelEmployeeInvite(id || '', vars.inviteId);
    },
    onError: () => {
      addToast({ toastType: 'Error', message: t('Failed to cancel the invitation.') });
    },
    onSuccess: () => {
      addToast({ toastType: 'Success', message: t('Invitation Cancelled') });
      queryClient.invalidateQueries({ queryKey: ['invitedPartnerUsers'] });
    },
  });

  const inviteSendHandle = inviteId => {
    const expiresAt = new Date();
    expiresAt.setDate(expiresAt.getDate() + 7);

    inviteSend.mutate({
      inviteId: inviteId,
    });
  };

  const inviteRejectHandle = inviteId => {
    inviteReject.mutate({
      inviteId: inviteId,
    });
  };

  const generateRows = () => {
    return (data as any)
      ?.slice(page * RESULTS_PER_PAGE, page * RESULTS_PER_PAGE + RESULTS_PER_PAGE)
      .map(invitedUser => {
        const { id, sendToEmail, sentAt, expiresAt } = invitedUser.invite;
        const role = invitedUser.roles[0];
        return {
          id,
          email: sendToEmail,
          role: VALUESTOTEXT[role],
          sentAt: formatDateTime(sentAt, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          }),
          expiresAt: formatDateTime(expiresAt, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          }),
          actions: [
            {
              name: 'cancel',
              text: t('Cancel'),
              action: () => {
                setTouchedEmployee(invitedUser.invite);
                openModal();
              },
            },
            {
              name: 'resend',
              text: t('Resend'),
              action: () => {
                inviteSendHandle(id || '');
              },
            },
          ],
        };
      });
  };

  const pageInfo = {
    hasNextPage: page * RESULTS_PER_PAGE + RESULTS_PER_PAGE < ((data as any)?.length || 0),
    hasPreviousPage: page !== 0,
  };

  const getOrderBy = (sortColumn, sortDirection) => {
    if (!sortColumn) {
      return;
    }

    return JSON.stringify({ [sortColumn]: sortDirection });
  };

  return (
    <div data-test-company-employees-list>
      <TableV2
        actionsButtonName={t('Options')}
        headers={[
          {
            displayName: t('ID'),
            isSortable: false,
            isHiddenColumn: true,
          },
          {
            displayName: t('Email'),
            isSortable: true,
            onSortColumnName: 'sendToEmail',
          },
          {
            displayName: t('Role'),
            isSortable: false,
            onSortColumnName: 'role',
          },
          {
            displayName: t('Sent'),
            isSortable: true,
            onSortColumnName: 'sentAt',
          },
          {
            displayName: t('Expires'),
            isSortable: false,
            onSortColumnName: 'expiresAt',
          },
          {
            displayName: '',
            isSortable: false,
          },
        ]}
        rows={generateRows()}
        NoDataMessage={t('No Invited Employees')}
        hasPagination
        hasNext={pageInfo?.hasNextPage}
        hasPrevious={pageInfo?.hasPreviousPage}
        nextButtonLabel={t('Next')}
        previousButtonLabel={t('Previous')}
        onPaginationNextClick={() => {
          setPage(prev => prev + 1);
        }}
        onPaginationPrevClick={() => {
          setPage(prev => prev - 1);
        }}
        onSort={(sortColumn, sortDirection) => {
          setPage(0);
          setOrderBy(getOrderBy(sortColumn, sortDirection));
        }}
      />

      <Modal title={t('Cancel Invite')}>
        <Trans>
          <p>
            The invite sent to <strong>{touchedEmployee?.sendToEmail}</strong> will be cancelled. If
            the user clicks on the link in the invite, they will be notified that the invitation is
            no longer valid.
          </p>
        </Trans>
        <Flex>
          <Button
            style={{ marginRight: '25px' }}
            type='button'
            variant='secondary'
            onClick={() => {
              setTouchedEmployee(null);
              closeModal();
            }}>
            {t('Go Back')}
          </Button>
          <Button
            type='button'
            variant='destructivePrimary'
            onClick={() => {
              inviteRejectHandle(touchedEmployee?.id);
              closeModal();
            }}>
            {t('Cancel Invite')}
          </Button>
        </Flex>
      </Modal>
    </div>
  );
};

export default InvitedEmployeesTable;
