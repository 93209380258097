import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { useAuth } from 'utils/auth';

const SentryUser = () => {
  const { getIdTokenClaims } = useAuth();

  useEffect(() => {
    getIdTokenClaims().then(data => {
      if (!data) {
        Sentry.setUser(null);
        return;
      }

      const { sub, aud } = data;
      const email = sub?.substring(sub.indexOf('|') + 1);

      Sentry.setUser({
        id: aud,
        email: email,
      });
    });
  }, [getIdTokenClaims]);

  return null;
};

export default SentryUser;
