import React, { Suspense, useEffect, useState } from 'react';
import { Box, Breadcrumbs } from '@resideo/blueprint-react';
import { useTranslation } from 'react-i18next';
import UsersLookupTable from './UsersLookupTable';
import { TableLoading } from '../../SharedComponentsModule';
import { useQueryParams } from 'hooks/useQueryParams';
import PageHeader from 'SharedComponentsModule/PageHeader';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import UserSearch from 'components/UserSearch';

const CurrentPageBreadcrumb = styled.span`
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  height: 15px;
  white-space: nowrap;
  line-height: 15px;
  color: #767676;
`;

const SearchContainer = styled.div`
  padding: 0 1rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    padding: 0 10rem 1rem 10rem;
  }
`;

export type Option = { key: UserDetailSearchType; value: string; label: string };

enum UserDetailSearchType {
  EMAIL = 'EMAIL',
  MAC_ADDRESS = 'MAC_ADDRESS',
  NAME = 'NAME',
}

export const filterByMap = {
  Email: UserDetailSearchType.EMAIL,
  Name: UserDetailSearchType.NAME,
  'MAC/Device ID': UserDetailSearchType.MAC_ADDRESS,
  EMAIL: UserDetailSearchType.EMAIL,
  NAME: UserDetailSearchType.NAME,
  MAC_ADDRESS: UserDetailSearchType.MAC_ADDRESS,
};

const UserResults = () => {
  const { t } = useTranslation();
  const { searchParams } = useQueryParams();
  const [filter, setFilter] = useState<string | undefined>(searchParams.get('filter') || undefined);
  const [filterBy, setFilterBy] = useState<UserDetailSearchType | undefined>(() => {
    const key = searchParams.get('filterBy');
    if (key) return filterByMap[key];
    return undefined;
  });

  useEffect(() => {
    if (searchParams) {
      setFilter(searchParams.get('filter') || undefined);
      setFilterBy(() => {
        const key = searchParams.get('filterBy');
        if (key) return filterByMap[key];
        return undefined;
      });
    }
  }, [searchParams]);

  return (
    <div>
      <div>
        <SearchContainer>
          <UserSearch />
        </SearchContainer>
        <PageHeader
          title={<span data-test-users-title>{t('Results')}</span>}
          hideBorder
          breadcrumbs={
            <Breadcrumbs>
              <Link to='/users'>{t('User Accounts')}</Link>
              <CurrentPageBreadcrumb>{t('Results')}</CurrentPageBreadcrumb>
            </Breadcrumbs>
          }
        />
      </div>

      <Box marginX='medium'>
        <Suspense fallback={<TableLoading />}>
          <UsersLookupTable filter={filter} filterBy={filterBy} />
        </Suspense>
      </Box>
    </div>
  );
};

export default UserResults;
