import {
  OpenAPIConfig,
  IntegrationServicesClient,
} from '@resideo/web-integration-services-api-client';
import { RIS_BASE_URL } from 'config';
import { useAuth } from 'utils/auth';

export const useRisClient = () => {
  const { getAccessTokenSilently } = useAuth();

  const config: Partial<OpenAPIConfig> = {
    BASE: RIS_BASE_URL,
    TOKEN: async () => {
      return await getAccessTokenSilently();
    },
  };

  return { client: new IntegrationServicesClient(config) };
};
