import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EditServiceForm from './EditServiceForm';
import { Button, Flex, Text } from '@resideo/blueprint-react';
import styled from 'styled-components';
import { FaPen } from 'react-icons/fa';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useRisClient } from 'hooks/useRisClient';

const ServiceInfoHeader = styled(Flex)`
  padding-left: 30px;
  justify-content: space-between;
`;

const Border = styled.div`
  border: 1px solid #0000001a;
  margin-right: 15px;
  margin-left: 15px;
`;

const PenIcon = styled(FaPen)`
  margin-right: 8px;
`;

const ServiceHeaders = styled(Text)`
  font-weight: bold;
  padding-bottom: 5px;
`;

const ServiceValues = styled(Text)`
  padding-bottom: 15px;
`;

const ServiceDetailsContainer = styled(Flex)`
  padding-top: 25px;
`;

const ServiceDetailsRows = styled(Flex)`
  flex-direction: row;
  gap: 50px;
`;

const ServiceDetails = ({ headerVisible, initialValues }) => {
  const { t } = useTranslation();
  const { client } = useRisClient();
  const [editMode, setEditMode] = useState(false);
  const [availablePrograms, setAvailablePrograms] = useState<any[]>([]);
  const { data: allPartnerPrograms } = useSuspenseQuery({
    queryKey: ['partnerPrograms'],
    queryFn: async () => {
      const res = await client.core.coreGetPartnerPrograms();
      return res.data;
    },
  });

  useEffect(() => {
    setAvailablePrograms(allPartnerPrograms ?? []);
  }, [allPartnerPrograms]);

  useEffect(() => {
    //show sticky header if on edit mode, otherwise hide it
    if (headerVisible) {
      headerVisible(!editMode);
    }
  }, [editMode]);

  const getPartnerProgramName = allPartnerPrograms?.find(
    p => p?.id == initialValues?.partnerProgramId,
  );

  return (
    <>
      {editMode ? (
        <EditServiceForm
          initialValues={initialValues}
          availablePrograms={availablePrograms}
          onCancel={() => setEditMode(false)}
          editMode={true}
        />
      ) : (
        <main style={{ backgroundColor: 'white' }}>
          <ServiceInfoHeader>
            <h2>{t('Service Info')}</h2>
            <Button
              data-test-edit-btn
              paddingRight='0rem'
              variant='tertiary'
              onClick={() => setEditMode(true)}>
              <PenIcon />
              {t('Edit')}
            </Button>
          </ServiceInfoHeader>
          <Border />
          <div style={{ padding: '15px 30px' }}>
            <ServiceHeaders data-test-product-name-header>{t('Product Name')}</ServiceHeaders>
            <ServiceValues data-test-product-name>{t(initialValues?.name)}</ServiceValues>
            <ServiceDetailsContainer>
              <ServiceDetailsRows>
                <Flex style={{ flexDirection: 'column', width: '300px' }}>
                  <ServiceHeaders data-test-product-sku-header>{t('Product SKU')}</ServiceHeaders>
                  <ServiceValues data-test-product-sku>{t(initialValues?.sku)}</ServiceValues>
                </Flex>
                <Flex style={{ flexDirection: 'column', width: '300px' }}>
                  <ServiceHeaders data-test-product-sku-type-header>{t('SKU Type')}</ServiceHeaders>
                  <ServiceValues data-test-product-sku-type>
                    {t(initialValues?.skuType)}
                  </ServiceValues>
                </Flex>
              </ServiceDetailsRows>
            </ServiceDetailsContainer>
          </div>
          <Border />
          <div style={{ padding: '15px 30px' }}>
            <ServiceDetailsContainer>
              <ServiceDetailsRows>
                <Flex style={{ flexDirection: 'column', width: '300px' }}>
                  <ServiceHeaders data-test-product-program-header>
                    {t('Partner Program')}
                  </ServiceHeaders>
                  <ServiceValues data-test-program-name>
                    {getPartnerProgramName?.name}
                  </ServiceValues>
                </Flex>
                <Flex style={{ flexDirection: 'column', width: '300px' }}>
                  <ServiceHeaders data-test-parent-program-header>
                    {t('Parent Program Product')}
                  </ServiceHeaders>
                  <ServiceValues data-test-parent-program>{''}</ServiceValues>
                </Flex>
              </ServiceDetailsRows>
            </ServiceDetailsContainer>
          </div>
          <Border />
          <div style={{ padding: '15px 30px' }}>
            <ServiceDetailsContainer>
              <ServiceDetailsRows>
                <Flex style={{ flexDirection: 'column', width: '300px' }}>
                  <ServiceHeaders data-test-product-offering-header>
                    {t('Product Offering')}
                  </ServiceHeaders>
                  <ServiceValues data-test-product-offering>
                    {initialValues?.programOffering}
                  </ServiceValues>
                </Flex>
                <Flex style={{ flexDirection: 'column', width: '300px' }}>
                  <ServiceHeaders data-test-product-variation-header>
                    {t('Product Variation')}
                  </ServiceHeaders>
                  <ServiceValues data-test-product-variation>
                    {initialValues?.variation}
                  </ServiceValues>
                </Flex>
                <Flex style={{ flexDirection: 'column', width: '300px' }}>
                  <ServiceHeaders data-test-product-billing-cycle-header>
                    {t('Billing Cycle')}
                  </ServiceHeaders>
                  <ServiceValues data-test-product-billing-cycle>
                    {initialValues?.billingCycle}
                  </ServiceValues>
                </Flex>
              </ServiceDetailsRows>
            </ServiceDetailsContainer>
          </div>
          <Border />
          <div style={{ padding: '15px 30px' }}>
            <ServiceDetailsContainer>
              <ServiceDetailsRows>
                <Flex style={{ flexDirection: 'column', width: '300px' }}>
                  <ServiceHeaders data-test-initial-order-header>
                    {t('Initial Order Price')}
                  </ServiceHeaders>
                  <ServiceValues data-test-initial-order>{initialValues?.orderPrice}</ServiceValues>
                </Flex>
                <Flex style={{ flexDirection: 'column', width: '300px' }}>
                  <ServiceHeaders data-test-recurring-price-header>
                    {t('Recurring Price')}
                  </ServiceHeaders>
                  <ServiceValues data-test-recurring-price>
                    {initialValues?.recurringPrice}
                  </ServiceValues>
                </Flex>
                <Flex style={{ flexDirection: 'column', width: '300px' }}>
                  <ServiceHeaders data-test-recurring-price-desc-header>
                    {t('Recurring Price Description')}
                  </ServiceHeaders>
                  <ServiceValues data-test-recurring-price-desc>
                    {initialValues?.recurringPriceDescription}
                  </ServiceValues>
                </Flex>
              </ServiceDetailsRows>
            </ServiceDetailsContainer>
          </div>
          <Border />
          <div style={{ padding: '15px 30px' }}>
            <ServiceDetailsContainer>
              <ServiceDetailsRows>
                <Flex style={{ flexDirection: 'column', width: '300px' }}>
                  <ServiceHeaders data-test-code-header>{t('SAP Product Code')}</ServiceHeaders>
                  <ServiceValues data-test-code>{initialValues?.sapProductCode}</ServiceValues>
                </Flex>
                <Flex style={{ flexDirection: 'column', width: '300px' }}>
                  <ServiceHeaders data-test-uuid-header>{t('SAP Product UUID')}</ServiceHeaders>
                  <ServiceValues data-test-uuid>{initialValues?.sapProductId}</ServiceValues>
                </Flex>
                <Flex style={{ flexDirection: 'column', width: '300px' }}>
                  <ServiceHeaders data-test-rate-plan-header>
                    {t('SAP Rate Plan UUID')}
                  </ServiceHeaders>
                  <ServiceValues data-test-rate-plan>{initialValues?.sapRatePlanId}</ServiceValues>
                </Flex>
              </ServiceDetailsRows>
            </ServiceDetailsContainer>
          </div>
        </main>
      )}
    </>
  );
};

export default ServiceDetails;
