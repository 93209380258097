import { Button, CopyButton, Flex, Heading, Link, Text } from '@resideo/blueprint-react';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { CustomerAccountSharedProps } from '../types';
import './CustomerDetailsStyles.css';
import { FaPen } from 'react-icons/fa';
import EditCustomerForm from './EditCustomersForm';
import { GLOBAL_CUSTOMER_SEARCH } from 'config';

const PenIcon = styled(FaPen)`
  margin-right: 8px;
`;

const AccountNumber = styled(Flex)`
  gap: 10px;
  align-items: center;
  cursor: default;
  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    flex-wrap: wrap;
    align-items: end;
    max-width: 145px;
  }
`;

const AccountId = styled.div`
  @media (width < 1600px) {
    inline-size: 145px;
    overflow-wrap: break-word;
  }
`;

const HideButton = styled(Button)`
  word-break: normal;
`;

const CustomerAccountDetails: FC<CustomerAccountSharedProps> = ({
  data,
  openModal,
  closeModal,
}) => {
  const { t } = useTranslation();
  const { id, email, firstName, lastName, phoneNumber } = data ?? '';
  const [showAccountNumber, setShowAccountNumber] = useState(false);

  const renderAccountNumber = () => {
    return (
      <AccountNumber>
        {showAccountNumber && <AccountId data-test-account-number>{id}</AccountId>}
        <HideButton
          data-test-show-button
          className='ShowButton'
          onClick={() => {
            setShowAccountNumber(!showAccountNumber);
          }}
          variant='tertiary'
          tertiaryLink>
          {t(showAccountNumber ? 'Hide' : 'Show')}
        </HideButton>
        <CopyButton
          data-test-copy-button
          successText={t('Copied')}
          tooltipText={t('Copy Account Number')}
          copyText={id || ''}
        />
      </AccountNumber>
    );
  };

  return (
    <div className='CustomerInfoSection'>
      <Flex className='CustomerInfoHeader'>
        <Heading
          as='h1'
          fontSize='large'
          paddingTop='medium'
          paddingBottom='medium'
          data-test-customer-info>
          {GLOBAL_CUSTOMER_SEARCH ? t('Customer Info') : t('Customer Information')}
        </Heading>
        <Link
          data-test-edit-customer-info
          paddingRight='30px'
          variant='tertiary'
          onClick={() => {
            openModal({
              title: t('Edit Customer Info'),
              children: <EditCustomerForm closeModal={closeModal} initialValues={data} />,
            });
          }}>
          <PenIcon />
          {t('Edit')}
        </Link>
      </Flex>
      <div className='CustomerInfoBorder'></div>

      <Flex className='CustomerInfo'>
        <Flex className='CustomerInfoDetails'>
          <Text className='InfoHeader'>{t('Name')}</Text>
          <Text data-test-customer-name className='InfoDetails'>
            {lastName} , {firstName}
          </Text>

          <Text className='InfoHeader'>{t('Email')}</Text>
          <Text data-test-customer-email className='InfoDetails'>
            {email}
          </Text>
        </Flex>

        <Flex className='CustomerInfoDetails'>
          <Text className='InfoHeader'>{t('Phone')}</Text>
          <Text data-test-customer-phone className='InfoDetails'>
            {phoneNumber}
          </Text>

          <Text className='InfoHeader'>{t('Account Number')}</Text>
          <Text className='InfoDetails'>{renderAccountNumber()}</Text>
        </Flex>
      </Flex>
    </div>
  );
};

export default CustomerAccountDetails;
