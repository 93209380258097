import {
  Button,
  Flex,
  Heading,
  SelectField,
  useToastContext,
  useVerificationModal,
} from '@resideo/blueprint-react';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddressType, CustomerAccountSharedProps } from '../types';
import CustomerAccountLocation from './CustomerAccountLocation';
import CustomerLocationForm from './CustomerLocationForm';
import { useUser } from '../CustomerLookupComponent/useUser';
import { useRisClient } from 'hooks/useRisClient';
import { useSuspenseQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { CreatePartnerDeviceLocationModel } from '@resideo/web-integration-services-api-client/dist';
import styled from 'styled-components';
import { parseGeoAddress } from '../../../../utils/common';

const HeadingComponent = styled(Flex)`
  display: -webkit-box;
  margin-left: 15px;
  margin-right: 15px;
  min-width: 0;
  padding: 30px 15px 8px 15px;
  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-bottom: 14px;
    padding: 0 0 15px 0;
  }
`;

const LocationDesktopSelectContainer = styled.div`
  margin-left: 2%;
  width: 215px;
  padding-top: 8px;
`;

const AddLocationContainer = styled.div`
  box-sizing: border-box;
  width: max-content;
  margin-left: auto;
  padding-top: 8px;
  @media (max-width: ${({ theme }) => theme.breakpoints.large}) {
    position: relative;
    padding-left: 4%;
    padding-top: 2%;
  }
`;

const CustomerAccountLocations: FC<CustomerAccountSharedProps> = ({
  openModal,
  customerId,
  closeModal,
}) => {
  const { t } = useTranslation();
  const { addToast } = useToastContext();
  const [{ partnerAccountId }] = useUser();
  const { client } = useRisClient();
  const queryClient = useQueryClient();

  const { data: customer } = useSuspenseQuery({
    queryKey: ['getPartnerCustomer'],
    queryFn: async () =>
      (await client.companies.companiesGetPartnerCustomer(partnerAccountId ?? '', customerId ?? ''))
        .data,
  });

  const [selectedLocation, setSelectedLocation] = useState<any>();

  const [deviceLocationEdges, setDeviceLocationEdges] = useState<any>(null);
  const [locationNames, setLocationNames] = useState<any>(null);

  const [locationValues, setLocationValues] = useState<any>({});
  const {
    openAddressVerificationModal,
    closeAddressVerificationModal,
    AddAddressVerificationModal,
  } = useVerificationModal();
  const [suggestedAddress, setSuggestedAddress] = useState<AddressType>({
    address1: '',
    address2: '',
    city: '',
    country: '',
    locationName: '',
    state: '',
    zip: '',
  });

  useEffect(() => {
    if (customer) {
      setDeviceLocationEdges(customer?.deviceLocations);
      setLocationNames(customer?.deviceLocations?.map((location: any) => location?.name ?? ''));
    }
  }, [customer]);

  useEffect(() => {
    if (!deviceLocationEdges) {
      return;
    }

    const hasSelectedLocation = deviceLocationEdges?.find(
      location => location?.id === selectedLocation?.id,
    );

    setSelectedLocation(hasSelectedLocation ? hasSelectedLocation : deviceLocationEdges[0]);
  }, [deviceLocationEdges]);

  const updateLocationDetails = locationName => {
    deviceLocationEdges?.forEach(location => {
      if (location?.name === locationName) {
        setSelectedLocation(location);
      }
    });
  };

  const addCustomerLocation = useMutation({
    mutationFn: async (vars: { partnerCustomerId; loc: CreatePartnerDeviceLocationModel }) => {
      return client.companies.companiesCreatePartnerDeviceLocation(
        partnerAccountId as string,
        vars.partnerCustomerId,
        {
          name: vars.loc.name,
          address: {
            addressLine1: vars.loc?.address?.addressLine1,
            addressLine2: vars.loc?.address?.addressLine2,
            city: vars.loc?.address?.city,
            stateProvinceRegionCode: vars.loc?.address?.stateProvinceRegionCode,
            zipPostalCode: vars.loc?.address?.zipPostalCode,
            countryCode: vars.loc?.address?.countryCode,
          },
        },
      );
    },
    onError: () => {
      addToast({
        toastType: 'Error',
        message: t('Something went wrong!'),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getPartnerCustomer'] });
      closeModal();
      addToast({
        toastType: 'Success',
        message: t('Location added'),
      });
    },
  });
  const handleAddLocation = (values?) => {
    const apiValues = values ? values : locationValues;
    addCustomerLocation.mutate({
      partnerCustomerId: customerId,
      loc: {
        name: apiValues?.locationName,
        address: {
          addressLine1: apiValues?.address1,
          addressLine2: apiValues?.address2,
          city: apiValues?.city,
          stateProvinceRegionCode: apiValues?.state,
          zipPostalCode: apiValues?.zip,
          countryCode: apiValues?.country,
        },
      },
    });
  };

  const handleCreateDeviceLocation = async values => {
    const {
      geoAddress,
      result,
      isLocationTypeRoofTop,
      partialMatch,
      shortNames,
    } = await parseGeoAddress(values);

    // check the response structure for geoAddress
    if ((result && isLocationTypeRoofTop && !partialMatch) || !geoAddress) {
      handleAddLocation();
    } else {
      closeModal();
      setSuggestedAddress(shortNames);
      openAddressVerificationModal();
    }
  };

  const createCustomerLocation = (AddressDetails?, isBackbuttonClicked = false) => {
    const defaultValues = {
      locationName: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      country: '',
    };
    const address = isBackbuttonClicked ? AddressDetails : defaultValues;
    return openModal({
      title: 'Add Location',
      children: (
        <CustomerLocationForm
          initialValues={{
            ...address,
          }}
          onSubmit={(values, autoCompleteSelected) => {
            if (autoCompleteSelected) {
              handleAddLocation(values);
            } else {
              setLocationValues(values);
              handleCreateDeviceLocation(values);
            }
          }}
          onCancel={() => closeModal()}
          isBackBtnClickedFromVerificationModal={isBackbuttonClicked}
        />
      ),
    });
  };

  return (
    <>
      <HeadingComponent>
        <Heading as='h1' fontSize='large' paddingTop='medium' data-test-locations-header>
          {t('Locations')}
        </Heading>
        {deviceLocationEdges?.length > 1 && (
          <LocationDesktopSelectContainer>
            <SelectField
              data-test-location-dropdown
              className='LocationDropdown'
              onChange={e => updateLocationDetails(e.target.value)}
              version='v2'
              label={''}
              name='locations'>
              {locationNames?.map((locationName, id) => {
                return (
                  <option key={id} value={locationName}>
                    {locationName}
                  </option>
                );
              })}
            </SelectField>
          </LocationDesktopSelectContainer>
        )}
        <AddLocationContainer>
          <Button
            data-test-add-location-button
            variant='secondary'
            onClick={() => {
              openModal({
                title: 'Add Location',
                children: (
                  <CustomerLocationForm
                    initialValues={{
                      locationName: '',
                      address1: '',
                      address2: '',
                      city: '',
                      state: '',
                      zip: '',
                      country: '',
                    }}
                    onSubmit={(values, autoCompleteSelected) => {
                      if (autoCompleteSelected) {
                        handleAddLocation(values);
                      } else {
                        setLocationValues(values);
                        handleCreateDeviceLocation(values);
                      }
                    }}
                    onCancel={() => closeModal()}
                  />
                ),
              });
            }}>
            {t('Add Location')}
          </Button>
        </AddLocationContainer>
      </HeadingComponent>
      <div className='LocationsBorder'></div>
      {selectedLocation && (
        <CustomerAccountLocation
          openModal={openModal}
          closeModal={closeModal}
          customerData={customer}
          location={selectedLocation}
        />
      )}
      <AddAddressVerificationModal
        suggestedAddress={suggestedAddress}
        originalAddress={locationValues}
        closeAddressVerificationModal={closeAddressVerificationModal}
        backButtonClicked={address => {
          createCustomerLocation(address, true);
          closeAddressVerificationModal();
        }}
        saveButtonClicked={values => {
          handleAddLocation(values);
          closeAddressVerificationModal();
        }}
        translations={{
          ModalTitle: t('Add Location'),
          ModalHeader: t('Verification'),
          ModalText: t('We are not able to verify the address you provided.'),
          ModalChooseText: t('Choose one of the following:'),
          OriginalAddressLabel: t('Original Address'),
          SuggestedAddressLabel: t('Suggested Address'),
          BackBtnLabel: t('Back'),
          SaveBtnLabel: t('Save'),
        }}
      />
    </>
  );
};

export default CustomerAccountLocations;
