import React from 'react';
import { Text, Link as BPLink } from '@resideo/blueprint-react';
import ReactTooltip from 'react-tooltip';

export const getDeviceCell = (id, deviceLocations, filter) => {
  // Get all devices in all locations
  const devices = [] as { loc: any; id: string }[];
  deviceLocations?.forEach(loc => {
    loc.devices?.forEach(device => {
      const deviceId = device.deviceId;
      // Add device ID, and if it matches the search term move it to the top
      if (deviceId === filter) {
        devices.unshift({ loc: loc, id: deviceId });
      } else if (deviceId) {
        devices.push({ loc: loc, id: deviceId });
      }
    });
  });

  return (
    <>
      {/* Display first three device IDs */}
      {devices.slice(0, 3).map((device, i) => {
        return <Text key={i}>{device.id}</Text>;
      })}
      {/* More than three devices, show tooltip */}
      {devices.length > 3 && (
        <>
          <BPLink data-tip data-for={'devices-' + id}>{`+${devices.length - 3} More`}</BPLink>
          <ReactTooltip
            type='light'
            id={'devices-' + id}
            place='bottom'
            effect='solid'
            delayHide={500}
            delayUpdate={500}>
            {devices.slice(3).map((device, i, devices) => (
              <div key={i}>
                {/* Show address header? */}
                {i === 0 || device.loc.id !== devices[i - 1].loc.id ? (
                  <>
                    <Text
                      fontSize='medium'
                      paddingTop={i === 0 ? 'xSmall' : 'medium'}
                      fontWeight='bold'>
                      {device.loc.address.addressLine1} {device.loc.address.addressLine2}
                    </Text>
                  </>
                ) : null}
                <Text fontSize='medium'>{device.id}</Text>
              </div>
            ))}
          </ReactTooltip>
        </>
      )}
    </>
  );
};
