import React, { useState, useEffect } from 'react';
import { TableV2, Text } from '@resideo/blueprint-react';
import { useSuspenseQuery } from '@tanstack/react-query';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { formatDateTime } from 'utils/common';
import '../../SharedComponentsModule/TooltipStyle.css';
import { TABLE_RESULTS_PER_PAGE } from '../../config';
import { useRisClient } from 'hooks/useRisClient';

const StyledLink = styled.a`
  text-decoration: none;
  color: #1c6fb9;
`;

const ProductsTable = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { client } = useRisClient();

  const { data, error } = useSuspenseQuery({
    queryKey: ['getProducts'],
    queryFn: async () => (await client.core.coreGetProductBySku()).data as any,
  });

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = TABLE_RESULTS_PER_PAGE;
  const productsLength = data?.length || 0;

  const totalPages = Math.ceil(productsLength / itemsPerPage);

  useEffect(() => {
    if (!data) {
      return;
    }
  }, [data]);

  const installationColumn = isDIY => {
    return isDIY ? t('Home & Pro Apps') : t('Pro App');
  };

  const publishDate = product => {
    const launchDate =
      product?.launchDate === null
        ? t('Pending')
        : formatDateTime(new Date(product?.launchDate ?? '').toISOString());
    const retireDate =
      product?.launchDate === null || product?.retireDate === null
        ? ''
        : formatDateTime(new Date(product?.retireDate ?? '').toISOString());
    return (
      <>
        <Text>{launchDate}</Text>
        <Text>{retireDate}</Text>
      </>
    );
  };

  const generateRows = () => {
    const productsArray = (data as any) || [];

    const sortedProducts = productsArray
      ?.filter(product => product)
      .sort((prod1, prod2) => {
        // Sorting products based on productName
        return (prod1?.productName || '').localeCompare(prod2?.productName || '');
      });

    // Adding pagination to sorted Product data
    const productData = sortedProducts.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage,
    );
    return (
      productData?.map(product => {
        return {
          productName: (
            <StyledLink href={`/products/${product?.id}`}>{product?.productName}</StyledLink>
          ),
          sku: product?.sku,
          productFamily: product?.productFamily,
          installation: installationColumn(product?.isDIY),
          publishDate: publishDate(product),
          actions: [
            {
              name: 'View',
              ctaTag: 'view',
              text: t('View'),
              action: () => {
                navigate(`/products/${product?.id}`);
              },
            },
          ],
        };
      }) ?? []
    );
  };

  return (
    <div>
      <TableV2
        hasPagination
        dataTestAttribute='data-test-products-table'
        actionsButtonName={t('Options')}
        hasPrevious={currentPage > 1}
        hasNext={currentPage < totalPages}
        onPaginationNextClick={() => setCurrentPage(currentPage + 1)}
        onPaginationPrevClick={() => setCurrentPage(currentPage - 1)}
        nextButtonLabel={t('Next')}
        previousButtonLabel={t('Previous')}
        headers={[
          {
            displayName: t('Product Name'),
            isSortable: false,
          },
          {
            displayName: t('Global Device Registry SKU'),

            isSortable: false,
          },
          {
            displayName: t('Product Family'),

            isSortable: false,
          },
          {
            displayName: t('Installation'),
            isSortable: false,
          },
          {
            displayName: t('Publish Date'),
            isSortable: false,
          },
          {
            displayName: '',
            isSortable: false,
          },
        ]}
        isError={
          error
            ? {
                errorHeader: t('Data Unavailable'),
                errorMessage: t('Table data unable to load. Try again later.'),
              }
            : undefined
        }
        rows={generateRows()}></TableV2>
    </div>
  );
};

export default ProductsTable;
