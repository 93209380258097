import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { useModal, Breadcrumbs, Button, useToastContext } from '@resideo/blueprint-react';
import './CustomerDetailsStyles.css';
import { RemoveCustomerConfirmationModal } from './RemoveCustomer';
import CustomerAccountDetails from './CustomerAccountDetails';
import CustomerAccountLocations from './CustomerAccountLocations';
import CustomerCompanyDetails from './CustomerCompanyDetails';
import PageHeader from 'SharedComponentsModule/PageHeader';
import { useRisClient } from 'hooks/useRisClient';
import { useSuspenseQuery, useMutation } from '@tanstack/react-query';
import { ENABLE_NEW_COMPANY_ACCOUNT_SEARCH, GLOBAL_CUSTOMER_SEARCH } from '../../../../config';
import { useQueryParams } from 'hooks/useQueryParams';

const CustomerAccount = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id: partnerAccountId = '' } = useParams<{ id: string }>();
  const { Modal, openModal: _openModal, closeModal } = useModal({
    newDesign: true,
  });
  const { addToast } = useToastContext();
  const { searchParams } = useQueryParams();
  // getting the id from url
  const id = window?.location?.pathname?.split('/').pop() || '';
  const customerAccountURL = `/companies/${partnerAccountId}/customers`;

  const { client } = useRisClient();
  const { data: customer } = useSuspenseQuery({
    queryKey: ['getPartnerCustomer', { partnerAccountId, id }],
    queryFn: async () =>
      (await client.companies.companiesGetPartnerCustomer(partnerAccountId ?? '', id)).data,
  });

  const { data: company } = useSuspenseQuery({
    queryKey: ['getPartnerAccount', { partnerAccountId }],
    queryFn: async () => (await client.companies.companiesGetCompany(partnerAccountId ?? '')).data,
  });

  const deleteCustomerAccount = useMutation({
    mutationKey: ['deleteCustomerAccount'],
    mutationFn: async () => {
      await client.companies.companiesDeletePartnerCustomer(
        partnerAccountId as string,
        customer?.id as string,
      );
    },
    onSuccess: () => {
      addToast({
        toastType: 'Success',
        message: t('Customer removed'),
      });
      closeModal();
      navigate(customerAccountURL);
    },
    onError: () => {
      addToast({
        toastType: 'Error',
        message: t('Something went wrong!'),
      });
    },
  });

  const handleDeleteCustomer = () => {
    deleteCustomerAccount.mutate();
  };

  const [modalProps, setModalProps] = useState({
    title: t('Remove Customer'),
    children: (
      <RemoveCustomerConfirmationModal
        onCancel={closeModal}
        onSubmit={() => {
          handleDeleteCustomer();
        }}
      />
    ),
  });

  const openModal = modalProps => {
    setModalProps(modalProps);
    _openModal();
  };

  const title = `${customer?.firstName} ${customer?.lastName}`;
  return (
    <>
      <PageHeader
        breadcrumbs={
          <Breadcrumbs>
            {searchParams.get('filter') ? (
              <>
                <Link to='/customers'> {t('Partner Customers')}</Link>
                <Link
                  to={`/customers/results?filter=${encodeURIComponent(
                    searchParams.get('filter') as string,
                  )}&filterBy=${searchParams.get('filterBy')}`}>
                  {t('Results')}
                </Link>
                <Link to='#'>{title}</Link>
              </>
            ) : (
              <>
                <Link to='/companies'>
                  {' '}
                  {GLOBAL_CUSTOMER_SEARCH ? t('Partner Companies') : t('Companies')}
                </Link>
                <Link to={customerAccountURL}>{company?.name}</Link>
                <Link to='#'>{title}</Link>
              </>
            )}
          </Breadcrumbs>
        }
        title={title}
        controls={
          <Button
            data-test-remove-customer
            variant='destructiveSecondary'
            onClick={() =>
              openModal({
                title: t('Remove Customer'),
                children: (
                  <RemoveCustomerConfirmationModal
                    onCancel={closeModal}
                    onSubmit={() => {
                      handleDeleteCustomer();
                    }}
                  />
                ),
              })
            }>
            {t('Remove Customer')}
          </Button>
        }
        hideBorder={GLOBAL_CUSTOMER_SEARCH}
      />
      <CustomerAccountDetails openModal={openModal} closeModal={closeModal} data={customer} />
      {ENABLE_NEW_COMPANY_ACCOUNT_SEARCH && <CustomerCompanyDetails companyData={company} />}
      <CustomerAccountLocations openModal={openModal} closeModal={closeModal} customerId={id} />
      <Modal {...modalProps} />
    </>
  );
};

export default CustomerAccount;
