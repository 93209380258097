import React from 'react';
import { useTranslation } from 'react-i18next';
import { ActionsButtonItem, SecondaryActionsButton, useModal } from '@resideo/blueprint-react';
import { useNavigate } from 'react-router-dom';
import { generateCompaniesRoute, UpdateEmployeeModal } from '../../CompaniesModule';
import DeleteEmployeeModal from 'UsersModule/DeleteUserModalComponent/DeleteEmployeeModal';
import styled from 'styled-components';

const DetailBox = styled.div`
  border: 1px solid #dadada;
  width: 25%;
  padding: 15px;
  margin-right: 25px;

  h3 {
    margin-top: 0;
  }

  address {
    font-style: normal;
    margin-bottom: 15px;
  }
`;

export const CompanyDetailBox = ({ company, user }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const VALUESTOTEXT = {
    TECHNICIAN: t('Technician'),
    COMPANY_ADMIN: t('Company Admin'),
    ARCHIVED: t('Archived'),
    ACTIVE: t('Active'),
    OWNER: t('Owner'),
  };

  const {
    Modal: RemoveModal,
    openModal: openRemoveModal,
    closeModal: closeRemoveModal,
  } = useModal({ newDesign: true });

  const {
    Modal: UpdateModal,
    openModal: openUpdateModal,
    closeModal: closeUpdateModal,
  } = useModal({ newDesign: true });

  const BUTTONS: ActionsButtonItem[] = [
    { name: 'update', text: t('Update Role/Status'), action: openUpdateModal },
    {
      disabled: false,
      name: 'view',
      text: t('View Company'),
      action: () => {
        navigate(generateCompaniesRoute('company', { id: company?.partnerAccount?.id }));
      },
    },
    {
      name: 'third',
      text: t('Remove Employee'),
      action: openRemoveModal,
    },
  ];

  const employee = {
    user: user,
    status: company?.status,
    roles: company?.roles,
    id: company?.id,
  };

  return (
    <DetailBox>
      <h3 className='title'>{company?.partnerAccount?.name}</h3>
      <div className='content'>
        {(company?.partnerAccount?.shipToAddress?.addressLine1 && (
          <address>
            {company?.partnerAccount?.shipToAddress?.addressLine1}
            <br />
            {company?.partnerAccount?.shipToAddress?.addressLine2 !== '' && (
              <div>
                {company?.partnerAccount?.shipToAddress?.addressLine2} <br />
              </div>
            )}
            {company?.partnerAccount?.shipToAddress?.city},{' '}
            {company?.partnerAccount?.shipToAddress?.stateProvinceRegionCode}{' '}
            {company?.partnerAccount?.shipToAddress?.zipPostalCode}
            <br />
            {company?.partnerAccount?.shipToAddress?.countryCode}
          </address>
        )) || <address>{t('No Address')}</address>}

        <div className='justifiedContent' style={{ display: 'flex', marginBottom: '15px' }}>
          <div style={{ flexGrow: 1 }}>
            <strong>{t('Role')}</strong>
            <br />
            {VALUESTOTEXT[company?.roles[0]]}
          </div>

          <div style={{ flexGrow: 1 }}>
            <strong>{t('Status')}</strong>
            <br />
            {VALUESTOTEXT[company?.status]}
          </div>
        </div>

        <SecondaryActionsButton size='xSmall' buttons={BUTTONS}>
          Options
        </SecondaryActionsButton>
      </div>

      <div>
        <RemoveModal title={t('Remove Employee')}>
          <DeleteEmployeeModal user={user} company={company} closeModal={closeRemoveModal} />
        </RemoveModal>

        <UpdateModal title={t('Update Employee Role/Status')}>
          <UpdateEmployeeModal
            employee={employee}
            closeModal={closeUpdateModal}
            companyId={company?.partnerAccount?.id}
          />
        </UpdateModal>
      </div>
    </DetailBox>
  );
};
