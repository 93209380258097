import React, { useState } from 'react';
import { Form, Formik, FormikErrors } from 'formik';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Text, Button } from '@resideo/blueprint-react';
import { checkPhoneNumber, checkEmail, checkName } from '../../../../utils/validation';
import { InputField, PhoneInputField } from '@resideo/blueprint-formik';
import { ICustomerInfoProps } from '../types';
import styled from 'styled-components';
import { useUser } from '../CustomerLookupComponent/useUser';
import { checkPhoneNumberForCountryCode } from 'utils/common';
import { useRisClient } from 'hooks/useRisClient';
import { useQuery } from '@tanstack/react-query';
import { GLOBAL_CUSTOMER_SEARCH } from 'config';

export interface CustomerValues {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber?: string;
}

const emptyState = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  countryCode: '',
};

const ErrorDiv = styled(Flex)`
  align-items: center;
  gap: 5px;
  color: #d22730;
  font-size: 0.75rem;
  line-height: 1.5;
  padding-top: 0.25rem;
  margin: 0;

  a {
    text-decoration: none;
  }
`;

const StyledButton = styled(Button)`
  padding-left: 0;
  text-align: left;
  margin-right: auto;
`;

const CustomerInfoForm: React.FC<ICustomerInfoProps> = ({
  initialValues = emptyState,
  isEditMode = false,
  closeModal,
  onSubmit,
  data,
}) => {
  const { t } = useTranslation();
  const [{ partnerAccountId }] = useUser();
  const { client } = useRisClient();
  const [email, setEmail] = useState('');
  const { refetch: checkExistingCustomerFetch } = useQuery({
    queryKey: ['partnerCustomersCheckExisting'],
    queryFn: async () =>
      (
        await client.companies.companiesSearchPartnerCustomers(
          partnerAccountId as string,
          JSON.stringify([{ email: email }]),
        )
      ).data,
    enabled: false,
    staleTime: 5000,
  });

  const validate = async (values: CustomerValues) => {
    const errors: FormikErrors<CustomerValues> = {};
    let existingCustomerId;
    const isValidEmail = values?.email && values?.email?.trim() !== '' && checkEmail(values.email);

    if (isValidEmail) {
      const { data } = await checkExistingCustomerFetch();

      const existingCustomers = data?.filter(node => node.email === values.email);

      existingCustomerId = existingCustomers?.length && existingCustomers[0]?.id;
    }

    if (!values.firstName || values.firstName.trim() === '')
      errors['firstName'] = t('First name cannot be empty');
    if (values?.firstName && !checkName(values?.firstName)) {
      errors['firstName'] = t('Unallowed characters in the First Name field');
    }
    if (!values.lastName || values.lastName.trim() === '')
      errors['lastName'] = t('Last name cannot be empty');
    if (values?.lastName && !checkName(values?.lastName)) {
      errors['lastName'] = t('Unallowed characters in the Last Name field');
    }
    if (!values.email || values.email.trim() === '') {
      errors.email = t('Email address is required');
    } else if (existingCustomerId && existingCustomerId !== initialValues?.id) {
      errors.email = `${t(
        'Email is already in use.',
      )} <a href="/companies/${partnerAccountId}/customers/${existingCustomerId}" target="_blank">${t(
        'View Customer',
      )}</a>`;
    } else if (!checkEmail(values?.email)) {
      errors.email = t('Unallowed characters in the Email field');
    }

    const countryCodeIsNotTheOnlyInput =
      values.phoneNumber &&
      `+${checkPhoneNumberForCountryCode(values.phoneNumber).toString()}` !== values.phoneNumber;

    if (
      values.phoneNumber &&
      !checkPhoneNumber(values.phoneNumber) &&
      countryCodeIsNotTheOnlyInput
    ) {
      errors.phoneNumber = t('Enter a valid phone number');
    }

    return errors;
  };

  const handleInput = event => {
    setEmail(event.target.value);
  };

  return (
    <>
      <Text marginBottom='medium' as='h2' fontSize='xLarge' data-test-new-customer-modal-enter-info>
        {GLOBAL_CUSTOMER_SEARCH
          ? t('Step 1 of 2: Enter Customer Info')
          : t('Step 1 of 2: Enter Customer Information')}
      </Text>
      <Formik initialValues={{ ...initialValues, ...data }} onSubmit={onSubmit} validate={validate}>
        {({ dirty, isValid, errors, touched }) => (
          <Form data-test-customer-info noValidate id={'customerInfoForm'}>
            <Text
              color='faded'
              marginBottom='medium'
              fontSize='small'
              data-test-new-customer-modal-required-fields>
              {t('* Required Fields')}
            </Text>
            <Flex flexDirection={['column', 'row']}>
              <Box data-test-customer-first-name marginBottom='medium' width={[1, 1, 1, 1]}>
                <InputField
                  autoFocus
                  data-test-new-customer-modal-first-name
                  version='v2'
                  name='firstName'
                  label={t('First Name')}
                  required={true}
                  disabled={false}
                />
              </Box>
            </Flex>
            <Flex flexDirection={['column', 'row']}>
              <Box data-test-customer-last-name marginBottom='medium' width={[1, 1, 1, 1]}>
                <InputField
                  data-test-new-customer-modal-last-name
                  version='v2'
                  name='lastName'
                  label={t('Last Name')}
                  required={true}
                  disabled={false}
                />
              </Box>
            </Flex>
            <Flex flexDirection={['column', 'row']}>
              <Box data-test-customer-email marginBottom='medium' width={[1, 1, 1, 1]}>
                <InputField
                  data-test-new-customer-modal-email
                  error={undefined}
                  borderColor={errors?.email && touched?.email ? 'red' : undefined}
                  version='v2'
                  name='email'
                  label={t('Contact Email')}
                  required={true}
                  disabled={false}
                  onInput={handleInput}
                />
                {errors?.email && touched?.email && (
                  <ErrorDiv
                    data-test-add-customer-email-error
                    dangerouslySetInnerHTML={{
                      __html: errors?.email,
                    }}></ErrorDiv>
                )}
              </Box>
            </Flex>
            <Flex flexDirection={['column', 'row']}>
              <Box data-test-new-customer-modal-phone marginBottom='medium' width={[1, 1, 1, 1]}>
                <PhoneInputField
                  version='v2'
                  label={t('Phone')}
                  name='phoneNumber'
                  disabled={false}
                  phoneInputConfig={{
                    preferredCountries: ['us', 'ca'],
                    country: 'us',
                    countryCodeEditable: false,
                  }}
                />
              </Box>
            </Flex>
            <Flex
              alignItems='center'
              flexDirection={['column', 'row-reverse']}
              paddingX={['medium', 0]}
              paddingTop='large'>
              <Button
                data-test-new-customer-modal-next
                data-test-profile-save-btn
                disabled={!dirty && data && Object.keys(data).length === 0 ? !dirty : !isValid}
                type='submit'
                variant='primary'>
                {isEditMode ? t('Save') : t('Next')}
              </Button>
              <StyledButton
                data-test-new-customer-modal-cancel
                variant={'tertiary'}
                onClick={closeModal}
                type='button'>
                {t('Cancel')}
              </StyledButton>
            </Flex>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CustomerInfoForm;
