// SWR documentation - https://swr.vercel.app/
import useSWR from 'swr';

const headers = new Headers();
const apiKey = import.meta.env.REACT_APP_COUNTRY_STATE_API_KEY || '';
headers.append('X-CSCAPI-KEY', apiKey);

const requestOptions = {
  method: 'GET',
  headers: headers,
};

const fetcher = async () => {
  const res = await fetch(`https://api.countrystatecity.in/v1/states`, requestOptions);
  return await res.json();
};

const useStateData = () => {
  const { data: stateData } = useSWR(`https://api.countrystatecity.in/v1/states`, fetcher, {
    suspense: true,
  });

  return { stateData };
};

export default useStateData;
