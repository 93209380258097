import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Heading,
  Select,
  TableV2,
  Text,
  useModal,
  useToastContext,
} from '@resideo/blueprint-react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { USER_DEVICE_SERVICES_LINK } from '../../config';
import { formatDateTime } from '../../utils/common';
import { RemoveUserConfirmationModal } from './RemoveUserConfirmationModal';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { RemoveDeviceModal } from './RemoveDeviceModal';
import { UnregisterDeviceModal } from './UnregisterDeviceModal';
import { USER_DEVICE_SERVICES_REGISTRATION_LINK } from '../../config';
import { ConsumerDeviceLocationModel } from '@resideo/web-integration-services-api-client';
import { useRisClient } from 'hooks/useRisClient';

interface IModalProps {
  title: string;
  children: any;
}

const UserInfoText = styled(Text)`
  font: normal normal normal 14px/18px Roboto;
`;

const StyledLink = styled.a`
  text-decoration: none;
  color: #1c6fb9;
`;

const UserLocationDetails = ({
  consumerAccountData,
  userLocations,
  setLocationName,
  locationName,
}: {
  consumerAccountData: any[];
  userLocations: ConsumerDeviceLocationModel[];
  setLocationName: (x: string) => void;
  locationName: string;
}) => {
  const [deviceId, setDeviceId] = useState<string>('');
  const deviceIdRef = useRef(deviceId);
  const [isDeviceServicesButtonClicked, setIsDeviceServicesButtonClicked] = useState<boolean>(
    false,
  );
  const isDeviceServicesButtonClickedRef = useRef(isDeviceServicesButtonClicked);
  const { t } = useTranslation();
  const [modalProps, setModalProps] = useState<IModalProps | null>(null);
  const { addToast } = useToastContext();
  const { Modal, openModal: _openModal, closeModal } = useModal({
    newDesign: true,
    onModalClose: () => {
      if (deviceIdRef.current) {
        closeUnregisterDeviceModal(true);
      }
    },
  });
  const { client } = useRisClient();
  const queryClient = useQueryClient();

  const deleteConsumerUser = useMutation({
    mutationKey: ['deleteConsumerUser'],
    mutationFn: async (consumerUserId: string) => {
      await client.core.coreDeleteConsumerUser(consumerUserId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getUserById'] });
      addToast({
        toastType: 'Success',
        message: t('User removed from location'),
      });
      closeModal();
    },
    onError: () => {
      addToast({
        toastType: 'Error',
        message: t('Something went wrong!'),
      });
      closeModal();
    },
  });

  const removeDeviceId = useMutation({
    mutationKey: ['deleteDeviceId'],
    mutationFn: async (deviceId: string) => {
      await client.core.coreDeleteConsumerDevice(deviceId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getUserById'] });
      openModal({
        title: t('Remove Device'),
        children: (
          <UnregisterDeviceModal
            GoToDeviceServices={() => redirectToDeviceServices()}
            onCancel={() => closeUnregisterDeviceModal(false)}
          />
        ),
      });
    },
    onError: () => {
      closeModal();
      addToast({
        toastType: 'Error',
        message: t('Something went wrong!'),
      });
      setDeviceId('');
    },
  });

  const handleRemoveDevice = deviceId => {
    setIsDeviceServicesButtonClicked(false);
    removeDeviceId.mutate(deviceId);
  };

  // When user and location is deleted, check whether the locationName exists in new data or
  // else update the state to use userLocations[0].name
  useEffect(() => {
    if (userLocations) {
      const isLocationAvailable = userLocations.find(location => {
        return location.name === locationName;
      });

      // If the location is not available in new data, update setLocationName with first location name to display.
      if (!isLocationAvailable) {
        setLocationName(userLocations[0]?.name || '');
      }
    }
  }, [userLocations]);

  useEffect(() => {
    // Updating the deviceId ref with the state
    deviceIdRef.current = deviceId;
  }, [deviceId]);

  useEffect(() => {
    // Updating the deviceServices button click ref with the state
    isDeviceServicesButtonClickedRef.current = isDeviceServicesButtonClicked;
  }, [isDeviceServicesButtonClicked]);

  const openModal = modalProps => {
    setModalProps(modalProps);
    _openModal();
  };

  const redirectToDeviceServices = () => {
    setIsDeviceServicesButtonClicked(true);
    window.open(`${USER_DEVICE_SERVICES_REGISTRATION_LINK}?${deviceIdRef.current}`, '_blank');
  };

  const closeUnregisterDeviceModal = isCloseIconClicked => {
    if (!isCloseIconClicked) {
      closeModal();
    }

    // when Open Device Services button is clicked, display the toast that hides after 3 seconds.
    if (isDeviceServicesButtonClickedRef.current) {
      addToast({
        toastType: 'Success',
        message: t(`${deviceIdRef.current} removed from location.`),
      });
    } else {
      // when Open Device Services button is not clicked, display the toast that is displayed until it's closed manually.
      addToast({
        toastType: 'Alert',
        message: t(
          `Notice: ${deviceIdRef.current} removal will not be complete until unregistered from Device Services.`,
        ),
        linkText: t('Open Device Services'),
        linkHref: `${USER_DEVICE_SERVICES_REGISTRATION_LINK}?${deviceIdRef.current}`,
        target: '_blank',
      });
    }
    setDeviceId('');
  };

  const getRows = location => {
    return location?.consumerDevices?.map((consumerDevice, index) => {
      const deviceID = consumerDevice?.device?.deviceId;
      return {
        'Device/MAC ID': (
          <StyledLink key={index} href={`${USER_DEVICE_SERVICES_LINK}?${deviceID}`}>
            {deviceID}
          </StyledLink>
        ),
        'Device Name': consumerDevice?.name,
        'Device Type': consumerDevice?.device?.product?.productName || '',
        actions: [
          {
            name: 'View Details',
            ctaTag: 'View Details',
            text: t('View Details'),
            action: () => {
              window.location.href = `${USER_DEVICE_SERVICES_LINK}?${deviceID}`;
            },
          },
          {
            name: 'Remove',
            ctaTag: 'Remove',
            text: t('Remove'),
            action: () => {
              openModal({
                title: t('Remove Device'),
                children: (
                  <RemoveDeviceModal
                    device={consumerDevice}
                    deviceType={consumerDevice?.device?.product?.productName || ''}
                    addressDetails={location}
                    onSubmit={() => {
                      setDeviceId(deviceID);
                      handleRemoveDevice(consumerDevice?.id);
                    }}
                    onCancel={closeModal}
                  />
                ),
              });
            },
          },
        ],
      };
    });
  };

  const getUserLocationRows = consumerUserEdges => {
    const consumerUsers = consumerUserEdges.filter(
      consumerUser => consumerUser?.user?.firstName && consumerUser?.user?.lastName,
    );
    return consumerUsers?.map(consumerUser => {
      const user = consumerUser?.user;
      if (user) {
        const { firstName, lastName, contactEmail, createdAt } = user;
        return {
          Name: `${firstName} ${lastName}`,
          Email: contactEmail,
          'Associated On': formatDateTime(createdAt),
          actions: [
            {
              name: 'Remove',
              ctaTag: 'Remove',
              text: 'Remove',
              disabled: consumerUsers?.length === 1,
              tooltipText:
                consumerUsers?.length === 1
                  ? t('Cannot remove the only user at this location.')
                  : '',
              action: () => {
                openModal({
                  title: t('Remove User'),
                  children: (
                    <RemoveUserConfirmationModal
                      onCancel={closeModal}
                      onSubmit={() => {
                        deleteConsumerUser.mutate(consumerUser?.id);
                      }}
                    />
                  ),
                });
              },
            },
          ],
        };
      }
    });
  };

  return (
    <>
      {consumerAccountData?.length > 1 && (
        <Box width={[1 / 5]} marginX={'26px'} paddingTop={'small'} data-test-location-drop-down>
          <Select
            version={'v2'}
            name={'Location'}
            value={locationName}
            onChange={e => setLocationName(e.target.value)}>
            {userLocations?.map(userLocation => {
              return (
                <option key={`user-location-${userLocation?.id}`} value={userLocation?.name || []}>
                  {userLocation?.name}
                </option>
              );
            })}
          </Select>
        </Box>
      )}
      {consumerAccountData?.length > 0 &&
        consumerAccountData?.map(accountData => {
          return accountData?.locations?.map(location => {
            if (location?.name === locationName) {
              return (
                <Box key={`account-location-${location?.id}`} paddingTop={'medium'}>
                  <Box marginX={'26px'}>
                    {consumerAccountData?.length < 2 && (
                      <Heading
                        as={'h2'}
                        paddingBottom={'medium'}
                        fontSize={'medium'}
                        fontFamily={'normal normal bold 18px/22px Roboto'}
                        letterSpacing={'0.23px'}
                        data-test-location-name>
                        {location?.name}
                      </Heading>
                    )}
                    <UserInfoText data-test-address>
                      {`${location?.address?.addressLine1} ${location?.address?.addressLine2 ||
                        ''}`}
                    </UserInfoText>
                    <UserInfoText data-test-city>{`${location?.address?.city}, ${location?.address
                      ?.stateProvinceRegionCode || ''} ${
                      location?.address?.zipPostalCode
                    }`}</UserInfoText>
                    <UserInfoText
                      data-test-country>{`${location?.address?.countryCode}`}</UserInfoText>
                  </Box>
                  <Heading
                    as={'h3'}
                    paddingTop='large'
                    marginX='large'
                    data-test-devices-header
                    fontSize={'21px'}>
                    {t('Devices')}
                  </Heading>

                  <Box paddingTop={'20px'} marginX='medium'>
                    <TableV2
                      dataTestAttribute='data-test-devices-table'
                      headers={[
                        {
                          displayName: t('Device/MAC ID'),
                          isSortable: false,
                        },
                        { displayName: t('Device Name'), isSortable: false },
                        { displayName: t('Device Type'), isSortable: false },
                        { displayName: '', isSortable: false },
                      ]}
                      rows={getRows(location)}></TableV2>
                  </Box>

                  <Heading
                    as={'h3'}
                    paddingTop='large'
                    marginX='large'
                    data-test-user-locations-header
                    fontSize={'21px'}>
                    {t('Location Users')}
                  </Heading>
                  <Box paddingTop={'20px'} marginX='medium'>
                    <TableV2
                      dataTestAttribute='data-test-location-users-table'
                      headers={[
                        {
                          displayName: t('Name'),
                          isSortable: false,
                        },
                        { displayName: t('Email'), isSortable: false },
                        { displayName: t('Associated On'), isSortable: false },
                        { displayName: '', isSortable: false },
                      ]}
                      rows={getUserLocationRows(accountData?.consumerUsers || [])}></TableV2>
                  </Box>
                </Box>
              );
            }
          });
        })}
      <Modal {...modalProps} />
    </>
  );
};

export default UserLocationDetails;
