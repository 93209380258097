import { Flex } from '@resideo/blueprint-react';
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import UserSearch from 'components/UserSearch';

const Container = styled.div`
  display: grid;
  align-content: center;
  grid-gap: 2rem;
  margin-top: 10rem;
  padding: 0 1rem;
  h2,
  p,
  div {
    margin: 0;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    padding: 0 10rem;
  }
`;

const Page = styled(Flex)`
  min-height: calc(100vh - 62px);
  flex-direction: column;
  justify-content: space-between;
`;

const Footer = styled.footer`
  align-self: center;
  margin: ${({ theme }) => `${theme.space.large} 0`};
`;

const StyledLink = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.primary};
`;

const FindUserAccount = () => {
  const { t } = useTranslation();

  return (
    <Page>
      <Container>
        <h2>{t('Find User Account')}</h2>
        <p>{t('Find a First Alert app, Resideo Pro, or Pro Portal user account.')}</p>
        <UserSearch />
      </Container>
      <Footer>
        <p>
          {t('Note: to look up a Resideo Home or Total Connect Comfort user accounts visit the')}{' '}
          <StyledLink href='https://gcsportal.resideo.com/' target='_blank'>
            GCS Portal
          </StyledLink>{' '}
          {t('or')}{' '}
          <StyledLink href='https://www.mytotalconnectcomfort.com/Admin/' target='_blank'>
            TCC Admin Portal
          </StyledLink>
          .
        </p>
      </Footer>
    </Page>
  );
};

export default FindUserAccount;
