// This module pertains to all sections for users.
import React from 'react';
import { Outlet, RouteObject } from 'react-router-dom';
import FindPartnerCustomersAccount from './PartnerCustomersLookupComponent/FindPartnerCustomersAccount';
import PartnerCustomerResults from './PartnerCustomersLookupComponent/PartnerCustomersResults';

export const PartnerCustomersModule = () => <Outlet />;

const routes: RouteObject[] = [
  {
    index: true,
    element: <FindPartnerCustomersAccount />,
  },
  {
    path: 'results',
    element: <PartnerCustomerResults />,
  },
];

export default routes;
