import React, { Suspense, useState } from 'react';
import { Button, useModal, Box } from '@resideo/blueprint-react';
import { FiPlusCircle } from 'react-icons/fi';
import styled from 'styled-components';
import { TableLoading } from '../../../../SharedComponentsModule';
import EmployeesTable from './EmployeesTableComponent/EmployeesTable';
import { useTranslation } from 'react-i18next';
import './EmployeeLookupComponentStyles.css';
import InviteEmployeeModal from './InviteEmployeeModal';
import InvitedEmployeesTable from './InvitedEmployeesTableComponent/InvitedEmployeesTable';
import { useCompanyDetailsContext } from 'CompaniesModule/CompanyDetailsComponent';

const AddIcon = styled(FiPlusCircle)<{ variant: string }>`
  color: ${props => (props.variant === 'primary' ? 'white' : '#153755')};
  height: 12px;
  padding-right: 6px;
  width: 12px;
`;

const EmployeesComponent = () => {
  const { t } = useTranslation();
  const [toggledState, setToggledState] = useState('current');
  const { Modal: InviteModal, openModal: openInviteModal, closeModal: closeInviteModal } = useModal(
    {
      newDesign: true,
    },
  );

  const { companyName, companyId } = useCompanyDetailsContext();

  const toggleTable = () => {
    if (toggledState == 'current') {
      setToggledState('invited');
    } else {
      setToggledState('current');
    }
  };

  return (
    <Box marginX='large' marginBottom='large'>
      <div className='employeeSubHeader'>
        <div className='subHeaderLeft'>
          <h2 style={{ marginRight: '15px' }}>{t('Employees')}</h2>
          <div className='tableToggle' onClick={toggleTable}>
            <div className={'toggleItem' + (toggledState == 'current' ? ' active' : '')}>
              {t('Current')}
            </div>
            <div
              data-test-invited-btn
              className={'toggleItem' + (toggledState == 'invited' ? ' active' : '')}>
              {t('Invited')}
            </div>
          </div>
        </div>
        <div className='subHeaderRight'>
          <Button data-test-employees-invite variant='secondary' onClick={openInviteModal}>
            <AddIcon variant='white' />
            {t('Invite Employee')}
          </Button>
        </div>
      </div>

      {toggledState == 'current' && (
        <Suspense fallback={<TableLoading />}>
          <EmployeesTable companyName={companyName} companyId={companyId} />
        </Suspense>
      )}

      {toggledState == 'invited' && (
        <Suspense fallback={<TableLoading />}>
          <InvitedEmployeesTable />
        </Suspense>
      )}

      <InviteModal title={t('Invite Employee')}>
        <InviteEmployeeModal closeModal={closeInviteModal} />
      </InviteModal>
    </Box>
  );
};

export default React.memo(EmployeesComponent);
