import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import './CompanyLookupStyles.css';
import { TableV2 } from '@resideo/blueprint-react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { formatDateTime } from 'utils/common';
import { checkUrl } from 'utils';
import { useIsMounted } from 'hooks/useIsMounted';
import { useQueryParams } from 'hooks/useQueryParams';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useRisClient } from 'hooks/useRisClient';

const MAX_PAGE_LOAD = 10;

const CompanyLookupTable = ({ filter, filterBy }) => {
  const { t } = useTranslation();
  const { pathname, search } = useLocation();
  const { searchParams, updateSearchParams } = useQueryParams();
  const sortColumn = searchParams.get('sortColumn') || 'createdAt';
  const sortDirection = searchParams.get('sortDirection') || 'DESC';
  const [orderBy, setOrderBy] = useState<any>(null);
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const { client } = useRisClient();
  const [pagination, setPagination] = useState<any>({
    first: MAX_PAGE_LOAD,
    last: undefined,
    before: undefined,
    after: undefined,
  });

  const { data, error } = useSuspenseQuery({
    queryKey: ['companyLookupTable', { filterBy, filter, orderBy, pagination }],
    queryFn: async () => {
      const filterObj: any = filter || filterBy ? {} : undefined;
      if (filter) filterObj.name = filter;
      if (filterBy) filterObj.status = filterBy;
      return client.companies.companiesSearchCompanyEntities(
        filterObj ? JSON.stringify([filterObj]) : undefined,
        JSON.stringify(orderBy ? orderBy : { [sortColumn]: sortDirection }),
        pagination.first,
        pagination.last,
        pagination.before,
        pagination.after,
      );
    },
  });

  // Error handling
  useEffect(() => {
    if (!error) {
      return;
    }

    Sentry.addBreadcrumb({
      message: 'Company Lookup Error',
      level: 'debug',
      data: error,
    });
    Sentry.captureMessage('Ris API Call Error - CompanyLookupQuery', 'fatal');
  }, [error]);

  const pageInfo = data?.pageInfo;

  const generateRows = function(inputData) {
    return inputData?.map(company => {
      return {
        id: company.id,
        companyName: company.name,
        ownerName: findOwner(company.partnerUsers),
        dateSubmitted: formatDateTime(company.createdAt),
        companyPhone: company.primaryPhoneNumber,
        status: transformStatus(company.status),
        actions: [
          {
            name: 'email',
            text: i18n.t('Email Business Owner'),
            action: () => {
              if (findOwnersEmail(company.partnerUsers)) {
                window.location.href = `mailto:${findOwnersEmail(company.partnerUsers)}`;
              }
            },
            disabled: !findOwnersEmail(company.partnerUsers),
          },
          {
            name: 'website',
            text: i18n.t('Visit Company Website'),
            action: () => {
              if (company.websiteUrl) {
                window.open(company.websiteUrl, '_blank');
              }
            },
            disabled: !checkUrl(company.websiteUrl as string),
          },
          {
            name: 'location',
            text: i18n.t('See Location'),
            action: () => {
              const address = company.shipToAddress;
              const addressQuery = `${address?.addressLine1},${address?.city},${address?.stateProvinceRegionCode},${address?.countryCode},${address?.zipPostalCode}`;
              window.open(
                `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                  addressQuery,
                )}`,
                '_blank',
              );
            },
          },
        ],
      };
    });
  };

  const transformStatus = status => {
    if (status === null || status === undefined) {
      status = '';
    } else {
      status = status.charAt(0).toUpperCase() + status.substr(1).toLowerCase();
    }
    return status;
  };

  const findOwner = function(users) {
    if (users) {
      const returnUser = users.find(user => user.roles.includes('OWNER') && user.user != null);

      if (
        !returnUser ||
        !returnUser.user ||
        (!returnUser.user.firstName && !returnUser.user.lastName)
      ) {
        return 'Owner not assigned';
      }

      return `${returnUser.user.firstName} ${returnUser.user.lastName}`;
    }
  };

  const findOwnersEmail = function(users) {
    if (users) {
      const returnUser = users.find(user => user?.roles.includes('OWNER'));

      return returnUser?.user?.contactEmail;
    }
  };

  return (
    <TableV2
      initialSortColumn={sortColumn || 'createdAt'}
      initialSortDirection={sortDirection || 'DESC'}
      actionsButtonName={t('Options')}
      headers={[
        {
          displayName: 'id',
          isSortable: false,
          isHiddenColumn: true,
        },
        {
          displayName: t('Company Name'),
          isSortable: true,
          onSortColumnName: 'name',
          onColumnCellClick: row => {
            navigate(`/companies/${row.id}/info`, { state: { prevPath: pathname, search } });
          },
          useEllipses: true,
        },
        // TODO: When query can sort by owner, add that ability
        {
          displayName: t('Owner Name'),
          isSortable: false,
        },
        {
          displayName: t('Date Submitted'),
          isSortable: true,
          onSortColumnName: 'createdAt',
        },
        { displayName: t('Company Phone'), isSortable: false },
        {
          displayName: t('Status'),
          isSortable: false,
        },
        { displayName: '', isSortable: false },
      ]}
      isError={
        error
          ? {
              errorHeader: t('Data Unavailable'),
              errorMessage: t('Table data unable to load. Try again later.'),
            }
          : undefined
      }
      rows={generateRows(data?.data)}
      onSort={(col, dir) => {
        if (col && dir && isMounted()) {
          setOrderBy({ [col]: dir });
          updateSearchParams([
            { name: 'sortColumn', value: col },
            { name: 'sortDirection', value: dir },
          ]);
        }
      }}
      hasPagination
      hasNext={pageInfo?.hasNextPage}
      hasPrevious={pageInfo?.hasPreviousPage}
      nextButtonLabel={t('Next')}
      previousButtonLabel={t('Previous')}
      onPaginationNextClick={() => {
        setPagination({
          after: pageInfo?.endCursor,
          before: undefined,
          first: MAX_PAGE_LOAD,
          last: undefined,
        });
      }}
      onPaginationPrevClick={() => {
        setPagination({
          after: undefined,
          before: pageInfo?.startCursor,
          first: undefined,
          last: MAX_PAGE_LOAD,
        });
      }}></TableV2>
  );
};

export default CompanyLookupTable;
