import React from 'react';
import { Flex, Text } from '@resideo/blueprint-react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const ProductInfoHeader = styled(Flex)`
  padding-left: 30px;
  justify-content: space-between;
`;

const Border = styled.div`
  border: 1px solid #0000001a;
  margin-right: 15px;
  margin-left: 15px;
`;

const ProductInfoDetailsContainer = styled(Flex)`
  padding-top: 25px;
  gap: calc(30%);
`;

const ProductInfoDetails = styled(Flex)`
  flex-direction: column;
`;

const InfoHeader = styled(Text)`
  font-weight: bold;
  padding-bottom: 5px;
`;

const InfoDetails = styled(Text)`
  padding-bottom: 30px;
`;

const ProductDetails = ({ initialValues }) => {
  const { t } = useTranslation();

  const productCategory = value => {
    return value?.map(x => {
      return (
        ' ' +
        x
          .toLowerCase()
          .split(' ')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ')
      );
    });
  };

  return (
    <div>
      <main>
        <ProductInfoHeader>
          <h2>{t('Product Info')}</h2>
        </ProductInfoHeader>
        <Border />
        <div style={{ paddingLeft: '30px' }}>
          <ProductInfoDetailsContainer>
            <ProductInfoDetails>
              <InfoHeader data-test-displayed-product-name-header>
                {t('Displayed Product Name')}
              </InfoHeader>
              <InfoDetails data-test-displayed-product-name>
                {t(`${initialValues?.productName}`)}
              </InfoDetails>
              <InfoHeader data-test-sku-header>{t('Product SKU')}</InfoHeader>
              <InfoDetails data-test-sku>{t(`${initialValues?.sku}`)}</InfoDetails>
              <InfoHeader data-test-platform-header>{t('Product Platform')}</InfoHeader>
              <InfoDetails data-test-platform>{t(`${initialValues?.productPlatform}`)}</InfoDetails>
              <InfoHeader data-test-family-header>{t('Product Family')}</InfoHeader>
              <InfoDetails data-test-family>{t(`${initialValues?.productFamily}`)}</InfoDetails>
              <InfoHeader data-test-category-header>{t('Product Category')}</InfoHeader>
              <InfoDetails data-test-category>
                {t(`${productCategory(initialValues?.productCategories)}`)}
              </InfoDetails>
            </ProductInfoDetails>
          </ProductInfoDetailsContainer>
        </div>
      </main>
    </div>
  );
};

export default ProductDetails;
