import LeftNavigation from '@resideo/blueprint-react/dist/components/LeftNavigation/LeftNavigation';
import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const NavWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  background-color: #f7f7f7;
  width: 280px;
  height: 100vh;
  padding-top: 60px;
`;

const TopLevelNavigation = ({ menuItems }) => {
  return (
    <NavWrapper data-test-top-level-nav-header>
      <LeftNavigation
        linkRenderer={({ link, title }) => (
          <NavLink data-test-top-level-nav-item to={link}>
            {title}
          </NavLink>
        )}
        menuItems={menuItems}
      />
    </NavWrapper>
  );
};

export default TopLevelNavigation;
