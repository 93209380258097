import React, { Suspense } from 'react';
import { useAuth } from 'utils/auth';
import * as Sentry from '@sentry/react';
import PageError from '../SharedComponentsModule/PageError';
import { PageLoading } from '../SharedComponentsModule/PageLoading';

export const withAnonymousRoute = Component => (
  <Sentry.ErrorBoundary fallback={({ resetError }) => <PageError resetError={resetError} />}>
    <Suspense fallback={<PageLoading />}>
      <Component />
    </Suspense>
  </Sentry.ErrorBoundary>
);

export const withRoute = Component => {
  const { isAuthenticated, isLoading } = useAuth();

  return <>{isAuthenticated && !isLoading ? withAnonymousRoute(Component) : <PageLoading />}</>;
};
