import React, { FC, useState } from 'react';
import { AddressType, CustomerLocationProps } from '../types';
import { Flex, Link, useToastContext, Text, useVerificationModal } from '@resideo/blueprint-react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { convertSchemaAddress } from 'utils/validation';
import { FaMinusCircle, FaPen } from 'react-icons/fa';
import CustomerAccountDevices from './CustomerAccountDevices';
import CustomerAccountServices from './CustomerAccountServices';
import CustomerLocationForm from './CustomerLocationForm';
import { UnableToRemoveLocationModal } from './RemoveLocation/UnableToRemoveLocationModal';
import { RemoveLocationModal } from './RemoveLocation/RemoveLocationModal';
import AddDeviceModal from './DeviceModals/AddDeviceModal';
import { useParams } from 'react-router-dom';
import { useRisClient } from 'hooks/useRisClient';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { UpdatePartnerDeviceLocationModel } from '@resideo/web-integration-services-api-client/dist';
import { ENABLE_ADD_DEVICE_AND_RESEND_INVITE } from 'config';
import { parseGeoAddress } from '../../../../utils/common';

const PenIcon = styled(FaPen)`
  margin-right: 8px;
`;

const MinusIcon = styled(FaMinusCircle)`
  margin-right: 8px;
  margin-bottom: -3px;
  background-color: #166a96;
  color: white;
  border-radius: 10px;
  border: 1px solid #166a96;
`;

const CustomerLocation: FC<CustomerLocationProps> = ({
  customerData,
  location,
  openModal,
  closeModal,
}) => {
  const { addToast } = useToastContext();
  const { t } = useTranslation();
  const { id = '' } = useParams<{ id: string }>();
  const { client } = useRisClient();
  const queryClient = useQueryClient();
  const [suggestedAddress, setSuggestedAddress] = useState<AddressType>({
    address1: '',
    address2: '',
    city: '',
    country: '',
    locationName: '',
    state: '',
    zip: '',
  });
  const [locationValues, setLocationValues] = useState<any>({});
  const {
    openAddressVerificationModal,
    closeAddressVerificationModal,
    AddAddressVerificationModal,
  } = useVerificationModal();

  const hasActivePartnerBrandProfile =
    customerData?.partnerAccount?.partnerProgramEnrollments?.filter(
      enrollment =>
        enrollment?.partnerProgram?.name === 'Homeowner App Branding' &&
        enrollment.status === 'ACTIVE',
    ) || false;

  const deleteCustomerLocation = useMutation({
    mutationKey: ['deletePartnerDeviceLocation'],
    mutationFn: async () => {
      await client.companies.companiesDeletePartnerDeviceLocation(
        id as string,
        location?.id as string,
      );
    },
    onError: () => {
      addToast({
        toastType: 'Error',
        message: t('Something went wrong!'),
      });
    },
    onSuccess: () => {
      // TODO: invalidate query coming from CustomerLocations.tsx once that's in
      queryClient.invalidateQueries({ queryKey: ['getPartnerCustomer'] });
      addToast({
        toastType: 'Success',
        message: t('Location Removed'),
      });
      closeModal();
    },
  });

  const handleDeleteLocation = () => {
    deleteCustomerLocation.mutate();
  };

  const updateCustomerLocation = useMutation({
    mutationKey: ['partnerDeviceLocationUpdate'],
    mutationFn: async (vars: {
      partnerCustomerId: string;
      partnerDeviceLocationId: string;
      location: UpdatePartnerDeviceLocationModel;
    }) => {
      return client.companies.companiesUpdatePartnerDeviceLocation(
        id as string,
        vars?.partnerCustomerId as string,
        vars?.partnerDeviceLocationId as string,
        {
          name: vars?.location?.name,
          address: {
            addressLine1: vars?.location?.address?.addressLine1,
            addressLine2: vars?.location?.address?.addressLine2,
            city: vars?.location?.address?.city,
            stateProvinceRegionCode: vars?.location?.address?.stateProvinceRegionCode,
            zipPostalCode: vars?.location?.address?.zipPostalCode,
            countryCode: vars?.location?.address?.countryCode,
          },
        },
      );
    },
    onSuccess: () => {
      // TODO: invalidate query coming from CustomerLocations.tsx once that's in
      queryClient.invalidateQueries({ queryKey: ['getPartnerCustomer'] });
      addToast({
        toastType: 'Success',
        message: t('Location Info Updated'),
      });
      closeModal();
    },
    onError: () => {
      addToast({
        toastType: 'Error',
        message: t('Something went wrong!'),
      });
    },
  });

  const editCustomerLocation = values => {
    updateCustomerLocation.mutate({
      partnerCustomerId: customerData?.id ?? '',
      partnerDeviceLocationId: location?.id ?? '',
      location: {
        name: values?.locationName,
        address: {
          addressLine1: values.address1,
          addressLine2: values.address2,
          city: values.city,
          stateProvinceRegionCode: values.state,
          zipPostalCode: values.zip,
          countryCode: values.country,
        },
      },
    });
  };

  const handleUpdateLocation = async values => {
    const {
      geoAddress,
      result,
      isLocationTypeRoofTop,
      partialMatch,
      shortNames,
    } = await parseGeoAddress(values);

    // check the response structure for geoAddress
    if ((result && isLocationTypeRoofTop && !partialMatch) || !geoAddress) {
      editCustomerLocation(values);
    } else {
      closeModal();
      // updating the formatted address similar to original address so that we can use the same to make the API call
      setSuggestedAddress(shortNames);
      openAddressVerificationModal();
    }
  };

  const removeLocationLink = () => {
    return (
      <Link
        data-test-remove-link
        paddingRight='30px'
        variant='tertiary'
        data-tip
        onClick={() => {
          openModal({
            title: location?.devices?.length ? 'Cannot Remove Location' : 'Remove Location',
            children: location?.devices?.length ? (
              <UnableToRemoveLocationModal onSubmit={closeModal} />
            ) : (
              <RemoveLocationModal
                onCancel={closeModal}
                onSubmit={handleDeleteLocation}
                locationName={location?.name}
              />
            ),
          });
        }}>
        <MinusIcon />
        {t('Remove')}
      </Link>
    );
  };

  const EditLocationModal = (backButtonAddress?, isBackBtnClicked = false) => {
    const address = isBackBtnClicked ? backButtonAddress : convertSchemaAddress(location?.address);
    const presentLocation = {
      locationName: location?.name,
      locationId: location?.id,
      ...address,
    };
    openModal({
      title: t('Edit Location Info'),
      children: (
        <CustomerLocationForm
          initialValues={presentLocation}
          onSubmit={(values, autoCompleteSelected) => {
            if (autoCompleteSelected) {
              editCustomerLocation(values);
            } else {
              setLocationValues(values);
              handleUpdateLocation(values);
            }
          }}
          onCancel={() => closeModal()}
          isBackBtnClickedFromVerificationModal={isBackBtnClicked}
        />
      ),
    });
  };

  return (
    <div className='LocationSection'>
      <Flex className='LocationSectionHeader'>
        <h3 data-test-location-name>{location?.name}</h3>
        <Flex>
          {removeLocationLink()}
          <div className='VerticalLine'></div>
          <Link
            data-test-edit-link
            paddingRight='30px'
            variant='tertiary'
            onClick={() => {
              EditLocationModal();
            }}>
            <PenIcon />
            {t('Edit')}
          </Link>
        </Flex>
      </Flex>
      <Text data-test-address>
        {`${location?.address.addressLine1} ${location?.address?.addressLine2 || ''}`}
      </Text>
      <Text data-test-city>{`${location?.address.city}, ${location?.address
        .stateProvinceRegionCode || ''} ${location?.address.zipPostalCode}`}</Text>
      <Text data-test-country>{`${location?.address.countryCode}`}</Text>
      <div className='DevicesSection'>
        {ENABLE_ADD_DEVICE_AND_RESEND_INVITE ? (
          <AddDeviceModal location={location} customerData={customerData} />
        ) : (
          <h3 data-test-devices='true'>{t('Devices')}</h3>
        )}
        <CustomerAccountDevices
          locationData={location}
          customerData={customerData}
          openModal={openModal}
        />
      </div>
      <div className='ServicesSection'>
        <h3>{t('Services')}</h3>
      </div>
      {location?.deviceServices?.length ? (
        <CustomerAccountServices
          data={location.deviceServices}
          openModal={openModal}
          hasActivePartnerBrandProfile={hasActivePartnerBrandProfile}
          closeModal={closeModal}
          location={location}
        />
      ) : (
        <Text data-test-no-services>
          {t('There are no services associated with this location.')}
        </Text>
      )}
      <AddAddressVerificationModal
        suggestedAddress={suggestedAddress}
        originalAddress={locationValues}
        closeAddressVerificationModal={closeAddressVerificationModal}
        backButtonClicked={address => {
          EditLocationModal(address, true);
          closeAddressVerificationModal();
        }}
        saveButtonClicked={values => {
          editCustomerLocation(values);
          closeAddressVerificationModal();
        }}
        translations={{
          ModalTitle: t('Edit Location Info'),
          ModalHeader: t('Verification'),
          ModalText: t('We are not able to verify the address you provided.'),
          ModalChooseText: t('Choose one of the following:'),
          OriginalAddressLabel: t('Original Address'),
          SuggestedAddressLabel: t('Suggested Address'),
          BackBtnLabel: t('Back'),
          SaveBtnLabel: t('Save'),
        }}
      />
    </div>
  );
};

export default CustomerLocation;
