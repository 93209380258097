import PhoneNumber, { parsePhoneNumber } from 'awesome-phonenumber';
import i18n from 'i18next';

/**
 * Format date/time based on user's locale, with overriding option
 * @///<reference path="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/DateTimeFormat"/>
 * Be careful with option like 'dateStyle' or 'timeStyle' since they are only experimental phase
 * @param value
 * @param option
 */
export const formatDateTime = (
  value: number | string,
  options?: Intl.DateTimeFormatOptions,
  locale = 'en-US',
) => {
  const overrideOption = { ...options };
  if (value) {
    const date = new Date(value);
    return new Intl.DateTimeFormat(locale, overrideOption).format(date);
  } else {
    return i18n.t('Unknown');
  }
};

export const getInternationalPhone = (phone?: string | null | undefined) => {
  if (!phone) return '';
  let pn = new PhoneNumber(phone);
  if (pn.isValid()) return phone;

  pn = new PhoneNumber(phone, 'US');
  return pn.getNumber('international');
};

export const formatAddress = ({
  addressLine1,
  addressLine2,
  city,
  stateProvinceRegionCode,
  zipPostalCode,
  countryCode,
}) =>
  `${addressLine1} ${addressLine2}, ${city}, ${stateProvinceRegionCode}  ${zipPostalCode} - ${countryCode}`;

export function checkPhoneNumberForCountryCode(value: string) {
  const pn = parsePhoneNumber(value);
  return pn.getCountryCode();
}

export const geocodeAddress = async address => {
  const { maps } = (window as any).google;
  const geocoder = new maps.Geocoder();
  return geocoder.geocode({ address }, results => {
    return results;
  });
};

export const parseGeoAddress = async values => {
  const { address1, city, state, zip, locationName, locationId, name } = values || {};
  const locationAddress = `${address1} ${city} ${state} ${zip}`;
  const geoAddress = await geocodeAddress(locationAddress);
  const addressComponents = geoAddress?.results?.[0]?.address_components;
  const shortNames = parseFormattedAddress(addressComponents, locationName || name, locationId);
  const isLocationTypeRoofTop = geoAddress?.results?.[0]?.geometry?.location_type === 'ROOFTOP';
  const partialMatch = geoAddress?.results?.[0]?.partial_match;
  const result = compareObjects(shortNames, values);

  return {
    geoAddress,
    isLocationTypeRoofTop,
    partialMatch,
    result,
    shortNames,
  };
};

// Function to return Geo coder address in the similar format the API accepts
export const parseFormattedAddress = (data, locationName, locationId?) => {
  const allowedTypes = {
    street_number: '',
    route: '',
    locality: '',
    administrative_area_level_1: '',
    country: '',
    postal_code: '',
    postal_code_suffix: '',
  };
  data.forEach(item => {
    item.types.forEach(type => {
      if (allowedTypes[type] !== undefined) {
        allowedTypes[type] = item.short_name; // Assigning the short_name to the corresponding type
      }
    });
  });

  // Extracting values and format the final string
  const streetNumber = allowedTypes.street_number || '';
  const route = allowedTypes.route || '';
  const city = allowedTypes.locality || '';
  const state = allowedTypes.administrative_area_level_1 || '';
  const postalCode = allowedTypes.postal_code || '';
  const postalSuffix = allowedTypes.postal_code_suffix || '';
  const country = allowedTypes.country || '';

  const formattedPostalCode =
    postalCode && postalSuffix ? `${postalCode}-${postalSuffix}` : postalCode;

  return {
    address1: `${streetNumber} ${route}`,
    address2: '',
    city: city,
    state: state,
    zip: formattedPostalCode,
    country: country,
    locationName: locationName,
    locationId: locationId,
  };
};

// Function to compare Geo coder address and User entered address
export const compareObjects = (newObj, oldObj) => {
  // List of keys to check in both objects
  const keysToCheck = ['address1', 'city', 'state', 'zip', 'country'];

  // Looping through each key in newObj and compare values case-insensitively
  for (const key of keysToCheck) {
    if (newObj[key].toLowerCase() !== oldObj[key]?.toLowerCase()) {
      return false;
    }
  }

  // If all relevant keys and values match (case-insensitively), return true
  return true;
};
