import React, { useEffect, useState } from 'react';
import {
  Breadcrumbs,
  SecondaryNavigation,
  SecondaryNavItem,
  PrimaryActionsButton,
} from '@resideo/blueprint-react';
import { useTranslation } from 'react-i18next';
import {
  Navigate,
  useParams,
  Outlet,
  Link,
  useLocation,
  NavLink,
  RouteObject,
  useOutletContext,
} from 'react-router-dom';
import CompanyInfo from './CompanyInfoComponent/CompanyInfo';
import BrandProfile from './BrandProfileComponent/BrandProfile';
import EmployeesComponent from './EmployeesComponent/EmployeeLookupComponent/EmployeeLookupComponent';
import CustomerLookup from './CustomersComponent/CustomerLookupComponent/CustomerLookup';
import { checkUrl } from 'utils';
import PageHeader from 'SharedComponentsModule/PageHeader';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useRisClient } from 'hooks/useRisClient';
import { PartnerAccountModel } from '@resideo/web-integration-services-api-client';
import { GLOBAL_CUSTOMER_SEARCH } from 'config';

type ContextType = { companyId: any; setShowHeader: any; partnerAccount: any; companyName: string };

export const CompanyInfoLayout = () => {
  const [showHeader, setShowHeader] = useState(true);
  const [partnerAccount, setPartnerAccount] = useState<PartnerAccountModel | null | undefined>(
    null,
  );

  const secondaryNavigation: SecondaryNavItem[] = [
    {
      text: 'Company Info',
      path: 'info',
      ref: 'info',
    },
    {
      text: 'Brand Profile',
      path: 'brand',
      ref: 'brand',
    },
    {
      text: 'Employees',
      path: 'employees',
      ref: 'employees',
    },
    {
      text: 'Customers',
      path: 'customers',
      ref: 'customers',
    },
  ];

  const { client } = useRisClient();

  const { id = '' } = useParams<{ id: string }>();

  const { t } = useTranslation();

  const { state } = useLocation();
  const { data } = useSuspenseQuery({
    queryKey: ['GetCompany', id],
    queryFn: async () => {
      const res = await client.companies.companiesGetCompany(id);
      return res?.data;
    },
  });

  useEffect(() => {
    if (data) {
      setPartnerAccount(data);
    }
  }, [id, data]);

  const companyName = partnerAccount?.name;
  const ownerEmail = partnerAccount?.contactEmail;
  const website = partnerAccount?.websiteUrl || '';
  const address = `${partnerAccount?.shipToAddress?.addressLine1} ${partnerAccount?.shipToAddress?.addressLine2} ${partnerAccount?.shipToAddress?.city} ${partnerAccount?.shipToAddress?.stateProvinceRegionCode} ${partnerAccount?.shipToAddress?.zipPostalCode} ${partnerAccount?.shipToAddress?.countryCode}`;

  const BUTTONS = [
    {
      name: 'email',
      text: 'Email Business Owner',
      action: () => (location.href = `mailto:${ownerEmail}`),
    },
    {
      name: 'website',
      text: 'Visit Company Website',
      action: () => {
        if (website) {
          window.open(website, '_blank');
        }
      },
      disabled: !checkUrl(website as string),
    },
    {
      name: 'location',
      text: 'See Location',
      action: () => window.open(`http://maps.google.com/?q=${address}`),
    },
  ];

  return (
    <>
      {showHeader && (
        <PageHeader
          data-test-options-button
          breadcrumbs={
            <Breadcrumbs>
              <Link
                to={`/companies${
                  state?.search && state?.prevPath === '/companies' ? state.search : ''
                }`}>
                {GLOBAL_CUSTOMER_SEARCH ? t('Partner Companies') : t('Companies')}
              </Link>
              <Link to='#'>{companyName}</Link>
            </Breadcrumbs>
          }
          title={companyName}
          controls={
            <PrimaryActionsButton buttons={BUTTONS} flush='right'>
              Options
            </PrimaryActionsButton>
          }
          secondaryNavigation={
            <SecondaryNavigation
              navItems={secondaryNavigation}
              displayedRef={window.location.pathname.split('/').pop()}
              linkRenderer={({ text, path }) => {
                return (
                  <NavLink to={path} end>
                    {text}
                  </NavLink>
                );
              }}
            />
          }
        />
      )}
      <Outlet
        context={{
          companyId: id,
          setShowHeader,
          partnerAccount,
          companyName,
        }}
      />
    </>
  );
};

export function useCompanyDetailsContext() {
  return useOutletContext<ContextType>();
}

const routes: RouteObject[] = [
  {
    index: true,
    element: <Navigate to='info' replace />,
  },
  {
    path: 'info',
    element: <CompanyInfo />,
  },
  {
    path: 'brand',
    element: <BrandProfile />,
  },
  {
    path: 'employees',
    element: <EmployeesComponent />,
  },
  {
    path: 'customers',
    element: <CustomerLookup />,
  },
];

export default routes;
