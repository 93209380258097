import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Flex, Text } from '@resideo/blueprint-react';
import styled from 'styled-components';

const ModalContainer = styled.div`
  display: block;
`;

const ButtonContainer = styled(Flex)`
  flex-direction: row;
  gap: 16px;
  justify-content: flex-end;
  padding-top: 35px;
`;

export const UnregisterDeviceModal = ({ GoToDeviceServices, onCancel }) => {
  const { t } = useTranslation();
  return (
    <ModalContainer>
      <Text marginBottom='medium' as='h2' fontSize='xLarge' data-test-remove-device-modal-step-2>
        {t('Step 2 of 2: Unregister Device')}
      </Text>
      <Flex>
        <Text fontSize='medium' marginBottom='large' data-test-device-unregister-text1>
          {t('The device has successfully been removed from the location, ')}
          <b>
            {t(
              'but still needs to be unregistered in Device Services to complete the removal process.',
            )}
          </b>
        </Text>
      </Flex>
      <Text marginBottom='medium' fontSize='medium' data-test-device-unregister-detail-text>
        {t(
          'To unregister the device, go to Device Services, under the "Registration" tab select "Un-Register" and follow the prompts.',
        )}
      </Text>
      <ButtonContainer>
        <Button data-test-device-services-cancel-button variant='secondary' onClick={onCancel}>
          {t('Device is Unregistered')}
        </Button>
        <Button
          data-test-device-services-redirect-button
          variant='primary'
          onClick={GoToDeviceServices}>
          {t('Open Device Services')}
        </Button>
      </ButtonContainer>
    </ModalContainer>
  );
};
