import { Box, Button, Flex, SearchField, useModal } from '@resideo/blueprint-react';
import React, { Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiPlusCircle } from 'react-icons/fi';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import CustomerAccountsList from './CustomerAccountsList';
import './CustomerLookupStyles.css';
import AddCustomer from '../AddCustomer';
import { TableLoading } from 'SharedComponentsModule';
import { useCompanyDetailsContext } from 'CompaniesModule/CompanyDetailsComponent';

const AddIcon = styled(FiPlusCircle)`
  color: #153755;
  height: 12px;
  padding-right: 6px;
  width: 12px;
`;

const SearchAndFilterContainer = styled(Flex)`
  padding-right: 30px;
`;

const CustomerLookup = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [filter, setFilter] = useState<string | null>(null);
  const [filterBy, setFilterBy] = useState<string | null>(null);
  const { partnerAccount } = useCompanyDetailsContext();

  const { Modal, openModal, closeModal } = useModal({ newDesign: true, allowOutsideClick: true });

  const getModalProps = v => {
    const versions = {
      createCustomer: {
        title: t('New Customer'),
        children: <AddCustomer partnerAccountId={id} closeModal={closeModal} />,
      },
    };

    return versions[v];
  };

  useEffect(() => {
    return () => {
      setFilter(null);
    };
  }, []);

  return (
    <Box marginX='large'>
      <main>
        <Flex className='CustomersSubheader'>
          <h3 data-test-customers-title color='#303030'>
            {t('Customers')}
          </h3>
          <Flex className='SearchContainer' data-test-customer-accounts>
            <SearchAndFilterContainer>
              <SearchField
                data-test-customer-accounts-search
                style={{ width: '381px' }}
                type='text'
                name='search'
                id='search'
                placeholder={t('Search')}
                onSearchClick={(search, searchBy) => {
                  setFilter(search);
                  setFilterBy(searchBy);
                }}
                onClearClick={() => {
                  setFilter('');
                }}
                searchBy={[
                  {
                    value: 'email',
                    title: t('By Email'),
                    searchPlaceholder: t('Search for Customer by Email'),
                  },
                  {
                    value: 'deviceId',
                    title: t('By Device ID'),
                    searchPlaceholder: t('Search for Customer by Device ID'),
                  },
                  {
                    value: 'location',
                    title: t('By Location'),
                    searchPlaceholder: t('Search for Customer by Location'),
                  },
                  {
                    value: 'name',
                    title: t('By Name'),
                    searchPlaceholder: t('Search for Customer by Name'),
                  },
                ]}
                searchBySelectProps={{
                  value: 'name',
                  id: 'searchCustomerBy',
                }}
              />
            </SearchAndFilterContainer>
            <Button data-test-new-customer disabled={false} variant='secondary' onClick={openModal}>
              <Flex alignItems='center' data-test-new-customer-modal-header>
                <AddIcon />
                {t('Add Customer')}
              </Flex>
            </Button>
          </Flex>
        </Flex>
        <div>
          <Suspense fallback={<TableLoading />}>
            <CustomerAccountsList
              partnerAccountId={partnerAccount?.id as string}
              filter={filter}
              filterBy={filterBy}
            />
          </Suspense>
        </div>
      </main>
      <Modal {...getModalProps('createCustomer')} />
    </Box>
  );
};

export default CustomerLookup;
