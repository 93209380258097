import { useLocation, useSearchParams } from 'react-router-dom';

export type Param = {
  name: string;
  value: string;
};

export const useQueryParams = () => {
  const queryParams = new URLSearchParams(useLocation().search);
  const [searchParams, setSearchParams] = useSearchParams();

  const updateSearchParams = (input: Array<Param>) => {
    const newParams = new URLSearchParams(searchParams);
    input.forEach(param => {
      newParams.set(param.name, param.value);
    });
    setSearchParams(newParams);
  };

  const deleteSearchParams = (input: Array<string>) => {
    const newParams = new URLSearchParams(searchParams);
    input.forEach(param => {
      newParams.delete(param);
    });
    setSearchParams(newParams);
  };

  return { queryParams, updateSearchParams, deleteSearchParams, searchParams };
};
