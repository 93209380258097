import React, { useState } from 'react';
import { CreateCustomerForm, LocationModal } from './Steps';
import { IAddCustomerProps, Steps } from './types';
import VerificationModal from './Steps/VerificationModal';

const AddCustomer = ({ closeModal, partnerAccountId }) => {
  const [step, setStep] = useState<Steps>(Steps.UserInfo);
  // Shared data across all the steps
  const [data, setData] = useState<{ [x: string]: any }>({});

  const addValues = payload => {
    setData({ ...data, ...payload });
  };

  const addCustomerProps: IAddCustomerProps = {
    closeModal,
    addValues,
    data,
    setStep,
    partnerAccountId,
  };

  const steps = {
    [Steps.UserInfo]: <CreateCustomerForm {...addCustomerProps} />,
    [Steps.Location]: <LocationModal {...addCustomerProps} />,
    [Steps.Verification]: <VerificationModal {...addCustomerProps} />,
  };
  return steps[step];
};

export default AddCustomer;
