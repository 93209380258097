import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { Heading, Link, Text } from '@resideo/blueprint-react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const CompanyInfoSection = styled.div`
  margin-left: 30px;
  margin-top: 24px;
  margin-bottom: 20px;
`;

const CompanyInfoBorder = styled.div`
  border: 1px solid #0000001a;
  margin-right: 15px;
  margin-left: -15px;
  margin-bottom: 32px;
`;

const CompanyNameLink = styled(Link)`
  a {
    color: #1c6fb9;
    font-weight: 500;
    text-decoration: none;
    font-size: 20px;
  }
`;

const CompanyAddress = styled.div`
  margin-top: 10px;
`;

const CustomerCompanyDetails = ({ companyData }) => {
  const { t } = useTranslation();
  return (
    <CompanyInfoSection data-test-company-info-section>
      <Heading
        as='h1'
        fontSize='large'
        paddingTop='medium'
        paddingBottom='medium'
        data-test-company-info-title>
        {t('Company Association')}
      </Heading>
      <CompanyInfoBorder />
      <CompanyNameLink>
        <ReactRouterLink to={`/companies/${companyData.id}/info`} data-test-company-info-link>
          {companyData.name}
        </ReactRouterLink>
      </CompanyNameLink>
      <Text>
        {(companyData?.shipToAddress && (
          <CompanyAddress data-test-company-address>
            <Text data-test-company-address-line1>{companyData.shipToAddress.addressLine1}</Text>
            {companyData.shipToAddress.addressLine2 !== '' && (
              <Text data-test-company-address-line2>{companyData.shipToAddress.addressLine2}</Text>
            )}
            <Text data-test-company-address-city-state-zip>
              {companyData.shipToAddress.city}, {companyData.shipToAddress.stateProvinceRegionCode}{' '}
              {companyData.shipToAddress.zipPostalCode}
            </Text>
            <Text data-test-company-address-country-code>
              {companyData.shipToAddress.countryCode}
            </Text>
          </CompanyAddress>
        )) || <CompanyAddress data-test-company-no-address>{t('No Address')}</CompanyAddress>}
      </Text>
    </CompanyInfoSection>
  );
};

export default CustomerCompanyDetails;
