import React, { useEffect, useState } from 'react';
import {
  Button,
  Flex,
  Text,
  Box,
  useToastContext,
  useModal,
  InAppPreview,
  DragandDrop,
} from '@resideo/blueprint-react';
import { InputField, SelectField, PhoneInputField } from '@resideo/blueprint-formik';
import { imkLogoFolder, imkPublicKey, imkUrlEndpoint } from 'config';
import { useTranslation } from 'react-i18next';
import { upload } from '../../../SharedComponentsModule/ImageUpload';
import { ErrorMessage, Form, Formik, FormikErrors } from 'formik';
import { checkPhoneNumber } from '../../../utils/validation';
import { checkUrl, urlEnsureHttps } from 'utils';
import { BrandValues } from './BrandProfile';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import PageHeader from 'SharedComponentsModule/PageHeader';
import { useRisClient } from 'hooks/useRisClient';
import { useMutation } from '@tanstack/react-query';

const ButtonContainer = styled(Flex)`
  justify-content: flex-end;
  margin-top: ${({ theme }) => theme.space.large};
`;

const DragAndDropComponent = styled.div`
  height: 300px;
  width: 396px;
`;

const BrandProfileForm = ({ data, setEditMode, setIsAPISuccess }) => {
  const { t } = useTranslation();
  const { addToast } = useToastContext();
  const { id } = useParams<{ id: string }>();
  const [error, setError] = useState(false);
  const [logoUrl, setLogoUrl] = useState('');
  const [uploading, setUploading] = useState(false);
  const navigate = useNavigate();

  const { client } = useRisClient();

  // Once the image upload is success
  const onUploadSuccess = uploadResult => {
    setLogoUrl(uploadResult.url);
    setUploading(false);
  };

  useEffect(() => {
    if (data?.partnerBrandProfile?.logo) {
      setLogoUrl(initialValues.logoUrl);
    }
  }, []);

  const partnerAccount = data || {};
  const initialValues = {
    name: partnerAccount.name || '',
    websiteUrl: partnerAccount?.partnerBrandProfile?.websiteUrl || '',
    logoUrl: partnerAccount?.partnerBrandProfile?.logo || '',
    customerServiceNumber: partnerAccount?.partnerBrandProfile?.customerServiceNumber || '',
    businessEmail: partnerAccount?.partnerBrandProfile?.businessEmail || '',
    aboutUs: partnerAccount?.partnerBrandProfile?.aboutUs || '',
    status: partnerAccount.partnerBrandProfile?.status || '',
    brandProfileId: partnerAccount?.partnerBrandProfile?.id || '',
  };

  const DragandDropTranslations = {
    draganddropText: t('Drag and drop logo file here'),
    fileRecommendations: t('Recommended dimensions: 300 x 80 pixels'),
    supportedFiles: t('Supported file types: JPEG, PNG, SVG (2MB max)'),
    browseFiles: t('Browse Files'),
    downloadLogo: t('Download Logo'),
    removeLogo: t('Remove Logo'),
    uploadFailed: t('Upload Failed'),
    fileRestrictions: t('File must be JPEG, PNG, SVG and less than 2MB'),
    clear: t('Clear'),
    invalidFile: t('Invalid File'),
    companyLogoRequired: t('Company Logo is required'),
  };

  const imageUploadMutation = useMutation({
    mutationFn: async (vars: { file }) => {
      const response = await client.core.coreGetImagekitToken();
      return { response, file: vars.file };
    },
    onError: () => {
      setUploading(false);
      setError(true);
    },
    onSuccess: ({ response, file }) => {
      const { token, signature, expire } = response.data || {};
      const options = {
        file,
        fileName: file && file.name,
        publicKey: imkPublicKey,
        urlEndpoint: imkUrlEndpoint,
        token,
        signature,
        expire,
        useUniqueFileName: true,
        tags: [],
        folder: imkLogoFolder,
        isPrivateFile: false,
        customCoordinates: '',
        responseFields: [],
      };

      if (file && file.name) {
        upload(options, (err, result) => {
          if (err) {
            addToast({
              toastType: 'Error',
              message: t('common.toasts.error'),
            });
            setUploading(false);
          } else {
            onUploadSuccess(result);
          }
        });
      } else {
        setUploading(false);
        setError(true);
      }
    },
  });

  const createCompanyBrandProfileMutation = useMutation({
    mutationFn: async (vars: { input; partnerAccountId }) => {
      return await client.companies.companiesCreatePartnerBrandProfile(vars.partnerAccountId, {
        ...vars.input,
      });
    },
    onError: () => {
      addToast({
        toastType: 'Error',
        message: t('Something went wrong'),
      });
    },
    onSuccess: () => {
      setEditMode(false);
      setIsAPISuccess(true);
      addToast({
        toastType: 'Success',
        message: t('Brand Profile saved.'),
      });
      navigate(0);
    },
  });

  const saveNewBrandProfile = (values: any) => {
    const { customerServiceNumber, businessEmail, websiteUrl, status } = values;
    createCompanyBrandProfileMutation.mutate({
      partnerAccountId: id,
      input: {
        customerServiceNumber,
        businessEmail,
        websiteUrl: websiteUrl ? urlEnsureHttps(websiteUrl) : '',
        logo: logoUrl,
        status,
      },
    });
  };

  const companyBrandProfileMutation = useMutation({
    mutationFn: async (vars: { input; id }) => {
      return await client.companies.companiesUpdatePartnerBrandProfile(vars.id, {
        ...vars.input,
      });
    },
    onError: () => {
      addToast({
        toastType: 'Error',
        message: t('Something went wrong'),
      });
    },
    onSuccess: () => {
      setEditMode(false);
      addToast({
        toastType: 'Success',
        message: t('Brand Profile saved.'),
      });
      navigate(0);
    },
  });

  const saveUpdatedBrandProfile = (values: any) => {
    const { customerServiceNumber, businessEmail, websiteUrl, status } = values;
    companyBrandProfileMutation.mutate({
      id,
      input: {
        customerServiceNumber,
        businessEmail,
        websiteUrl: websiteUrl ? urlEnsureHttps(websiteUrl) : '',
        logo: logoUrl,
        status,
      },
    });
  };

  const saveChanges = (values: any) => {
    if (!initialValues.brandProfileId) {
      saveNewBrandProfile(values);
    } else {
      saveUpdatedBrandProfile(values);
    }
  };

  const validate = (values: BrandValues) => {
    const errors: FormikErrors<BrandValues> = {};
    const emailRegex = /^\S+@\S+\.\S+$/;
    const container = document.getElementById('NoLogoContainer');

    if (!values.status) {
      errors.status = t('Status is required');
    }

    if (!values.customerServiceNumber) {
      errors.customerServiceNumber = t('Customer Service Phone is required.');
    } else if (!checkPhoneNumber(values.customerServiceNumber)) {
      errors.customerServiceNumber = t('Invalid phone number.');
    }

    if (!logoUrl) {
      container?.classList.add('NoLogoContainer');
      errors.logoUrl = t('Company Logo is required');
    } else {
      container?.classList.remove('NoLogoContainer');
    }

    if (values.businessEmail) {
      if (!emailRegex.test(values.businessEmail as string)) {
        errors.businessEmail = t('Invalid email');
      }
    }

    if (!values.businessEmail) {
      errors.businessEmail = t('Customer Service Email is required.');
    }

    if (!values.websiteUrl) {
      errors.websiteUrl = t('Company Website is required.');
    }
    if (values.websiteUrl) {
      if (!checkUrl(values.websiteUrl as string)) {
        errors.websiteUrl = t('Invalid web address.');
      }
    }
    if (Object.keys(errors).length) {
      addToast({
        toastType: 'Error',
        message: t('Brand Profile cannot be saved without all required information.'),
      });
    }
    return errors;
  };

  const uploadLogo = (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    imageUploadMutation.mutate({ file });
  };

  const { Modal, openModal, closeModal } = useModal({ newDesign: true });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={saveChanges}
      validateOnChange={false}
      validateOnBlur={false}
      validate={validate}
      enableReinitialize>
      {({ resetForm, values }) => {
        const renderButtons = () => (
          <>
            <Button
              data-test-cancel-button
              variant='secondary'
              marginRight={'medium'}
              type='button'
              onClick={() => {
                setEditMode(false);
                setLogoUrl(initialValues.logoUrl);
                resetForm();
              }}>
              {t('Cancel')}
            </Button>
            <Button data-test-save-button variant='primary' type='submit'>
              {t('Save')}
            </Button>
          </>
        );
        return (
          <Form className='EditForm' role='form' noValidate>
            <PageHeader
              data-test-brand-profile-edit-heading
              title={`${initialValues?.name} ${t('Brand Profile')}`}
              controls={!initialValues?.brandProfileId ? <Flex>{renderButtons()}</Flex> : null}
            />

            <Box marginX='large'>
              <h3 color='#303030'>{t('Brand Profile')}</h3>
              <hr />
              <div className='EditFormContainer'>
                <Box data-test-select-dropdown width={170}>
                  <SelectField
                    version='v2'
                    marginBottom='medium'
                    variant='active'
                    label={t('Status')}
                    name='status'>
                    <option value=''>{t('Select...')}</option>
                    <option value='ACTIVE'>{t('Active')}</option>
                    <option value='ARCHIVED'>{t('Deactivated')}</option>
                  </SelectField>
                </Box>

                <Box className='EditableFields' maxWidth={400}>
                  <Text data-test-company-logo-header className='BrandProfileCompanyLogo'>
                    {t('Company Logo')}
                  </Text>
                  <DragAndDropComponent data-test-company-logo-container id='NoLogoContainer'>
                    <DragandDrop
                      uploadImage={file => {
                        uploadLogo(file);
                      }}
                      image={logoUrl}
                      setImage={() => {
                        setLogoUrl('');
                      }}
                      error={error}
                      isUploading={uploading}
                      clearError={() => setError(false)}
                      translations={DragandDropTranslations}
                    />
                  </DragAndDropComponent>
                  <ErrorMessage
                    name='logoUrl'
                    render={msg => <div className='NoLogo'>{msg}</div>}
                  />
                  <Text data-test-customer-service-header className='BrandProfileSectionTextEdit'>
                    {t('Customer Service Info')}
                  </Text>
                  <Box data-test-customer-service-phone-input width={[2 / 3]} marginBottom='medium'>
                    <PhoneInputField
                      version='v2'
                      label={t('Customer Service Phone')}
                      name='customerServiceNumber'
                      type='text'
                      phoneInputConfig={{
                        preferredCountries: ['us', 'ca'],
                        country: 'us',
                        countryCodeEditable: false,
                      }}
                    />
                  </Box>

                  <Box data-test-customer-service-email-input>
                    <InputField
                      version='v2'
                      label={t('Customer Service Email')}
                      marginBottom='medium'
                      name='businessEmail'
                      type='text'
                    />
                  </Box>
                  <Box data-test-customer-service-website-input>
                    <InputField
                      version='v2'
                      label={t('Company Website')}
                      marginBottom='medium'
                      name='websiteUrl'
                      type='text'
                    />
                  </Box>
                </Box>
                {initialValues?.brandProfileId && (
                  <Flex className='ButtonContainer' justifyContent='space-between'>
                    <Button
                      variant='secondary'
                      marginRight={'medium'}
                      type='button'
                      onClick={() => {
                        openModal();
                      }}>
                      {t('Preview')}
                    </Button>
                    <div>{renderButtons()}</div>
                  </Flex>
                )}
                <Modal title={t('In-App Preview')} className='PreviewModal'>
                  <InAppPreview
                    logo={`${logoUrl}?tr=h-212,ar-15-4,c-at_max`}
                    phone={values?.customerServiceNumber ?? ''}
                    email={values?.businessEmail ?? ''}
                    url={values?.websiteUrl ?? ''}
                  />
                  <ButtonContainer>
                    <Button
                      data-test-cancel-button
                      variant='secondary'
                      marginRight={'medium'}
                      type='button'
                      onClick={() => {
                        closeModal();
                      }}>
                      {t('Close')}
                    </Button>
                  </ButtonContainer>
                </Modal>
              </div>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export default BrandProfileForm;
