import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text, Button, Flex } from '@resideo/blueprint-react';
import styled from 'styled-components';

export const ButtonContainer = styled(Flex)`
  flex-direction: row;
  gap: 16px;
  width: 100%;
  justify-content: flex-end;
  padding-right: 20px;
  padding-top: 35px;
  border-top: 1px solid rgb(218, 218, 218);
`;

export const ModalContent = styled(Flex)`
  line-height: 24px;
  flex-direction: column;
`;

export const RemoveDeviceModal = ({ onCancel, onSubmit, deviceData, locationData }) => {
  const { t } = useTranslation();

  return (
    <ModalContent>
      <Text data-test-remove-device-text marginBottom='medium'>
        {t('This device will be removed permanently from this location.')}
      </Text>
      <Text data-test-remove-device-name fontWeight='bold'>{`${
        deviceData.typeName ? t('Connected Device') : t('Unknown')
      }`}</Text>
      <Text data-test-remove-device-id marginBottom='medium'>{`${deviceData.deviceId}`}</Text>
      <Text data-test-remove-device-text-2 marginBottom='medium'>
        {t('will be removed from:')}
      </Text>
      <Text data-test-remove-device-location-name fontWeight='bold'>{`${locationData.name}`}</Text>
      <Text data-test-remove-device-location-address>
        {/* {' '} */}
        {`${locationData.address.addressLine1} ${locationData.address.addressLine2}`}
      </Text>
      <Text data-test-remove-device-location-city-state-zip>{`${
        locationData.address.city
      }, ${locationData.address.stateProvinceRegionCode || ''}, ${
        locationData.address.zipPostalCode
      }`}</Text>
      <Text data-test-remove-device-location-country>{`${locationData.address.countryCode}`}</Text>
      <ButtonContainer>
        <Button data-test-remove-device-cancel-button variant='secondary' onClick={onCancel}>
          {t('Cancel')}
        </Button>
        <Button
          data-test-remove-device-remove-button
          variant='destructivePrimary'
          onClick={onSubmit}>
          {t('Remove')}
        </Button>
      </ButtonContainer>
    </ModalContent>
  );
};
