import React from 'react';
import { useTranslation } from 'react-i18next';
import { IAddCustomerProps, Steps } from '../types';
import { Text, useToastContext } from '@resideo/blueprint-react';
import CustomerLocationForm from '../CustomerDetailsComponent/CustomerLocationForm';
import { useRisClient } from 'hooks/useRisClient';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  CreatePartnerCustomerModel,
  CreatePartnerDeviceLocationModel,
} from '@resideo/web-integration-services-api-client/dist';
import { checkPhoneNumberForCountryCode } from 'utils/common';

const initialLocationValues = {
  locationName: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
  country: '',
};
const LocationModal: React.FC<IAddCustomerProps> = ({
  setStep,
  addValues,
  data,
  partnerAccountId,
  closeModal,
}) => {
  const { addToast } = useToastContext();
  const { t } = useTranslation();
  const { client } = useRisClient();
  const queryClient = useQueryClient();

  const createCustomer = useMutation({
    mutationFn: async (cust: CreatePartnerCustomerModel) => {
      return await client.companies.companiesCreatePartnerCustomer(partnerAccountId, {
        firstName: cust.firstName,
        lastName: cust.lastName,
        email: cust.email,
        phoneNumber: cust.phoneNumber,
      });
    },
  });

  const createLocation = useMutation({
    mutationFn: async (vars: { partnerCustomerId; loc: CreatePartnerDeviceLocationModel }) => {
      return client.companies.companiesCreatePartnerDeviceLocation(
        partnerAccountId as string,
        vars.partnerCustomerId,
        {
          name: vars.loc.name,
          address: {
            addressLine1: vars.loc?.address?.addressLine1,
            addressLine2: vars.loc?.address?.addressLine2,
            city: vars.loc?.address?.city,
            stateProvinceRegionCode: vars.loc?.address?.stateProvinceRegionCode,
            zipPostalCode: vars.loc?.address?.zipPostalCode,
            countryCode: vars.loc?.address?.countryCode,
          },
        },
      );
    },
    onError: () => {
      addToast({
        toastType: 'Error',
        message: t('Something went wrong!'),
      });
    },
    onSuccess: (data, vars) => {
      queryClient.invalidateQueries({ queryKey: ['partnerCustomers'] });
      closeModal();
      addToast({
        toastType: 'Success',
        message: t(`Customer added`),
        linkHref: `/companies/${partnerAccountId}/customers/${vars.partnerCustomerId}`,
        linkText: 'View Customer',
      });
    },
  });

  const countryCodeIsTheOnlyInput =
    data?.phoneNumber &&
    `+${checkPhoneNumberForCountryCode(data?.phoneNumber).toString()}` === data?.phoneNumber;

  const handleSubmit = async values => {
    addValues({ ...values });
    try {
      const cust = await createCustomer.mutateAsync({
        firstName: data?.firstName,
        lastName: data?.lastName,
        email: data?.email,
        phoneNumber: countryCodeIsTheOnlyInput ? '' : data?.phoneNumber,
      });

      const customerId = cust?.data?.id as string;

      if (customerId) {
        addValues({ customerId });
        createLocation.mutate({
          partnerCustomerId: customerId,
          loc: {
            name: values?.locationName,
            address: {
              addressLine1: values?.address1,
              addressLine2: values?.address2,
              city: values?.city,
              stateProvinceRegionCode: values?.state,
              zipPostalCode: values?.zip,
              countryCode: values?.country,
            },
          },
        });
      }
    } catch {
      addToast({
        toastType: 'Error',
        message: t('Something went wrong!'),
      });
    }
  };

  return (
    <>
      <Text
        data-test-new-customer-modal-enter-location
        marginBottom='medium'
        as='h2'
        fontSize='xLarge'>
        {t('Step 2 of 2: Enter Location Information')}
      </Text>
      <CustomerLocationForm
        initialValues={data?.isBackButtonClicked ? data : initialLocationValues}
        onSubmit={(values, autoCompleteSelected) => {
          if (autoCompleteSelected) {
            handleSubmit(values);
          } else {
            addValues({ ...values });
            setStep(Steps.Verification);
          }
        }}
        onCancel={() => {
          if (setStep) {
            setStep(Steps.UserInfo);
          }
        }}
        isStep
        isBackBtnClickedFromVerificationModal={data?.isBackButtonClicked}
      />
    </>
  );
};
export default LocationModal;
