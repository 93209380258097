import { light } from '@resideo/blueprint-theme';

const colors = {
  ...light.colors,
  welcomeBanner: '#f8f8f8',
  header: {
    icons: '#ffffff',
    primaryBackground: '#032a3c',
    primaryLink: '#4cb5f5',
    primaryLinkActive: '#ffffff',
    secondaryBackground: '#ffffff',
    secondaryLink: '#217eae',
    secondaryLinkActive: '#000000',
  },
  list: {
    hover: '#ececec',
  },
  home: {
    icons: '#012a3a',
  },
  secondaryBackground: '#f8f8f8',
  sidebar: {
    circle: '#add8e6',
    background: '#f5f5f5',
    text: '#ffffff',
    selected: '#f0f8ff',
    block: '#ffffff',
    hover: '#e6e6fa',
    active: '#fffff0',
  },
};

export default {
  ...light,
  colors,
};
