import React from 'react';
import { Button, useToastContext } from '@resideo/blueprint-react';
import { useTranslation } from 'react-i18next';
import config from 'config';
import axios from 'axios';

const ResetUserPasswordModal = ({ user, closeModal }) => {
  const { t } = useTranslation();
  const { addToast } = useToastContext();
  const { clientId, changePasswordEndpoint } = config;

  const sendPasswordResetEmail = () => {
    const options = {
      method: 'POST',
      url: changePasswordEndpoint,
      headers: { 'content-type': 'application/json' },
      data: {
        client_id: clientId,
        email: user.contactEmail,
        connection: 'Username-Password-Authentication',
      },
    };

    axios
      .request(options)
      .then(function() {
        addToast({ toastType: 'Success', message: t('Email sent.') });
      })
      .catch(function() {
        addToast({ toastType: 'Error', message: t('An error occurred. Please try again.') });
      });
  };

  return (
    <div style={{ marginTop: '-32px', marginBottom: '-8px' }}>
      <p data-test-reset-password-title style={{ marginTop: '30px' }}>
        {t('The user will be sent a reset password link to the following email.')}
      </p>
      <p
        data-test-password-reset-email-header
        style={{ marginTop: '30px', marginBottom: '5px', fontWeight: 'bold' }}>
        {t('Email')}
      </p>
      <p data-test-password-reset-email style={{ marginTop: '0px' }}>
        {t(user.contactEmail)}
      </p>
      <div style={{ display: 'flex', justifyContent: 'right' }}>
        <Button
          data-test-password-reset-cancel-btn
          variant='secondary'
          marginBottom={['medium', 0]}
          type='button'
          onClick={closeModal}>
          {t('Cancel')}
        </Button>
        <Button
          data-test-password-reset-send-btn
          variant='primary'
          marginBottom={['medium', 0]}
          marginLeft={'small'}
          type='submit'
          onClick={() => {
            sendPasswordResetEmail();
            closeModal();
          }}>
          {t('Send Email')}
        </Button>
      </div>
    </div>
  );
};

export default ResetUserPasswordModal;
