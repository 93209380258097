import React, { useState, useEffect } from 'react';
import { Spinner, useToastContext, useVerificationModal } from '@resideo/blueprint-react';
import { AddressType, IAddCustomerProps, Steps } from '../types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  CreatePartnerCustomerModel,
  CreatePartnerDeviceLocationModel,
} from '@resideo/web-integration-services-api-client';
import { useTranslation } from 'react-i18next';
import { useRisClient } from '../../../../hooks/useRisClient';
import {
  checkPhoneNumberForCountryCode,
  geocodeAddress,
  parseFormattedAddress,
} from '../../../../utils/common';
import styled from 'styled-components';

const LoaderIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
`;

const VerificationModal: React.FC<IAddCustomerProps> = ({
  setStep,
  addValues,
  data,
  partnerAccountId,
  closeModal,
}) => {
  const { addToast } = useToastContext();
  const { t } = useTranslation();
  const { client } = useRisClient();
  const queryClient = useQueryClient();
  const [locationValues, setLocationValues] = useState<any>({});
  const [isLoader, setIsLoader] = useState<boolean>(false);
  const [suggestedAddress, setSuggestedAddress] = useState<AddressType>({
    address1: '',
    address2: '',
    city: '',
    country: '',
    locationName: '',
    state: '',
    zip: '',
  });
  const {
    openAddressVerificationModal,
    closeAddressVerificationModal,
    AddAddressVerificationModal,
  } = useVerificationModal();

  useEffect(() => {
    const parseAddress = async () => {
      const { address1, city, state, zip, locationName } = data || {};
      const locationAddress = `${address1} ${city} ${state} ${zip}`;
      const geoAddress = await geocodeAddress(locationAddress);
      const addressComponents = geoAddress?.results?.[0]?.address_components;
      const shortNames = parseFormattedAddress(addressComponents, locationName);
      setLocationValues(data);
      setSuggestedAddress(shortNames);
      addValues({ ...shortNames });
      if (geoAddress) {
        openAddressVerificationModal();
        setIsLoader(false);
      }
    };
    parseAddress();
    setIsLoader(true);
  }, []);

  const createCustomer = useMutation({
    mutationFn: async (cust: CreatePartnerCustomerModel) => {
      return await client.companies.companiesCreatePartnerCustomer(partnerAccountId, {
        firstName: cust.firstName,
        lastName: cust.lastName,
        email: cust.email,
        phoneNumber: cust.phoneNumber,
      });
    },
  });

  const countryCodeIsTheOnlyInput =
    data?.phoneNumber &&
    `+${checkPhoneNumberForCountryCode(data?.phoneNumber).toString()}` === data?.phoneNumber;

  const createLocation = useMutation({
    mutationFn: async (vars: { partnerCustomerId; loc: CreatePartnerDeviceLocationModel }) => {
      return client.companies.companiesCreatePartnerDeviceLocation(
        partnerAccountId as string,
        vars.partnerCustomerId,
        {
          name: vars.loc.name,
          address: {
            addressLine1: vars.loc?.address?.addressLine1,
            addressLine2: vars.loc?.address?.addressLine2,
            city: vars.loc?.address?.city,
            stateProvinceRegionCode: vars.loc?.address?.stateProvinceRegionCode,
            zipPostalCode: vars.loc?.address?.zipPostalCode,
            countryCode: vars.loc?.address?.countryCode,
          },
        },
      );
    },
    onError: () => {
      addToast({
        toastType: 'Error',
        message: t('Something went wrong!'),
      });
    },
    onSuccess: (data, vars) => {
      queryClient.invalidateQueries({ queryKey: ['partnerCustomers'] });
      closeModal();
      addToast({
        toastType: 'Success',
        message: t(`Customer added`),
        linkHref: `/companies/${partnerAccountId}/customers/${vars.partnerCustomerId}`,
        linkText: 'View Customer',
      });
    },
  });

  const updateCustomerLocation = async (values?) => {
    addValues({ ...values });
    try {
      const cust = await createCustomer.mutateAsync({
        firstName: data?.firstName,
        lastName: data?.lastName,
        email: data?.email,
        phoneNumber: countryCodeIsTheOnlyInput ? '' : data?.phoneNumber,
      });

      const customerId = cust?.data?.id as string;

      if (customerId) {
        addValues({ customerId });
        createLocation.mutate({
          partnerCustomerId: customerId,
          loc: {
            name: values?.locationName,
            address: {
              addressLine1: values?.address1,
              addressLine2: values?.address2,
              city: values?.city,
              stateProvinceRegionCode: values?.state,
              zipPostalCode: values?.zip,
              countryCode: values?.country,
            },
          },
        });
      }
    } catch {
      addToast({
        toastType: 'Error',
        message: t('Something went wrong!'),
      });
    }
  };

  return isLoader ? (
    <LoaderIcon>
      <Spinner color='primary' size='xxLarge' />
    </LoaderIcon>
  ) : (
    <AddAddressVerificationModal
      suggestedAddress={suggestedAddress}
      originalAddress={locationValues}
      closeAddressVerificationModal={() => {
        closeAddressVerificationModal();
        closeModal();
      }}
      backButtonClicked={() => {
        addValues({ ...locationValues, isBackButtonClicked: true });
        setStep(Steps.Location);
        closeAddressVerificationModal();
      }}
      saveButtonClicked={values => {
        updateCustomerLocation(values);
      }}
      translations={{
        ModalTitle: t('New Customer'),
        ModalHeader: t('Verification'),
        ModalText: t('We are not able to verify the address you provided.'),
        ModalChooseText: t('Choose one of the following:'),
        OriginalAddressLabel: t('Original Address'),
        SuggestedAddressLabel: t('Suggested Address'),
        BackBtnLabel: t('Back'),
        SaveBtnLabel: t('Save'),
      }}
    />
  );
};

export default VerificationModal;
