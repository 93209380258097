import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { TableV2 } from '@resideo/blueprint-react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useQueryParams } from 'hooks/useQueryParams';
import { useRisClient } from 'hooks/useRisClient';
import { useSuspenseQuery } from '@tanstack/react-query';
import useStateData from 'hooks/useStateData';
import { PartnerCustomerModel } from '@resideo/web-integration-services-api-client';
import { getPartnerLocationCell } from 'SharedComponentsModule/TableCells/PartnerLocationCell';
import { getDeviceCell } from 'SharedComponentsModule/TableCells/DeviceCell';
import { getFilter } from './PartnerCustomersSearch';

const MAX_PAGE_LOAD = 10;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #1c6fb9;
`;

const NoDataText = styled.p`
  font-weight: normal;
  max-width: ${({ theme }) => theme.breakpoints.small};
  text-align: left;
  line-height: 1.5;
`;

const PartnerCustomersLookupTable = ({ filter, filterBy }) => {
  const { t } = useTranslation();
  const { pathname, search } = useLocation();
  const { searchParams } = useQueryParams();
  const sortColumn = searchParams.get('sortColumn') || 'lastName';
  const sortDirection = searchParams.get('sortDirection') || 'ASC';
  const [orderBy, setOrderBy] = useState<any>({ [sortColumn]: sortDirection });
  const [pageInfo, setPageInfo] = useState<any>();
  const [pagination, setPagination] = useState<any>({
    first: MAX_PAGE_LOAD,
    last: undefined,
    before: undefined,
    after: undefined,
  });
  const navigate = useNavigate();
  const { client } = useRisClient();
  const { stateData } = useStateData();

  const { data: searchData } = useSuspenseQuery({
    queryKey: ['getPartnerCustomers', { filterBy, filter, pagination, orderBy }],
    queryFn: async () => {
      const res = await client.companies.companiesSearchCompanyCustomers(
        filterBy ? JSON.stringify(getFilter(filterBy, filter)) : '',
        JSON.stringify(orderBy),
        pagination.first,
        pagination.last,
        pagination.before,
        pagination.after,
      );
      return res;
    },
  });

  useEffect(() => {
    if (searchData.data?.length === 1 && !searchData?.pageInfo?.hasPreviousPage) {
      const partnerAccountId = searchData[0].partnerAccount?.id;
      const custId = searchData[0].id;

      if (custId) {
        navigate(
          `/companies/${partnerAccountId}/customers/${custId}?filter=${encodeURIComponent(
            filter as string,
          )}&filterBy=${filterBy}`,
        );
      }
    }

    if (searchData.pageInfo) {
      setPageInfo(searchData.pageInfo);
    }
  }, [searchData]);

  const generateRows = (customers: PartnerCustomerModel[]) => {
    const rows = customers?.map(cust => {
      return {
        id: cust?.id,
        name: (
          <StyledLink
            to={`/companies/${cust.partnerAccount?.id}/customers/${
              cust.id
            }?filter=${encodeURIComponent(filter as string)}&filterBy=${filterBy}`}>
            {cust?.firstName} {cust?.lastName}
          </StyledLink>
        ),
        location: getPartnerLocationCell(cust?.id, cust?.deviceLocations, stateData),
        email: cust.email,
        devices: getDeviceCell(cust?.id, cust?.deviceLocations, filter),
        companyAssociation: (
          <StyledLink
            to={`/companies/${cust.partnerAccount?.id}`}
            state={{ prevPath: pathname, search }}>
            {cust.partnerAccount?.name}
          </StyledLink>
        ),
        actions: [
          {
            name: 'userDetails',
            text: t('View Account'),
            action: () => {
              navigate(
                `/companies/${cust.partnerAccount?.id}/customers/${
                  cust.id
                }?filter=${encodeURIComponent(filter as string)}&filterBy=${filterBy}`,
              );
            },
          },
        ],
      };
    });

    return rows;
  };

  return (
    <div>
      <TableV2
        dataTestAttribute='data-test-partner-customers-table'
        initialSortColumn={sortColumn || 'name'}
        initialSortDirection={sortDirection || 'ASC'}
        actionsButtonName={t('Options')}
        NoDataMessage={
          <div>
            <h3>{t('No Results Found')}</h3>
            <NoDataText>{t('Check your entry and try again.')} </NoDataText>
          </div>
        }
        headers={[
          {
            displayName: 'id',
            isSortable: false,
            isHiddenColumn: true,
          },
          {
            displayName: t('Customer Name'),
            isSortable: true,
            onSortColumnName: 'customerName',
          },
          { displayName: t('Address'), isSortable: false, notBlueprintEllipses: true },
          {
            displayName: t('Email'),
            isSortable: true,
          },
          { displayName: t('Device/Mac ID(s)'), isSortable: false },
          {
            displayName: t('Company Association'),
            isSortable: false,
          },
          {
            displayName: '',
            isSortable: false,
          },
        ]}
        rows={generateRows(searchData?.data as PartnerCustomerModel[])}
        onSort={(col, dir) => {
          if (col && dir) {
            setOrderBy(col === 'customerName' ? { lastName: dir, firstName: dir } : { [col]: dir });
          }
        }}
        hasPagination
        hasNext={pageInfo?.hasNextPage}
        hasPrevious={pageInfo?.hasPreviousPage}
        nextButtonLabel={t('Next')}
        previousButtonLabel={t('Previous')}
        onPaginationNextClick={() => {
          setPagination({
            first: MAX_PAGE_LOAD,
            last: undefined,
            before: undefined,
            after: pageInfo?.endCursor,
          });
        }}
        onPaginationPrevClick={() => {
          setPagination({
            first: undefined,
            last: MAX_PAGE_LOAD,
            before: pageInfo?.startCursor,
            after: undefined,
          });
        }}></TableV2>
    </div>
  );
};

export default PartnerCustomersLookupTable;
