import React from 'react';
import { Text, Link as BPLink, Box } from '@resideo/blueprint-react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

const Locations = styled(Box)<{ locations: number }>`
  padding-top: ${props =>
    props.locations === 0 ? props.theme.space.xSmall : props.theme.space.medium};
`;

export const getPartnerLocationCell = (id, deviceLocations, stateData) => {
  const locations = deviceLocations?.map(loc => {
    const addressField = loc?.address;
    if (addressField) {
      const {
        addressLine1,
        addressLine2,
        city,
        stateProvinceRegionCode,
        zipPostalCode,
        countryCode,
      } = addressField;
      const stateName =
        stateData?.find(
          state => state.country_code === countryCode && state.iso2 === stateProvinceRegionCode,
        )?.name ?? '';
      return (
        <>
          <Text fontSize='medium'>
            {addressLine1} {addressLine2}
          </Text>
          <Text fontSize='medium'>{`${city} ${stateName || ''} ${zipPostalCode}`}</Text>
        </>
      );
    }
  });
  // Adding a check for locations existence
  if (locations) {
    return (
      <>
        {/* Display first location */}
        {locations[0]}
        {/* More than one location, show tooltip */}
        {locations.length > 1 && (
          <>
            <BPLink data-tip data-for={'locations-' + id}>{`+${locations.length - 1} More`}</BPLink>
            <ReactTooltip
              type='light'
              id={'locations-' + id}
              place='bottom'
              effect='solid'
              delayHide={500}
              delayUpdate={500}>
              {locations.slice(1).map((loc, i) => (
                <Locations locations={i} key={i}>
                  {loc}
                </Locations>
              ))}
            </ReactTooltip>
          </>
        )}
      </>
    );
  }
  return <></>;
};
