// This module pertains to all sections for products.
import React from 'react';
import { Outlet, RouteObject } from 'react-router-dom';
import ProductPage from './ProductPageComponent/ProductPage';
import ProductsPage from './ProductsPageComponent/ProductsPage';

export const ProductsModule = () => <Outlet />;

const routes: RouteObject[] = [
  {
    index: true,
    element: <ProductsPage />,
  },
  {
    path: ':id',
    element: <ProductPage />,
  },
];

export default routes;
