import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { TableV2, Text, useModal, Link as BPLink } from '@resideo/blueprint-react';
import { useTranslation } from 'react-i18next';
import DeleteUserModal from '../DeleteUserModalComponent/DeleteUserModal';
import ResetUserPasswordModal from './ResetUserPasswordModal';
import styled from 'styled-components';
import { useQueryParams } from 'hooks/useQueryParams';
import ReactTooltip from 'react-tooltip';
import { useRisClient } from 'hooks/useRisClient';
import { useSuspenseQuery } from '@tanstack/react-query';
import { formatDateTime } from 'utils/common';

const MAX_PAGE_LOAD = 10;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #1c6fb9;
`;

const StyledTooltip = styled(ReactTooltip)`
  &.show {
    opacity: 1 !important;
    box-shadow: 0px 2px 10px #0000004d;
  }
`;

const StyledExternalLink = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.primary};
`;

const NoDataText = styled.p`
  font-weight: normal;
  max-width: ${({ theme }) => theme.breakpoints.small};
  text-align: left;
  line-height: 1.5;
`;

const displayDeviceIds = (id: string, devices: string[]) => {
  if (!devices?.length) return <Text key={id}>None</Text>;
  return (
    <>
      {/* Display first three device IDs */}
      {devices.slice(0, 3).map((device, i) => {
        return <Text key={i}>{device}</Text>;
      })}
      {/* More than three devices, show tooltip */}
      {devices.length > 3 && (
        <>
          <BPLink data-tip data-for={'devices-' + id}>{`+${devices.length - 3} More`}</BPLink>
          <StyledTooltip
            type='light'
            id={'devices-' + id}
            place='bottom'
            effect='solid'
            delayHide={500}
            delayUpdate={500}>
            {devices.slice(3).map((device, i) => (
              <div key={i}>
                <Text fontSize='medium'>{device}</Text>
              </div>
            ))}
          </StyledTooltip>
        </>
      )}
    </>
  );
};

const UsersLookupTable = ({ filter, filterBy }) => {
  const { t } = useTranslation();
  const { pathname, search } = useLocation();
  const { searchParams } = useQueryParams();
  const sortColumn = searchParams.get('sortColumn') || 'name';
  const sortDirection = searchParams.get('sortDirection') || 'ASC';
  const [touchedUser] = useState(null);
  const [pageInfo, setPageInfo] = useState<any>();
  const [pagination, setPagination] = useState<any>({
    first: MAX_PAGE_LOAD,
    last: undefined,
    before: undefined,
    after: undefined,
  });
  const navigate = useNavigate();
  const { client } = useRisClient();

  const { Modal: RemoveModal, closeModal: closeRemoveModal } = useModal({ newDesign: true });
  const { Modal: ResetPasswordModal, closeModal: closeResetPasswordModal } = useModal({
    newDesign: true,
  });

  const getFilter = () => {
    if (filterBy.toLowerCase() === 'name') {
      if (filter.includes(' ')) {
        const parts = filter.split(' ');
        return [{ firstName: parts[0], lastName: parts[1] }];
      } else {
        return [{ firstName: filter }, { lastName: filter }];
      }
    }

    if (filterBy.toLowerCase() === 'email') {
      return [{ contactEmail: filter }];
    }

    if (filterBy.toLowerCase() === 'mac_address') {
      return [{ macAddress: filter }];
    }

    return [{}];
  };

  const { data: RisData } = useSuspenseQuery({
    queryKey: ['getUsers', { filterBy, filter, pagination }],
    queryFn: async () => {
      const res = await client.users.usersSearchUsers(
        filterBy ? JSON.stringify(getFilter()) : '',
        JSON.stringify({ firstName: 'ASC', lastName: 'ASC' }),
        pagination.first,
        pagination.last,
        pagination.before,
        pagination.after,
      );
      return res;
    },
  });

  useEffect(() => {
    if ((RisData as any)?.data?.length === 1) {
      const user = (RisData as any)?.data[0];
      navigate(`/users/${user?.id}?filter=${encodeURIComponent(filter)}&filterBy=${filterBy}`);
    }

    if ((RisData as any)?.pageInfo) {
      setPageInfo((RisData as any)?.pageInfo);
    }
  }, [RisData]);

  const generateRows = (users: any[]) => {
    const rows = users?.map(user => {
      return {
        id: user?.id,
        name: (
          <StyledLink to={`/users/${user?.id}`} state={{ prevPath: pathname, search }}>
            {user?.firstName} {user?.lastName}
          </StyledLink>
        ),
        email: user?.contactEmail,
        deviceIds: displayDeviceIds(user?.id, user?.macAddresses || []),
        createdAt: formatDateTime(user?.createdAt),
        actions: [
          {
            name: 'userDetails',
            text: t('View Account'),
            action: () => {
              navigate(`/users/${user?.id}`, { state: { prevPath: pathname, search } });
            },
          },
        ],
      };
    });

    return rows;
  };

  return (
    <div data-test-users-table>
      <TableV2
        initialSortColumn={sortColumn || 'name'}
        initialSortDirection={sortDirection || 'ASC'}
        actionsButtonName={t('Options')}
        NoDataMessage={
          <div>
            <h3>{t('No Results Found')}</h3>
            <NoDataText>
              {t(
                'Check your entry and try again. If no results are found, try finding the account in the',
              )}{' '}
              <StyledExternalLink href='https://gcsportal.resideo.com/' target='_blank'>
                GCS Portal
              </StyledExternalLink>{' '}
              {t('(Resideo Home) or the')}{' '}
              <StyledExternalLink
                href='https://www.mytotalconnectcomfort.com/Admin/'
                target='_blank'>
                TCC Admin
              </StyledExternalLink>{' '}
              {t('(Total Connect Comfort)')}
            </NoDataText>
          </div>
        }
        headers={[
          {
            displayName: 'id',
            isSortable: false,
            isHiddenColumn: true,
          },
          {
            displayName: t('Name'),
            isSortable: false,
          },
          {
            displayName: t('Email'),
            isSortable: false,
          },
          {
            displayName: t('Device/Mac ID(s)'),
            isSortable: false,
          },
          {
            displayName: t('Created Date'),
            isSortable: false,
          },
          {
            displayName: '',
            isSortable: false,
          },
        ]}
        rows={generateRows((RisData as any)?.data)}
        hasPagination
        hasNext={pageInfo?.hasNextPage}
        hasPrevious={pageInfo?.hasPreviousPage}
        nextButtonLabel={t('Next')}
        previousButtonLabel={t('Previous')}
        onPaginationNextClick={() => {
          setPagination({
            first: MAX_PAGE_LOAD,
            last: undefined,
            before: undefined,
            after: pageInfo?.endCursor,
          });
        }}
        onPaginationPrevClick={() => {
          setPagination({
            first: undefined,
            last: MAX_PAGE_LOAD,
            before: pageInfo?.startCursor,
            after: undefined,
          });
        }}></TableV2>

      <div>
        <ResetPasswordModal title='Reset Password'>
          <ResetUserPasswordModal user={touchedUser} closeModal={closeResetPasswordModal} />
        </ResetPasswordModal>
        <RemoveModal title='Delete User'>
          <DeleteUserModal user={touchedUser} closeModal={closeRemoveModal} />
        </RemoveModal>
      </div>
    </div>
  );
};

export default UsersLookupTable;
