import React, { useEffect, useState } from 'react';
import { Box, Button, Flex, Text, Image, Spinner, InAppPreview } from '@resideo/blueprint-react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import PlusCircle from '../../../SharedComponentsModule/Icons/plus-circle.svg?react';
import './BrandProfileStyles.css';
import { FaPen } from 'react-icons/fa';
import styled from 'styled-components';
import BrandProfileForm from './BrandProfileForm';
import { useCompanyDetailsContext } from 'CompaniesModule/CompanyDetailsComponent';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useRisClient } from 'hooks/useRisClient';

const PenIcon = styled(FaPen)`
  margin-right: 8px;
`;

const PreviewContainer = styled.main`
  padding-bottom: ${({ theme }) => theme.space.medium};
`;

const Status = styled(Text)<{ isActive?: boolean }>`
  color: ${({ isActive, theme }) =>
    isActive ? theme.colors.success : theme.colors.nav.selectedBorder};
`;

const LoaderIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
`;
export interface BrandValues {
  customerServiceNumber?: any;
  websiteUrl?: string;
  businessEmail?: string;
  name?: string;
  status?: any;
  logoUrl?: string;
  brandProfileId: string;
}

const BrandProfile = () => {
  const { id = '' } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const [editMode, setEditMode] = useState(false);
  const [isAPISuccess, setIsAPISuccess] = useState(false);
  const { setShowHeader, partnerAccount } = useCompanyDetailsContext();
  const { client } = useRisClient();

  useEffect(() => {
    //show sticky header if on edit mode, otherwise hide it
    if (editMode) {
      setShowHeader(false);
    } else if (!editMode) {
      setShowHeader(true);
    }
  });

  const { data } = useSuspenseQuery({
    queryKey: ['getPartnerAccount', partnerAccount?.id],
    queryFn: async () => {
      const res = await client.companies.companiesGetCompany(id);
      return res?.data;
    },
  });

  // if it has a brand profile this const is true
  const hasBrandProfile = !!data?.partnerBrandProfile;

  const renderStatus = value => {
    if (value === 'ACTIVE') {
      value = t('Active');
    }
    if (value == 'ARCHIVED') {
      value = t('Deactivated');
    }
    return value;
  };

  const statusValue = renderStatus(data?.partnerBrandProfile?.status);

  return (
    <>
      {!hasBrandProfile && (
        <Box marginX='large'>
          {isAPISuccess && (
            <LoaderIcon>
              <Spinner color='primary' size='xxLarge' />
            </LoaderIcon>
          )}
          {!editMode && (
            <>
              <Flex alignItems='center' flexDirection='column'>
                <Text className='NoProfileText'>
                  {t('This company does not have brand profile set ups.')}
                </Text>
                <Button
                  data-test-create-brand-profile-bttn
                  className='CreateButton'
                  variant='secondary'
                  onClick={() => setEditMode(true)}>
                  <Flex alignItems='center'>
                    <PlusCircle className='PlusIcon'></PlusCircle>
                    {t('Create Brand Profile')}
                  </Flex>
                </Button>
              </Flex>
            </>
          )}
        </Box>
      )}
      {hasBrandProfile && (
        <Box marginX='large'>
          <Flex className='subheader'>
            <h3 color='#303030'>{t('Brand Profile')}</h3>
            <Button
              data-test-edit-button
              paddingRight='0rem'
              variant='tertiary'
              onClick={() => setEditMode(true)}>
              <PenIcon />
              {t('Edit')}
            </Button>
          </Flex>
          {!editMode && <hr />}
          <div>
            <div className='StatusContainer'>
              <Text className='StatusTextLabel'>{t('Status')}</Text>
              <Status data-test-brand-status isActive={statusValue === 'Active'}>
                {statusValue}
              </Status>
            </div>
            <div className='LogoContainer'>
              <Text className='BrandProfileCompanyLogo'>{t('Company Logo')}</Text>
              <div className='ImageContainer'>
                {data?.partnerBrandProfile?.logo && (
                  <Image
                    src={`${data?.partnerBrandProfile?.logo}?tr=h-212,ar-15-4,c-at_max`}
                    maxHeight='212px'
                    maxWidth='363px'
                  />
                )}
              </div>
            </div>
            <div className='CustomerServiceContainer'>
              <Text className='BrandProfileSectionText'>{t('Customer Service Info')}</Text>
              <Text className='CustomerServiceLabelText'>{t('Company Phone')}</Text>
              <Text data-test-brand-phone-number className='CustomerServiceText'>
                {data?.partnerBrandProfile?.customerServiceNumber}
              </Text>

              <Text className='CustomerServiceLabelText'>{t('Website')}</Text>
              <Text data-test-brand-website className='CustomerServiceText'>
                {data?.partnerBrandProfile?.websiteUrl}
              </Text>

              <Text className='CustomerServiceLabelText'>{t('Customer Service Email')}</Text>
              <Text data-test-brand-email className='CustomerServiceText'>
                {data?.partnerBrandProfile?.businessEmail}
              </Text>
            </div>
            {!editMode && (
              <PreviewContainer>
                <h3>{t('In-App Preview')}</h3>
                <InAppPreview
                  logo={data?.partnerBrandProfile?.logo ?? ''}
                  phone={data?.partnerBrandProfile?.customerServiceNumber ?? ''}
                  email={data?.partnerBrandProfile?.businessEmail ?? ''}
                  url={data?.partnerBrandProfile?.websiteUrl ?? ''}
                />
              </PreviewContainer>
            )}
          </div>
        </Box>
      )}
      {editMode && (
        <BrandProfileForm data={data} setEditMode={setEditMode} setIsAPISuccess={setIsAPISuccess} />
      )}
    </>
  );
};

export default BrandProfile;
