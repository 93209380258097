import React, { useState, useEffect, Suspense } from 'react';
import { Form, Formik, FormikErrors } from 'formik';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Text, Button } from '@resideo/blueprint-react';
import { InputField, newUseCountrySelector } from '@resideo/blueprint-formik';
import { AddressType, checkName, validateAddress } from '../../../../utils/validation';
import styled from 'styled-components';
import Autocomplete from '../Autocomplete';
import { AUTOCOMPLETE_ADDRESS_FIELD } from 'config';

export interface Location extends AddressType {
  locationName?: string;
}

interface CustomerLocationFormProps {
  onSubmit: (values, autoCompleteSelected: boolean) => void;
  onCancel?: () => void;
  isStep?: boolean;
  initialValues: any;
  isBackBtnClickedFromVerificationModal?: boolean;
}

const CityStateContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 2rem;
  grid-template-columns: 1fr 1fr;
`;

const CustomerLocationForm = ({
  onSubmit,
  onCancel,
  isStep,
  initialValues,
  isBackBtnClickedFromVerificationModal,
}: CustomerLocationFormProps) => {
  const { t } = useTranslation();
  const [autoCompleteSelected, setAutoCompleteSelected] = useState<boolean>(false);

  const validate = (values: Location) => {
    const errors: FormikErrors<Location> = {};
    if (!values.locationName?.trim()) {
      errors.locationName = t('Location Name is required.');
    }
    if (values?.locationName && !checkName(values?.locationName)) {
      errors['locationName'] = t('Unallowed characters in the Location Name field');
    }

    validateAddress(errors, t, values, '');
    return errors;
  };

  const formikRef = React.useRef<any>(null);

  useEffect(() => {
    if (formikRef?.current?.values) {
      formikRef?.current?.validateForm();
    }
  }, [formikRef?.current?.values]);

  const apiKey = import.meta.env.REACT_APP_COUNTRY_STATE_API_KEY || '';
  const {
    CountrySelectorWrapper: CountryField,
    StateSelectorWrapper: StateField,
    setCountry,
    setStateValue,
  } = newUseCountrySelector({
    apiKey,
  });

  useEffect(() => {
    if (Object.entries(initialValues).length === 0) {
      document
        .getElementById('locationForm')
        ?.getElementsByTagName('input')[0]
        ?.focus();
    }
  });

  return AUTOCOMPLETE_ADDRESS_FIELD ? (
    <Formik
      initialValues={initialValues}
      onSubmit={values => onSubmit(values, autoCompleteSelected)}
      validate={validate}
      innerRef={formikRef}>
      {({
        dirty,
        isValid,
        isSubmitting,
        setFieldValue,
        values,
        errors,
        touched,
        setFieldTouched,
      }) => (
        <Form data-test-customer-info noValidate id='locationForm'>
          {!isStep && (
            <Text fontWeight='bold' fontSize='large' marginBottom='medium'>
              {t('Location Info')}
            </Text>
          )}
          <Text
            marginBottom='medium'
            fontSize='small'
            color='gray'
            data-test-new-customer-modal-required-fields>
            {t('* Required Fields')}
          </Text>
          <Box data-test-location-name>
            <InputField
              data-test-location-name-input
              version='v2'
              name='locationName'
              label={t('Location Name')}
              marginBottom='medium'
              required
            />
          </Box>
          <Suspense>
            <Autocomplete
              initialValue={initialValues?.address1}
              setCountry={setCountry}
              setStateValue={setStateValue}
              fieldName={'address1'}
              label={t('Address')}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              touched={touched}
              errors={errors}
              setAutoCompleteSelected={setAutoCompleteSelected}
            />
          </Suspense>
          <Box data-test-address-address2 marginBottom={['medium']}>
            <InputField version='v2' name={'address2' || ''} label={t('Apt, Suite, etc')} />
          </Box>
          <Box data-test-address-zip marginBottom={['medium']}>
            <InputField
              data-test-zip-input
              version='v2'
              name={'zip'}
              label={t('Zip / Postal Code')}
              required
            />
          </Box>
          <CityStateContainer>
            <Box data-test-address-city marginBottom={['medium']}>
              <InputField
                data-test-city-input
                version='v2'
                name={'city'}
                label={t('City')}
                required
              />
            </Box>
            <Box data-test-address-state marginBottom={['medium']}>
              <StateField
                version='v2'
                onStateChange={val => {
                  setFieldValue('state', val);
                }}
                initialValue={values.state}
                blankOption={t('Select')}
                required
                label={t('State/Province/Region')}
                name='State'
                onBlur={() => {
                  setFieldTouched('state');
                }}
                error={touched.state && errors.state}
              />
            </Box>
          </CityStateContainer>
          <Box data-test-address-country marginBottom={['medium']}>
            <CountryField
              version='v2'
              onCountryChange={val => {
                setFieldValue('country', val);
              }}
              initialValue={values.country}
              blankOption={t('Select')}
              required
              label={t('Country')}
              name='Country'
              onBlur={() => {
                setFieldTouched('country');
              }}
              error={touched.country && errors.country}
            />
          </Box>
          <Flex
            alignItems='center'
            justifyContent='flex-end'
            paddingX={['medium', 0]}
            paddingTop='large'>
            {onCancel && (
              <Button
                data-test-modal-back
                marginX='large'
                onClick={() => {
                  onCancel();
                }}
                type='button'
                variant='secondary'>
                {t(isStep ? 'Back' : 'Cancel')}
              </Button>
            )}
            <Button
              data-test-profile-save-btn
              disabled={
                isBackBtnClickedFromVerificationModal
                  ? !isBackBtnClickedFromVerificationModal
                  : !dirty || isSubmitting || !isValid
              }
              type='submit'
              variant='primary'>
              {t(isStep ? 'Submit' : 'Save')}
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
  ) : (
    <Formik
      initialValues={initialValues}
      onSubmit={values => onSubmit(values, false)}
      validate={validate}>
      {({
        dirty,
        isValid,
        isSubmitting,
        setFieldValue,
        values,
        errors,
        touched,
        setFieldTouched,
      }) => (
        <Form data-test-customer-info noValidate id='locationForm'>
          {!isStep && (
            <Text fontWeight='bold' fontSize='large' marginBottom='medium'>
              {t('Location Info')}
            </Text>
          )}
          <Text
            marginBottom='medium'
            fontSize='small'
            color='gray'
            data-test-new-customer-modal-required-fields>
            {t('* Required Fields')}
          </Text>
          <Box data-test-location-name>
            <InputField
              data-test-location-name-input
              version='v2'
              name='locationName'
              label={t('Location Name')}
              marginBottom='medium'
              required
            />
          </Box>
          <Box data-test-address-country marginBottom={['medium']}>
            <CountryField
              version='v2'
              onCountryChange={val => {
                setFieldValue('country', val);
              }}
              initialValue={values.country}
              blankOption={t('Select')}
              required
              label={t('Country')}
              name='Country'
              onBlur={() => {
                setFieldTouched('country');
              }}
              error={touched.country && errors.country}
            />
          </Box>
          <Flex marginBottom='medium' flexDirection={['column', 'row']}>
            <Box data-test-address-address1 width={[1, 2 / 3]} marginBottom={['medium', 0]}>
              <InputField
                data-test-address1-input
                version='v2'
                name={'address1'}
                label={t('Street Address')}
                required
              />
            </Box>
            <Box data-test-address-address2 width={[1, 1 / 3]} marginLeft={[0, 'medium']}>
              <InputField
                version='v2'
                name={'address2' || ''}
                label={t('Apt, Suite, etc. (optional)')}
              />
            </Box>
          </Flex>
          <CityStateContainer>
            <Box data-test-address-city marginBottom={['medium']}>
              <InputField
                data-test-city-input
                version='v2'
                name={'city'}
                label={t('City')}
                required
              />
            </Box>
            <Box data-test-address-state marginBottom={['medium']}>
              <StateField
                version='v2'
                onStateChange={val => {
                  setFieldValue('state', val);
                }}
                initialValue={values.state}
                blankOption={t('Select')}
                required
                label={t('State/Province/Region')}
                name='State'
                onBlur={() => {
                  setFieldTouched('state');
                }}
                error={touched.state && errors.state}
              />
            </Box>
          </CityStateContainer>
          <Box data-test-address-zip marginBottom={['medium']}>
            <InputField
              data-test-zip-input
              version='v2'
              name={'zip'}
              label={t('Zip/Postal Code')}
              required
            />
          </Box>
          <Flex
            alignItems='center'
            justifyContent='flex-end'
            paddingX={['medium', 0]}
            paddingTop='large'>
            {onCancel && (
              <Button
                marginX='large'
                onClick={() => {
                  onCancel();
                }}
                type='button'
                variant='secondary'>
                {t(isStep ? 'Back' : 'Cancel')}
              </Button>
            )}
            <Button
              data-test-profile-save-btn
              disabled={
                isBackBtnClickedFromVerificationModal
                  ? !isBackBtnClickedFromVerificationModal
                  : !dirty || isSubmitting || !isValid
              }
              type='submit'
              variant='primary'>
              {t(isStep ? 'Submit' : 'Save')}
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};

export default CustomerLocationForm;
