import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Flex, Text, Box } from '@resideo/blueprint-react';
import styled from 'styled-components';

const ModalContainer = styled.div`
  display: block;
`;

const ButtonContainer = styled(Flex)`
  flex-direction: row;
  gap: 16px;
  justify-content: flex-end;
  padding-top: 35px;
`;

const LocationContainer = styled.div`
  margin-bottom: 32px;
  font-size: 14px;
`;

export const RemoveDeviceModal = ({ device, deviceType, addressDetails, onSubmit, onCancel }) => {
  const { t } = useTranslation();
  return (
    <ModalContainer data-test-remove-user-modal>
      <Text marginBottom='medium' as='h2' fontSize='xLarge' data-test-remove-device-modal-step-1>
        {t('Step 1 of 2: Remove Device From Location')}
      </Text>
      <Box marginBottom='large' data-test-device-details>
        <Text fontSize='medium'>{device?.name || ''}</Text>
        <Text fontSize='medium'>{deviceType}</Text>
        <Text fontSize='medium'>{device?.device?.deviceId || ''}</Text>
      </Box>
      <Text marginBottom='large' fontSize='medium' data-test-device-removal-text>
        {t('will be removed permanently from this location:')}
      </Text>
      <LocationContainer data-test-device-location-details>
        <Text fontSize='medium'>{addressDetails?.name}</Text>
        <Text fontSize='medium'>
          {addressDetails?.address?.addressLine1} {addressDetails?.address?.addressLine2}
        </Text>
        <Text fontSize='medium'>
          {addressDetails?.address?.city} {addressDetails?.address?.stateProvinceRegionCode}{' '}
          {addressDetails?.address?.zipPostalCode}
        </Text>
        <Text fontSize='medium'>{addressDetails?.address?.countryCode}</Text>
      </LocationContainer>
      <Text marginBottom='medium' fontSize='medium' data-test-device-removal-access-text>
        {t('All users who currently have access to this device will no longer have access.')}
      </Text>
      <ButtonContainer>
        <Button data-test-remove-device-cancel-button variant='secondary' onClick={onCancel}>
          {t('Cancel')}
        </Button>
        <Button data-test-remove-device-button variant='destructivePrimary' onClick={onSubmit}>
          {t('Remove')}
        </Button>
      </ButtonContainer>
    </ModalContainer>
  );
};
