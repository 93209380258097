import React, { useEffect, useState } from 'react';
import { FieldArray, Form, Formik, FormikErrors } from 'formik';
import { InputField, SelectField } from '@resideo/blueprint-formik';
import { Button, useToastContext } from '@resideo/blueprint-react';
import styled from 'styled-components';
import { FiLoader, FiPlusCircle, FiTrash2 } from 'react-icons/fi';
import { useParams } from 'react-router-dom';
import { PRO_PORTAL_URL, RESIDEO_ID_SIGNUP, RESIDEO_ID_URL } from 'config';
import { useTranslation } from 'react-i18next';
import { checkEmail } from '../../../../utils/validation';
import './InviteEmployeeModalStyles.css';
import { useMutation } from '@tanstack/react-query';
import { useRisClient } from 'hooks/useRisClient';
import { PartnerRole } from '@resideo/web-integration-services-api-client';

const AddIcon = styled(FiPlusCircle)`
  margin-right: 12px;
  font-size: 24px;
`;

const RemoveIcon = styled(FiTrash2)`
  cursor: pointer;
  font-size: 24px;
`;

type InvitationType = {
  contactEmail?: string;
  role?: string;
  perksRole?: string;
};

type FormValuesType = {
  invites: InvitationType[];
};

enum StatusEnum {
  UNSENT = 'UNSENT',
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
  LOADING = 'LOADING',
}

const InviteEmployeeModal = ({ closeModal }) => {
  const [invitesLength, setInvitesLength] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [recordStatuses, setRecordStatuses] = useState<StatusEnum[]>([StatusEnum.UNSENT]);
  const { addToast } = useToastContext();
  const { id: partnerAccountId }: { id?: string } = useParams();
  useEffect(() => {
    document.getElementById(`invites.${invitesLength - 1}.contactEmail`)?.focus();
  }, [invitesLength]);
  const { t } = useTranslation();
  const { client } = useRisClient();

  const partnerUserCreateInvitation = useMutation({
    mutationKey: ['partnerUserCreateInvitation'],
    mutationFn: async (variables: { role: string; sendToEmail: string }) => {
      return await client.companies.companiesCreateEmployeeInvite(partnerAccountId as string, {
        roles: [variables?.role as PartnerRole],
        sendToEmail: variables?.sendToEmail,
      });
    },
  });

  const partnerUserSendInvitation = useMutation({
    mutationKey: ['partnerUserSendInvitation'],
    mutationFn: async (variables: {
      templateLanguage: string;
      baseUrl: string;
      expiresAt: string;
      inviteId: string;
    }) => {
      return await client.companies.companiesSendEmployeeInvite(
        variables?.inviteId || '',
        partnerAccountId as string,
        {
          templateLanguage: variables?.templateLanguage || '',
          baseUrl: variables?.baseUrl || '',
          expiresAt: variables?.expiresAt || '',
        },
      );
    },
  });

  const roleOptions = [
    {
      value: '',
      text: `${t('Select Role')}...`,
    },
    {
      value: 'COMPANY_ADMIN',
      text: t('Company Admin'),
    },
    {
      value: 'TECHNICIAN',
      text: t('Technician'),
    },
    {
      value: 'OWNER',
      text: t('Owner'),
    },
  ];

  const validate = values => {
    const { invites } = values;
    const errors: FormikErrors<InvitationType>[] = [];
    invites.forEach((item, idx) => {
      const inviteErrors: FormikErrors<InvitationType> = {};
      if (!item.contactEmail) {
        inviteErrors.contactEmail = t('Email is required');
      } else if (!checkEmail(item.contactEmail)) {
        inviteErrors.contactEmail = t('Invalid email');
      }
      if (!item.role) {
        inviteErrors.role = t('Role is required');
      }

      if (Object.keys(inviteErrors).length) errors[idx] = inviteErrors;
    });

    if (errors.length > 0) return { invites: errors };

    return {};
  };

  const handleAlerts = (currentState: StatusEnum[]) => {
    if (currentState.some(i => i === StatusEnum.UNSENT || i === StatusEnum.LOADING)) {
      return;
    }

    if (currentState.every(i => i === StatusEnum.ERROR)) {
      addToast({ toastType: 'Error', message: t('Failed to send invites. Please try again.') });
      return;
    }

    if (currentState.some(i => i === StatusEnum.ERROR)) {
      addToast({
        toastType: 'Error',
        message: t('Some invites failed to send. Please try again.'),
      });
      return;
    }

    if (currentState.every(i => i === StatusEnum.SUCCESS)) {
      addToast({ toastType: 'Success', message: t('Invites sent.') });
      closeModal();
    }
  };

  const inviteCreateHandle = (values: InvitationType) =>
    new Promise((resolve, reject) => {
      partnerUserCreateInvitation.mutate(
        {
          sendToEmail: values?.contactEmail as string,
          role: values?.role as string,
        },
        {
          onError: error => {
            reject(`InviteCreateMutation: ${error}`);
          },
          onSuccess: response => {
            resolve(response?.data?.id);
          },
        },
      );
    });

  const inviteSendHandle = inviteId =>
    new Promise((resolve, reject) => {
      const expiresAt = new Date();
      expiresAt.setDate(expiresAt.getDate() + 7);

      partnerUserSendInvitation.mutate(
        {
          templateLanguage: 'en',
          baseUrl: RESIDEO_ID_SIGNUP ? `${RESIDEO_ID_URL}/sign-up` : `${PRO_PORTAL_URL}/sign-up`,
          expiresAt: expiresAt.toISOString(),
          inviteId,
        },
        {
          onError: error => {
            reject(`InviteSendMutation: ${error}`);
          },
          onSuccess: response => {
            if (response?.errors?.length) {
              reject('Error: InviteSendMutation error');
            }

            resolve(response?.data);
          },
        },
      );
    });

  const handleMutation = async (values: InvitationType) => {
    try {
      const inviteId = await inviteCreateHandle(values);
      const inviteResult = await inviteSendHandle(inviteId);

      if (inviteResult) {
        return new Promise<boolean>(resolve => resolve(true));
      }
    } catch (err) {
      // handling error
    }
  };

  const submitRecord = async (data: InvitationType) => {
    const inviteTrimmed = {
      ...data,
      contactEmail: data?.contactEmail?.trim() || '',
    };
    const result = await handleMutation(inviteTrimmed);
    return result ? StatusEnum.SUCCESS : StatusEnum.ERROR;
  };

  const onSubmit = async (values: FormValuesType) => {
    setLoading(true);

    const { invites } = values;

    let idx = 0;
    const recordStatusesArray = [...recordStatuses];
    for (const invite of invites) {
      if (recordStatuses[idx] === StatusEnum.UNSENT || recordStatuses[idx] === StatusEnum.ERROR) {
        recordStatusesArray[idx] = StatusEnum.LOADING;
        recordStatusesArray[idx] = await submitRecord(invite);
      }
      idx++;
    }
    setLoading(false);
    return handleAlerts(recordStatusesArray);
  };

  return (
    <Formik
      initialValues={{ invites: [{ contactEmail: '', role: '' }] }}
      validate={validate}
      onSubmit={onSubmit}>
      {({ values, isValid, dirty, setFieldTouched }) => (
        <Form>
          <p>* {t('Required Fields')}</p>

          <FieldArray name='invites'>
            {arrayHelpers => (
              <div>
                <div style={{ maxHeight: '485px', overflow: 'auto' }}>
                  {values.invites &&
                    values.invites.map((invite, index) => (
                      <div key={index} style={{ display: 'flex', gap: '15px', height: '97px' }}>
                        <div style={{ flexGrow: 5 }}>
                          <InputField
                            id={`invites.${index}.contactEmail`}
                            version='v2'
                            label='Contact Email'
                            name={`invites.${index}.contactEmail`}
                            required
                          />
                        </div>

                        <div style={{ flexGrow: 3 }}>
                          <SelectField
                            id={`invites.${index}.role`}
                            version='v2'
                            label='Role'
                            name={`invites.${index}.role`}
                            required>
                            {roleOptions.map(i => (
                              <option key={i.value} value={i.value}>
                                {i.text}
                              </option>
                            ))}
                          </SelectField>
                        </div>

                        <div style={{ flexGrow: 0, display: 'flex', alignItems: 'center' }}>
                          <Button
                            className='removeButton'
                            type='button'
                            variant='tertiary'
                            onClick={() => {
                              arrayHelpers.remove(index);
                              const newRecordStatuses = [...recordStatuses];
                              newRecordStatuses.splice(index, 1);
                              setRecordStatuses(newRecordStatuses);
                            }}
                            disabled={values.invites.length <= 1}>
                            <RemoveIcon style={{ paddingTop: '8px' }} />
                          </Button>
                        </div>
                      </div>
                    ))}
                </div>

                <Button
                  type='button'
                  variant='tertiary'
                  style={{ display: 'flex', alignItems: 'center', paddingLeft: '0' }}
                  onClick={() => {
                    setFieldTouched(`invites.${values.invites.length - 1}.contactEmail`, true);
                    setFieldTouched(`invites.${values.invites.length - 1}.role`, true);
                    arrayHelpers.push({ contactEmail: '', role: '' });
                    setRecordStatuses([...recordStatuses, StatusEnum.UNSENT]);
                    setInvitesLength(values.invites.length + 1);
                  }}>
                  <AddIcon /> Add Another
                </Button>
              </div>
            )}
          </FieldArray>

          <div style={{ display: 'flex', justifyContent: 'right' }}>
            <Button
              variant='secondary'
              type='button'
              style={{ marginRight: '25px' }}
              onClick={closeModal}>
              Cancel
            </Button>

            <Button
              data-test-send-invite
              variant='primary'
              type='submit'
              disabled={!dirty || !isValid || loading}>
              {loading && (
                <FiLoader className='icon-spin' style={{ marginLeft: 4, marginTop: 2 }} />
              )}
              {!loading && 'Send'}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default InviteEmployeeModal;
