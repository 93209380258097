const parseBoolean = (str = '') => str.toLowerCase() === 'true';

// Environment
export const ENABLE_MOCKS = parseBoolean(import.meta.env.REACT_APP_ENABLE_MOCKS);

export const IS_TEST = parseBoolean(import.meta.env.REACT_APP_IS_TEST);

const config = {
  //Auth0
  audience: import.meta.env.REACT_APP_AUTH0_AUDIENCE || '',
  clientId: import.meta.env.REACT_APP_AUTH0_CLIENT_ID || '',
  auth0Connection: import.meta.env.REACT_APP_AUTH0_CONNECTION || 'Resideo-Azure-AD',
  domain: import.meta.env.REACT_APP_AUTH0_DOMAIN || '',
  namespace: `${import.meta.env.REACT_APP_AUTH0_DOMAIN}/`,
  scope: 'openid profile email',
  changePasswordEndpoint: import.meta.env.REACT_APP_AUTH0_CHANGE_PASSWORD_ENDPOINT || '',
};
export { config };

// Sentry
export const SENTRY_DSN = import.meta.env.REACT_APP_SENTRY_DSN || '';
export const ENVIRONMENT = import.meta.env.REACT_APP_ENVIRONMENT || 'development';
export const SENTRY_SAMPLE_RATE = parseFloat(import.meta.env.REACT_APP_SENTRY_SAMPLE_RATE || '0.2');

// PRO PORTAL URL

export const IS_PRODUCTION = ENVIRONMENT === 'production';

export const PRO_PORTAL_URL = import.meta.env.REACT_APP_PRO_PORTAL_URL;
export const RESIDEO_ID_URL = import.meta.env.REACT_APP_RESIDEO_ID_URL;

// ImageKit IMK
export const imkPublicKey = import.meta.env.REACT_APP_IMK_PUBLIC_KEY || '';
export const imkUrlEndpoint = import.meta.env.REACT_APP_IMK_URL_ENDPOINT || '';
export const imkLogoFolder = import.meta.env.REACT_APP_IMK_LOGO_FOLDER || '';
export const imkUploadUrl =
  import.meta.env.REACT_APP_IMK_UPLOAD_URL || 'https://upload.imagekit.io/api/v1/files/upload';

// Feature Flag
export const RESIDEO_ID_SIGNUP = parseBoolean(import.meta.env.REACT_APP_FEATURE_RESIDEO_ID_SIGNUP);

export const HOME_HERO_ONE_UPDATES = parseBoolean(
  import.meta.env.REACT_APP_FEATURE_HOME_HERO_ONE_UPDATES,
);

export const USER_DEVICE_SERVICES_LINK = import.meta.env
  .REACT_APP_USER_DEVICE_SERVICES_LINK as string;

export const USER_DEVICE_SERVICES_REGISTRATION_LINK = import.meta.env
  .REACT_APP_USER_DEVICE_SERVICES_REGISTRATION_LINK as string;

export const AUTOCOMPLETE_ADDRESS_FIELD = parseBoolean(
  import.meta.env.REACT_APP_FEATURE_20240524_AUTOCOMPLETE_ADDRESS_FIELD,
);

export const TABLE_RESULTS_PER_PAGE = parseInt(
  import.meta.env.REACT_APP_FEATURE_TABLE_RESULTS_PER_PAGE ?? '10',
);

export const SENTRY_ENABLE_LOCALHOST = parseBoolean(
  import.meta.env.REACT_APP_SENTRY_ENABLE_LOCALHOST,
);

export const ENABLE_VX_DEVICES = parseBoolean(
  import.meta.env.REACT_APP_FEATURE_20240926_ENABLE_VX_DEVICES,
);

export const ENABLE_ADD_DEVICE_AND_RESEND_INVITE = parseBoolean(
  import.meta.env.REACT_APP_FEATURE_20241030_ENABLE_ADD_DEVICE_RESEND_INVITE,
);

export const ENABLE_NEW_COMPANY_ACCOUNT_SEARCH = parseBoolean(
  import.meta.env.REACT_APP_FEATURE_20241112_ENABLE_COMPANY_CUSTOMER_ACCOUNT_SEARCH,
);

export const GLOBAL_CUSTOMER_SEARCH = parseBoolean(
  import.meta.env.REACT_APP_FEATURE_20241125_GLOBAL_CUSTOMER_SEARCH,
);

export const ADD_DEVICE_BASE_URL = import.meta.env.REACT_APP_ADD_DEVICE_BASE_URL as string;

export const RIS_BASE_URL = import.meta.env.REACT_APP_RIS_BASE_URL || '';

export default config;
