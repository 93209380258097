import React from 'react';
import { Form, Formik, FormikErrors } from 'formik';
import { useTranslation } from 'react-i18next';
import { InputField, PhoneInputField, newUseCountrySelector } from '@resideo/blueprint-formik';
import { Button, useToastContext } from '@resideo/blueprint-react';
import { checkEmail } from '../../utils/validation';
import { useRisClient } from 'hooks/useRisClient';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { UpdateUserModel } from '@resideo/web-integration-services-api-client';
import styled from 'styled-components';

const Header = styled.h2`
  margin-top: 0;
`;

const RequiredFields = styled.p`
  color: '#767676';
`;

const ButtonContainer = styled.div`
  margin-top: 25px;
  text-align: right;
`;

const CancelButton = styled(Button)`
  margin-right: 25px;
`;

const StyledForm = styled(Form)`
  display: grid;
  grid-gap: 25px;
`;

export const EditUserModal = ({ user, closeModal }) => {
  const { t } = useTranslation();
  const { addToast } = useToastContext();
  const apiKey = import.meta.env.REACT_APP_COUNTRY_STATE_API_KEY || '';
  const { CountrySelectorWrapper: CountryField } = newUseCountrySelector({
    apiKey,
  });
  const { client } = useRisClient();
  const queryClient = useQueryClient();

  const editUser = useMutation({
    mutationKey: ['editUser'],
    mutationFn: async (variables: { userId: string; requestBody: UpdateUserModel }) => {
      await client.users.usersUpdateUser(variables?.userId, variables?.requestBody);
    },
    onSuccess: () => {
      // TODO: Invalidate some queries
      queryClient.invalidateQueries();
      addToast({ toastType: 'success', message: t('User Updated') });
      closeModal();
    },
    onError: () => {
      addToast({ toastType: 'error', message: t('Something went wrong. Please try again.') });
    },
  });

  const initialValues = {
    firstName: user?.firstName,
    lastName: user?.lastName,
    contactEmail: user?.contactEmail,
    countryCode: user?.countryCode,
    primaryPhoneNumber: user?.primaryPhoneNumber,
  };

  const handleSave = values => {
    editUser.mutate({ userId: user?.id || '', requestBody: values });
  };

  const validate = values => {
    const errors: FormikErrors<any> = {};

    if (!values.firstName || values.firstName.trim() === '') {
      errors.firstName = t('First Name Required');
    }

    if (!values.lastName || values.lastName.trim() === '') {
      errors.lastName = t('Last Name Required');
    }

    if (!values.contactEmail) {
      errors.contactEmail = t('Email Required');
    } else if (!checkEmail(values.contactEmail)) {
      errors.contactEmail = t('Invalid Email Address');
    }

    if (!values.countryCode) {
      errors.CountryCode = t('Country Required');
    }

    return errors;
  };

  const getPhoneInputConfig = (country: string) => ({
    preferredCountries: ['us', 'ca'],
    country,
    countryCodeEditable: false,
    disableDropdown: true,
  });

  return (
    <div data-test-edit-modal>
      <Header>{t('User Info')}</Header>
      <RequiredFields>{t('* Required Fields')}</RequiredFields>
      <Formik initialValues={initialValues} onSubmit={handleSave} validate={validate}>
        {({ setFieldValue, setFieldTouched, values }) => (
          <StyledForm>
            <InputField required version='v2' label={t('First Name')} name='firstName' />
            <InputField required version='v2' label={t('Last Name')} name='lastName' />
            <InputField required version='v2' label={t('Email')} name='contactEmail' />
            <CountryField
              required
              version='v2'
              label={t('Country')}
              name='CountryCode'
              initialValue={values.countryCode}
              blankOption={t('SELECT')}
              onCountryChange={val => {
                setFieldValue('countryCode', val);
              }}
              onBlur={() => {
                setFieldTouched('countryCode');
              }}
            />
            <PhoneInputField
              version='v2'
              label={t('Phone')}
              name='primaryPhoneNumber'
              phoneInputConfig={getPhoneInputConfig(values?.countryCode?.toString().toLowerCase())}
            />
            <ButtonContainer>
              <CancelButton type='button' variant='secondary' onClick={closeModal}>
                {t('Cancel')}
              </CancelButton>
              <Button type='submit' variant='primary'>
                {t('Save')}
              </Button>
            </ButtonContainer>
          </StyledForm>
        )}
      </Formik>
    </div>
  );
};
