import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Card, Text } from '@resideo/blueprint-react';
import { useTranslation } from 'react-i18next';

export type ErrorProps = {
  header?: string;
  content?: string;
  resetError?: () => void;
};

const PageError = (props: ErrorProps) => {
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      if (props.resetError) {
        props.resetError();
      }
    };
  }, [location]);

  return (
    <Box marginLeft='280px' maxWidth='48rem' paddingX={[0, 'medium']} paddingY='4rem'>
      <Card borderRadius={[0, 'medium']} padding='large'>
        <h1>{props.header || t('This page is not working.')}</h1>
        <Text>{props.content || t('Please try again at a later time')}</Text>
        {/* TODO: Add button to hard refresh app */}
      </Card>
    </Box>
  );
};

export default PageError;
