import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CopyButton, Flex, TableV2, useModal } from '@resideo/blueprint-react';
import { formatDateTime } from '../../../../../utils/common';
import { useTranslation } from 'react-i18next';
import RemoveEmployeeModal from './RemoveEmployeeModal';
import UpdateEmployeeModal from './UpdateEmployeeModal';
import styled from 'styled-components';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useRisClient } from '../../../../../hooks/useRisClient';

const Email = styled(Flex)`
  align-items: center;
  gap: 8px;
`;

const generateNameLink = (firstname, lastname, id) => {
  return (
    <a href={`/users/${id}`} style={{ color: '#1C6FB9', textDecoration: 'none' }}>
      {firstname} {lastname}
    </a>
  );
};

const RESULTS_PER_PAGE = 15;

const EmployeesTable: FC<{ companyName: string; companyId: any }> = ({
  companyName,
  companyId,
}) => {
  const { t } = useTranslation();
  const [touchedEmployee, setTouchedEmployee] = useState<any>({});
  const [orderBy, setOrderBy] = useState<any>(null);
  const { client } = useRisClient();
  const { id } = useParams<{ id: string }>();
  const rowsPerPage = RESULTS_PER_PAGE;
  const [pageInfo, setPageInfo] = useState<any>();
  const [pagination, setPagination] = useState<any>({
    first: rowsPerPage,
    last: undefined,
    before: undefined,
    after: undefined,
  });

  const goToPageZero = () => {
    setPagination({
      first: rowsPerPage,
      last: undefined,
      before: undefined,
      after: undefined,
    });
  };

  const { data: employeeData, error } = useSuspenseQuery({
    queryKey: ['allPartnerUsers', { orderBy, pagination, companyId }],
    queryFn: async () =>
      await client.companies.companiesSearchPartnerUser(
        id || '',
        '[{"status": "ACTIVE"}, {"status": "ARCHIVED"}]',
        orderBy,
        pagination.first,
        pagination.last,
        pagination.before,
        pagination.after,
      ),
  });

  const VALUESTOTEXT = {
    TECHNICIAN: t('Technician'),
    COMPANY_ADMIN: t('Company Admin'),
    ARCHIVED: t('Archived'),
    ACTIVE: t('Active'),
    OWNER: t('Owner'),
  };

  const {
    Modal: RemoveModal,
    openModal: openRemoveModal,
    closeModal: closeRemoveModal,
  } = useModal({ newDesign: true, onModalClose: () => setTouchedEmployee({}) });

  const {
    Modal: UpdateModal,
    openModal: openUpdateModal,
    closeModal: closeUpdateModal,
  } = useModal({ newDesign: true, onModalClose: () => setTouchedEmployee({}) });

  useEffect(() => {
    if ((employeeData as any)?.pageInfo) {
      setPageInfo((employeeData as any)?.pageInfo);
    }
  }, [employeeData]);

  const renderEmail = (email?: string) => {
    if (!email) return;
    return (
      <Email>
        <span>{email}</span>
        <CopyButton copyText={email} successText='Copied' tooltipText='Copy email' />
      </Email>
    );
  };

  const generateRows = inputData => {
    return inputData.map(partnerUser => {
      const userInfo = partnerUser?.user;
      return {
        id: userInfo?.id,
        name: generateNameLink(userInfo?.firstName, userInfo?.lastName, userInfo?.id),
        email: renderEmail(userInfo?.contactEmail),
        created: formatDateTime(partnerUser?.createdAt),
        role: VALUESTOTEXT[partnerUser?.roles[0]],
        status: VALUESTOTEXT[partnerUser?.status],
        actions: [
          {
            name: 'gotoUser',
            text: t('View User Account'),
            action: () => {
              window.location.href = `/users/${userInfo?.id}`;
            },
          },
          {
            name: 'email',
            text: t('Email Employee'),
            action: () => {
              window.location.href = `mailto:${userInfo.contactEmail}`;
            },
          },
          {
            name: 'update',
            text: t('Update Role/Status'),
            action: () => {
              setTouchedEmployee(partnerUser);
              openUpdateModal();
            },
          },
          {
            name: 'remove',
            text: t('Remove Employee'),
            action: () => {
              setTouchedEmployee(partnerUser);
              openRemoveModal();
            },
          },
        ],
      };
    });
  };

  const getOrderBy = (sortColumn, sortDirection) => {
    if (!sortColumn) {
      return;
    }

    if (sortColumn === 'name') {
      return JSON.stringify({ 'user.lastName': sortDirection, 'user.firstName': sortDirection });
    } else if (sortColumn === 'status') {
      return JSON.stringify({ [sortColumn]: sortDirection });
    }

    return JSON.stringify({ [`user.${sortColumn}`]: sortDirection });
  };

  return (
    <div data-test-company-employees-list>
      <TableV2
        actionsButtonName={t('Options')}
        headers={[
          {
            displayName: t('ID'),
            isSortable: false,
            isHiddenColumn: true,
          },
          {
            displayName: t('Name'),
            isSortable: true,
            onSortColumnName: 'name',
          },
          {
            displayName: t('Email'),
            isSortable: true,
            onSortColumnName: 'contactEmail',
          },
          {
            displayName: t('Created'),
            isSortable: false,
          },
          {
            displayName: t('Role'),
            isSortable: false,
            onSortColumnName: 'role',
          },
          {
            displayName: t('Status'),
            isSortable: true,
            onSortColumnName: 'status',
          },
          {
            displayName: '',
            isSortable: false,
          },
        ]}
        isError={
          error
            ? {
                errorHeader: t('Data Unavailable'),
                errorMessage: t('Table data unable to load. Try again later.'),
              }
            : undefined
        }
        rows={generateRows(employeeData.data)}
        NoDataMessage={t('No Current Employees')}
        hasPagination
        hasNext={pageInfo?.hasNextPage}
        hasPrevious={pageInfo?.hasPreviousPage}
        nextButtonLabel={t('Next')}
        previousButtonLabel={t('Previous')}
        onPaginationNextClick={() => {
          setPagination({
            first: rowsPerPage,
            last: undefined,
            before: undefined,
            after: pageInfo?.endCursor,
          });
        }}
        onPaginationPrevClick={() => {
          setPagination({
            first: undefined,
            last: rowsPerPage,
            before: pageInfo?.startCursor,
            after: undefined,
          });
        }}
        onSort={(sortColumn, sortDirection) => {
          if (sortColumn && sortDirection) {
            setOrderBy(getOrderBy(sortColumn, sortDirection));
            goToPageZero();
          }
        }}
      />

      <div>
        <RemoveModal title={t('Remove Employee')}>
          <RemoveEmployeeModal
            employee={touchedEmployee}
            companyName={companyName}
            companyId={companyId}
            closeModal={closeRemoveModal}
          />
        </RemoveModal>
        <UpdateModal title={t('Update Employee Role/Status')}>
          <UpdateEmployeeModal
            employee={touchedEmployee}
            closeModal={closeUpdateModal}
            companyId={companyId}
          />
        </UpdateModal>
      </div>
    </div>
  );
};

export default EmployeesTable;
