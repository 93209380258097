import React from 'react';
import { Button, useToastContext } from '@resideo/blueprint-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRisClient } from 'hooks/useRisClient';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import styled from 'styled-components';

const Content = styled.div`
  display: flex;
  justify-content: right;
`;

const StyledButton = styled(Button)`
  margin-right: 15px;
`;

const DeleteUserModal = ({ user, closeModal }) => {
  const { addToast } = useToastContext();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { client } = useRisClient();
  const queryClient = useQueryClient();

  const deleteUser = useMutation({
    mutationKey: ['deleteUser'],
    mutationFn: async (userId: string) => {
      await client.users.usersDeleteUserById(userId);
    },
    onSuccess: () => {
      closeModal();
      queryClient.invalidateQueries({ queryKey: ['getUsers'] });
      addToast({ toastType: 'success', message: t('User deleted') });
      navigate('/users');
    },
    onError: () => {
      addToast({ toastType: 'error', message: t('Something went wrong. Try again.') });
    },
  });

  return (
    <div data-test-remove-user-modal>
      <p>{t('Are you sure you want to delete the user?')}</p>

      <p style={{ fontWeight: 'bold' }}>
        {user?.firstName} {user?.lastName}
        <br />
        {user?.contactEmail}
      </p>

      <p>
        {t(
          'They will no longer have access to Resideo applications and will be disassociated from any\n' +
            '        locations, companies and services they are currently associated with.',
        )}
      </p>

      <Content>
        <StyledButton variant='secondary' onClick={closeModal}>
          {t('No')}
        </StyledButton>

        <Button
          variant='destructivePrimary'
          onClick={() => {
            deleteUser.mutate(user?.id);
          }}>
          {t('Yes, Delete')}
        </Button>
      </Content>
    </div>
  );
};

export default DeleteUserModal;
