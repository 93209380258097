export enum CustomerAccountModals {
  RemoveCustomer,
  RemoveLocation,
  EditLocation,
  AddLocation,
  RemoveService,
  ReactivateService,
  InviteCustomer,
  AddDevice,
  EditCustomerForm,
  RemoveDevice,
  ResendInvite,
}

export interface AddressType {
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  locationName: string;
}

export interface CustomerAccountSharedProps {
  openModal: (modalProps: any) => void;
  data?: any;
  closeModal: () => void;
  customerId?: string;
}
export interface IModalProps {
  closeModal: () => void;
  data?: { [x: string]: any };
}

export interface ICustomerInfoProps extends IModalProps {
  initialValues?: any;
  onSubmit?: any;
  isEditMode?: boolean;
  customerData?: { [x: string]: any };
}
export interface IAddCustomerProps extends IModalProps {
  addValues: (values: AddCustomerDataShape) => any;
  setStep: (step: Steps) => void;
  customerData?: { [x: string]: any };
  partnerAccountId: string;
}

export interface IModalProps {
  closeModal: () => void;
  data?: { [x: string]: any };
}

export enum Steps {
  UserInfo,
  Location,
  Success,
  Info,
  Verification,
}

type AddCustomerDataShape = {
  customerId?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: number;
  locationName?: any;
  address1?: any;
  address2?: any;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
};

export interface CustomerLocationProps {
  customerData?: any;
  location?: any;
  openModal: (modalProps: any) => void;
  closeModal: () => void;
}
