import React from 'react';
import { Button, useToastContext } from '@resideo/blueprint-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRisClient } from '../../../../../hooks/useRisClient';
import { useTranslation } from 'react-i18next';

const RemoveEmployeeModal = ({ employee, companyName, companyId, closeModal }) => {
  const { addToast } = useToastContext();
  const { t } = useTranslation();
  const { client } = useRisClient();
  const queryClient = useQueryClient();

  const deleteEmployee = useMutation({
    mutationFn: async () => {
      return client.companies.companiesDeletePartnerUser(companyId, employee?.id);
    },
    onError: () => {
      addToast({
        toastType: 'Error',
        message: t('An error occurred. Please try again.'),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['allPartnerUsers'] });
      closeModal();
      addToast({ toastType: 'Success', message: t('Employee removed') });
    },
  });

  const removeEmployee = () => {
    deleteEmployee.mutate();
  };

  return (
    <div>
      <p>
        <strong>{`${employee?.user?.firstName} ${employee?.user?.lastName}`}</strong>&nbsp;
        {t('will be removed from')} <strong>{companyName}</strong>,{' '}
        {t('however their Resideo Account will not be deleted')}.
      </p>

      <div style={{ display: 'flex', justifyContent: 'right' }}>
        <Button
          variant='secondary'
          type='button'
          onClick={() => closeModal()}
          style={{ marginRight: '15px' }}>
          {t('Cancel')}
        </Button>
        <Button variant='destructivePrimary' type='button' onClick={removeEmployee}>
          {t('Remove')}
        </Button>
      </div>
    </div>
  );
};

export default RemoveEmployeeModal;
