import React, { useState, Suspense } from 'react';
import './CompanyLookupStyles.css';
import { Box, Flex, SearchField, SelectField } from '@resideo/blueprint-react';
import { useTranslation } from 'react-i18next';
import CompanyLookupTable from './CompanyLookupTable';
import { TableLoading } from 'SharedComponentsModule';
import { useQueryParams } from 'hooks/useQueryParams';
import PageHeader from 'SharedComponentsModule/PageHeader';

const CompanyLookup = () => {
  const { t } = useTranslation();
  const { searchParams, updateSearchParams, deleteSearchParams } = useQueryParams();
  const [search, setSearch] = useState<string>(searchParams.get('filter') || '');
  const [filter, setFilter] = useState<string | null>(searchParams.get('filter') || null);
  const [filterBy, setFilterBy] = useState<string | null>(searchParams.get('filterBy') || null);

  return (
    <div className='CompanyLookup'>
      <PageHeader
        data-test-companies-title
        title={t('Partner Companies')}
        controls={
          <Flex className='searchAndFilter'>
            <span>{t('Status')}</span>

            <SelectField
              className='selectField'
              data-test-companies-status-dropdown
              label=''
              id='status'
              name='status'
              version='v2'
              width='300px'
              value={(filterBy ?? '') as string}
              onChange={e => {
                e.preventDefault();
                const filterByValue = e.target.value === 'ALL' ? '' : e.target.value;
                setFilterBy(filterByValue);
                updateSearchParams([{ name: 'filterBy', value: filterByValue }]);
              }}>
              <option value='ALL'>{t('All')}</option>
              <option value='ACTIVE'>{t('Active')}</option>
              <option value='ARCHIVED'>{t('Archived')}</option>
              <option value='PENDING'>{t('Pending')}</option>
            </SelectField>

            <SearchField
              data-test-companies-search
              name='search'
              type='text'
              value={search}
              placeholder={t('Company Name')}
              onChange={e => {
                e.preventDefault();
                setSearch(e.target.value);
              }}
              onSearchClick={() => {
                setFilter(search);
                updateSearchParams([{ name: 'filter', value: search }]);
              }}
              onClearClick={() => {
                setSearch('');
                setFilter('');
                setFilterBy('');
                deleteSearchParams(['filter', 'filterBy']);
              }}
            />
          </Flex>
        }
        hideBorder
      />

      <Box marginX='medium' data-test-companies-list>
        <Suspense fallback={<TableLoading />}>
          <CompanyLookupTable filter={filter} filterBy={filterBy} />
        </Suspense>
      </Box>
    </div>
  );
};

export default CompanyLookup;
