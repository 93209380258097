import React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Text, Button } from '@resideo/blueprint-react';
import styled from 'styled-components';

const ButtonContainer = styled(Flex)`
  flex-direction: row;
  gap: 16px;
  justify-content: flex-end;
  padding-top: 35px;
`;
export const RemoveUserConfirmationModal = ({ onCancel, onSubmit }) => {
  const { t } = useTranslation();
  return (
    <Flex data-test-remove-user-modal flexDirection='column'>
      <Text marginBottom='medium' fontSize='medium' data-test-user-removal-text>
        {t('Once removed, the user will no longer have access to this location.')}
      </Text>
      <ButtonContainer>
        <Button data-test-remove-user-cancel-button variant='secondary' onClick={onCancel}>
          {t('Cancel')}
        </Button>
        <Button data-test-remove-user-remove-button variant='destructivePrimary' onClick={onSubmit}>
          {t('Remove')}
        </Button>
      </ButtonContainer>
    </Flex>
  );
};
