/* eslint-disable no-useless-escape */
import { parsePhoneNumber } from 'awesome-phonenumber';
import postalCodeRegex from '../utils/postalCodeRegex.json';

export function checkPhoneNumber(value: string) {
  const pn = parsePhoneNumber(value);
  return pn.isValid();
}

export interface singleAddressType {
  country?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
}

export function validateSingleAddress(
  errors,
  t,
  values: singleAddressType,
  prefix = '',
  IsStateFieldEnabled,
) {
  if (!values[`address1${prefix}`]) {
    errors[`address1${prefix}`] = t('Street Address is required.');
  }

  if (!values[`city${prefix}`]) {
    errors[`city${prefix}`] = t('City is required.');
  }

  if (!values[`state${prefix}`] && IsStateFieldEnabled) {
    errors[`state${prefix}`] = t('State/Province/Region is required.');
  }
  if (!values[`zip${prefix}`]) {
    errors[`zip${prefix}`] = t('Zip/Postal Code is required.');
  }

  if (!values[`country${prefix}`]) {
    errors[`country${prefix}`] = t('Country is required.');
  }

  return errors;
}

export function checkEmail(value: string) {
  return /\S+@\S+\.\S+[\.\S+]?/.test(value);
}

export function checkPostalCode(postalCode: string, countryCode: string) {
  const regExRule =
    new RegExp(postalCodeRegex[countryCode], 'i') || new RegExp(postalCodeRegex.GENERIC, 'i');
  return regExRule.test(postalCode);
}

export interface AddressType {
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zip: string;
  country: string;
}

export const validateAddress = (errors, t, values: AddressType, prefix = '') => {
  if (!values[`address1${prefix}`]) {
    errors[`address1${prefix}`] = t('Street Address is required.');
  }
  if (!values[`city${prefix}`]) {
    errors[`city${prefix}`] = t('City is required.');
  }
  if (!values[`state${prefix}`]) {
    errors[`state${prefix}`] = t('State/Province/Region is required.');
  }
  if (!values[`zip${prefix}`]) {
    errors[`zip${prefix}`] = t('Zip/Postal Code is required.');
  } else {
    if (!checkPostalCode(values[`zip${prefix}`], values[`country${prefix}`])) {
      errors[`zip${prefix}`] = t('Zip/Postal Code is invalid.');
    }
  }
  if (!values[`country${prefix}`]) {
    errors[`country${prefix}`] = t('Country is required.');
  }

  return errors;
};

export const convertSchemaAddress = (saddr): AddressType => ({
  address1: saddr.addressLine1,
  address2: saddr.addressLine2,
  city: saddr.city,
  state: saddr.stateProvinceRegionCode,
  zip: saddr.zipPostalCode,
  country: saddr.countryCode || 'US',
});

export const checkName = (value: string) => {
  return /^[\p{L}\s\d'_.-]+$/u.test(value);
};
