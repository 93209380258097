const moduleDomain = '/companies';

type DestinationString = 'module' | 'company';

const routes = {
  moduleDomain: moduleDomain,
  company: `${moduleDomain}/:id/info`,
};

export const generateRoute = (destination: DestinationString, options?: any) => {
  switch (destination) {
    case 'company':
      return routes.company.replace(':id', options.id);
    case 'module':
      return routes.moduleDomain;
  }
};
