import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRisClient } from 'hooks/useRisClient';
import { useSuspenseQuery } from '@tanstack/react-query';
import { TableV2 } from '@resideo/blueprint-react';
import './CustomerLookupStyles.css';
import { useNavigate } from 'react-router-dom';
import { formatDateWithFallback } from 'utils/formatDateWithFallback';
import { useIsMounted } from 'hooks/useIsMounted';
import { TABLE_RESULTS_PER_PAGE } from '../../../../config';
import useStateData from '../../../../hooks/useStateData';
import { getPartnerLocationCell } from 'SharedComponentsModule/TableCells/PartnerLocationCell';
import { getDeviceCell } from 'SharedComponentsModule/TableCells/DeviceCell';

interface PropsPartnerCustomers {
  partnerAccountId: string;
  filter: string | null;
  filterBy: string | null;
}
const CustomerLookupTable = ({ partnerAccountId, filter, filterBy }: PropsPartnerCustomers) => {
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { stateData } = useStateData();
  const [sortBy, setSortBy] = useState<any>({ updatedAt: 'DESC' });
  const isMounted = useIsMounted();
  const rowsPerPage = TABLE_RESULTS_PER_PAGE;
  const { client } = useRisClient();

  const { data, error } = useSuspenseQuery({
    queryKey: ['partnerCustomers', { filter, sortBy }, partnerAccountId],
    queryFn: async () =>
      (
        await client.companies.companiesSearchPartnerCustomers(
          partnerAccountId,
          filterBy === 'deviceId'
            ? JSON.stringify([{ 'device.deviceId': filter || '' }])
            : JSON.stringify([{ '*': filter || '' }]),
          JSON.stringify(sortBy),
        )
      ).data,
  });

  useEffect(() => {
    setPage(0);
  }, [sortBy, filter, filterBy]);

  //This is how we got updated paginated page loaded
  const getRows = () => {
    return (
      data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(node => {
        const id = node?.id;

        return {
          id: node?.id,
          name: `${node?.lastName}, ${node?.firstName}`,
          email: node?.email,
          location: getPartnerLocationCell(node?.id, node?.deviceLocations, stateData),
          devices: getDeviceCell(node?.id, node?.deviceLocations, filter),
          lastUpdated: formatDateWithFallback(node?.updatedAt), // format date to mm/dd/yyyy
          actions: [
            {
              name: 'View',
              ctaTag: 'view',
              text: t('View'),
              action: () => {
                navigate(`${window.location.pathname}/${id}`);
              },
            },
          ],
        };
      }) ?? []
    );
  };

  const partnerCustomersLength = data?.length || 0;

  const pageInfo = {
    hasNextPage: page * rowsPerPage + rowsPerPage < partnerCustomersLength,
    hasPreviousPage: page !== 0,
  };

  return (
    <div className='CustomerAccountsTable'>
      <TableV2
        dataTestAttribute='data-test-customers-table'
        headers={[
          {
            displayName: 'id',
            isSortable: false,
            isHiddenColumn: true,
          },
          {
            displayName: t('Name'),
            isSortable: true,
            useEllipses: true,
            onSortColumnName: 'lastName',
            onColumnCellClick: row => {
              navigate(`${window.location.pathname}/${row.id}`);
            },
          },
          {
            displayName: t('Email'),
            isSortable: true,
            onSortColumnName: 'email',
            useEllipses: true,
          },
          { displayName: t('Location'), isSortable: false, notBlueprintEllipses: true },
          { displayName: t('Device ID'), isSortable: false },
          {
            displayName: t('Last Updated'),
            isSortable: true,
            onSortColumnName: 'updatedAt',
          },
          { displayName: '', isSortable: false },
        ]}
        rows={getRows()}
        NoDataMessage={t('No data could be found')}
        onSort={(sortColumn, sortDirection) => {
          if (sortColumn && sortDirection && isMounted()) {
            setPage(0);
            setSortBy({
              [sortColumn]: sortDirection,
            });
          }
        }}
        initialSortColumn={'updatedAt'}
        initialSortDirection={'DESC'}
        hasPagination
        hasNext={pageInfo?.hasNextPage}
        hasPrevious={pageInfo?.hasPreviousPage}
        nextButtonLabel={t('Next')}
        previousButtonLabel={t('Previous')}
        onPaginationNextClick={() => {
          setPage(prev => prev + 1);
        }}
        onPaginationPrevClick={() => {
          setPage(prev => prev - 1);
        }}
        isError={
          error
            ? {
                errorHeader: t('Data Unavailable'),
                errorMessage: t('Table data unable to load. Try again later.'),
              }
            : undefined
        }
      />
    </div>
  );
};

export default CustomerLookupTable;
