import React from 'react';
import styled from 'styled-components';
import i18n from 'i18next';
import Header from '../SharedComponentsModule/Header/Header';
import { AvatarMenu } from '../SharedComponentsModule/Header/AvatarMenu';
import { useAuth } from 'utils/auth';

import { TopLevelNavigation } from 'SharedComponentsModule';
import { GLOBAL_CUSTOMER_SEARCH } from 'config';

const menuItems = [
  GLOBAL_CUSTOMER_SEARCH
    ? {
        name: 'partners',
        title: i18n.t('Partners'),
        subNav: [
          {
            name: 'companies',
            link: 'companies',
            title: i18n.t('Partner Companies'),
          },
          {
            name: 'customers',
            link: 'customers',
            title: i18n.t('Partner Customers'),
          },
        ],
      }
    : {
        name: 'companies',
        link: 'companies',
        title: i18n.t('Companies'),
      },
  {
    name: 'users',
    link: 'users',
    title: i18n.t('User Accounts'),
  },
  {
    name: 'publish',
    link: 'products',
    title: i18n.t('Publish'),
    subNav: [
      {
        name: 'products',
        link: 'products',
        title: i18n.t('Products'),
      },
      {
        name: 'services',
        link: 'services',
        title: i18n.t('Services'),
      },
    ],
  },
];

const StyledProfileMenu = styled(AvatarMenu)`
  display: block;
`;

const ContentContainer = styled.div`
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  padding: 60px 0 0 280px;
`;

const Content = styled.main`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: #fff;
  position: relative;
  overflow: auto;
`;

const NavigationLayout = props => {
  const { isAuthenticated } = useAuth();

  return (
    <>
      <Header primaryNav={isAuthenticated ? <StyledProfileMenu /> : null}></Header>
      <TopLevelNavigation menuItems={menuItems} />
      <ContentContainer>
        <Content>{props.children}</Content>
      </ContentContainer>
    </>
  );
};

export default NavigationLayout;
