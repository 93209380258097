import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ScopedSearch } from '@resideo/blueprint-react';
import { Option, filterByMap } from 'UsersModule/UsersLookupComponent/UserResults';
import { useQueryParams } from 'hooks/useQueryParams';
import { useQuery } from '@tanstack/react-query';
import { useRisClient } from 'hooks/useRisClient';

const Container = styled.div`
  display: grid;
  align-content: center;
  grid-gap: 2rem;
  margin-top: 1rem;
  h2,
  p,
  div {
    margin: 0;
  }
`;

enum UserDetailSearchType {
  EMAIL = 'EMAIL',
  MAC_ADDRESS = 'MAC_ADDRESS',
  NAME = 'NAME',
}

export const getFilter = (filterBy, filter) => {
  if (filterBy.toLowerCase() === 'name') {
    if (filter.includes(' ')) {
      const parts = filter.split(' ');
      return [{ firstName: parts[0], lastName: parts[1] }];
    } else {
      return [{ firstName: filter }, { lastName: filter }];
    }
  }

  if (filterBy.toLowerCase() === 'email') {
    return [{ email: filter }];
  }

  if (filterBy.toLowerCase() === 'mac_address') {
    return [{ 'device.deviceId': filter }];
  }

  return [{}];
};

const PartnerCustomersSearch = () => {
  const { t } = useTranslation();
  const { searchParams, updateSearchParams } = useQueryParams();
  const searchOptions = [
    {
      key: UserDetailSearchType.EMAIL,
      value: 'Email',
      label: t('By Email'),
      hintText: t('Lookup Partner Customer by Email'),
    },
    {
      key: UserDetailSearchType.NAME,
      value: 'Name',
      label: t('By Name'),
      hintText: t('Lookup Partner Customer by Name (ex. John Smith)'),
    },
    {
      key: UserDetailSearchType.MAC_ADDRESS,
      value: 'MAC/Device ID',
      label: t('By MAC/Device ID'),
      hintText: t('Lookup Partner Customer by MAC/Device ID'),
    },
  ];
  const [selectedValue, setSelectedValue] = useState(searchParams.get('filter') || undefined);
  const [selectedOption, setSelectedOption] = useState<Option | undefined>(() => {
    const key = searchParams.get('filterBy');
    if (key) {
      const tempInitialOption = searchOptions.find(
        option => option.key.toLowerCase() === key.toLowerCase(),
      );
      if (tempInitialOption) {
        return tempInitialOption;
      }
    }
  });

  const navigate = useNavigate();
  const { client } = useRisClient();

  const { data: searchData } = useQuery({
    queryKey: ['getPartnerCustomers', { filterby: selectedOption?.['key'], filter: selectedValue }],
    queryFn: async () => {
      const res = await client.companies.companiesSearchCompanyCustomers(
        selectedValue ? JSON.stringify(getFilter(selectedOption?.key, selectedValue)) : '',
        JSON.stringify({ firstName: 'ASC', lastName: 'ASC' }),
        10,
      );
      return res?.data;
    },
    staleTime: 5000,
    enabled: !!selectedValue,
  });

  useEffect(() => {
    if (!selectedOption && !selectedValue) return;
    if (!searchData) return;

    const mappedOption = selectedOption?.value ? filterByMap[selectedOption?.value] : undefined;
    const numberOfResults = (searchData as any)?.length || 0;

    if (numberOfResults === 1) {
      const partnerAccountId = searchData[0].partnerAccount?.id;
      const custId = searchData[0].id;

      if (custId) {
        navigate(
          `/companies/${partnerAccountId}/customers/${custId}?filter=${encodeURIComponent(
            selectedValue as string,
          )}&filterBy=${mappedOption}`,
          { replace: true },
        );
      }
    } else {
      navigate(
        `/customers/results?filter=${encodeURIComponent(
          selectedValue as string,
        )}&filterBy=${mappedOption}`,
        { replace: true },
      );
    }
  }, [searchData, selectedOption, selectedValue]);

  return (
    <Container data-test-patner-customers-search>
      <ScopedSearch
        uniqueName='AdminPortalPartnerCustomersLookup'
        name='PartnerCustomerLookup'
        placeholderText={t('Lookup Partner Customer By')}
        options={searchOptions}
        suggestionLimit={5}
        initialValue={selectedValue}
        initialOption={selectedOption}
        onSearchClick={async (search, option) => {
          updateSearchParams([
            { name: 'filter', value: search },
            { name: 'filterBy', value: option?.value || '' },
          ]);

          setSelectedOption(option);
          setSelectedValue(search);
        }}
      />
    </Container>
  );
};

export default PartnerCustomersSearch;
