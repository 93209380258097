import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useToastContext } from '@resideo/blueprint-react';
import { useParams } from 'react-router';

import { useNavigate } from 'react-router-dom';
import ServiceForm, { ServiceFormHandlers, ServiceFormValues } from './ServiceForm';
import { useMutation } from '@tanstack/react-query';
import { useRisClient } from 'hooks/useRisClient';
import { UpdatePartnerProgramProductModel } from '@resideo/web-integration-services-api-client';

interface ServiceFormUpdateProps {
  initialValues: ServiceFormValues;
  availablePrograms?: { id?: string | null; name?: string | null }[];
  onCancel?: (values: any) => void;
  editMode?: boolean;
}

const EditServiceForm = ({
  initialValues,
  availablePrograms,
  onCancel,
  editMode,
}: ServiceFormUpdateProps) => {
  const programProductFormRef = useRef<ServiceFormHandlers>(null);
  const { t } = useTranslation();
  const { client } = useRisClient();
  const { addToast } = useToastContext();
  const { id = '' } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const editProgramProduct = useMutation({
    mutationKey: ['editProgramProduct'],
    mutationFn: async ({
      id,
      variables,
    }: {
      id: string;
      variables: UpdatePartnerProgramProductModel;
    }) => {
      await client.core.coreUpdatePartnerProgramProduct(id, variables);
    },
    onSuccess: () => {
      addToast({
        toastType: 'Success',
        message: t('Saved'),
      });
      navigate('/services');
    },
    onError: () => {
      addToast({
        toastType: 'Error',
        message: t('Something went wrong'),
      });
    },
  });

  const saveChanges = (values: any) => {
    if (programProductFormRef?.current) programProductFormRef.current.setDisabled(true);
    const {
      name,
      programOffering,
      variation,
      billingCycle,
      orderPrice,
      recurringPrice,
      recurringPriceDescription,
      sku,
      skuType,
      sapProductCode,
      sapProductId,
      sapRatePlanId,
      partnerProgramId,
      //parentProductId,
    } = values;
    editProgramProduct.mutate({
      id,
      variables: {
        name,
        programOffering,
        variation: `${variation ?? ''}`.trim() === '' ? null : variation,
        billingCycle: `${billingCycle ?? ''}`.trim() === '' ? null : billingCycle,
        orderPrice: `${orderPrice ?? ''}`.trim() === '' ? undefined : parseFloat(`${orderPrice}`),
        recurringPrice:
          `${recurringPrice ?? ''}`.trim() === '' ? null : parseFloat(`${recurringPrice}`),
        recurringPriceDescription,
        sku,
        skuType,
        sapProductCode,
        sapProductId,
        sapRatePlanId,
        partnerProgramId: `${partnerProgramId ?? ''}`.trim() === '' ? null : partnerProgramId,
      },
    });
  };

  return (
    <ServiceForm
      ref={programProductFormRef}
      initialValues={initialValues}
      availablePrograms={availablePrograms}
      onSubmit={saveChanges}
      onCancel={onCancel}
      editMode={editMode}
    />
  );
};

export default EditServiceForm;
