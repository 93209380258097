import React, { FC, Suspense, useState } from 'react';
import { Form, Formik, FormikErrors } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  InputField,
  PhoneInputField,
  SelectField,
  newUseCountrySelector,
} from '@resideo/blueprint-formik';
import { Box, Button, Flex } from '@resideo/blueprint-react';
import { checkPhoneNumber, validateSingleAddress } from '../../../utils/validation';
import { getInternationalPhone } from 'utils/common';
import { checkUrl } from 'utils';
import './CompanyInfoStyles.css';
import PageHeader from 'SharedComponentsModule/PageHeader';
import Autocomplete from '../CustomersComponent/Autocomplete';
import { AUTOCOMPLETE_ADDRESS_FIELD } from 'config';
import styled from 'styled-components';

const EditForm = styled(Form)`
  width: 100%;
`;

const ButtonContainer = styled.div`
  margin: 0 25px 25px 0;
  text-align: right;
`;

export interface CompanyFormValues {
  status?: string;
  name?: string;
  country?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  companyPhone?: any;
  websiteUrl?: string;
  primaryBusinessType?: string;
  secondaryBusinessType?: string;
}

interface CompanyEditFormProps {
  initialValues?: CompanyFormValues;
  onSubmit: (values: any, autoCompleteSelected: boolean) => void;
  onCancel: (values: any) => void;
}

const CompanyInfoForm: FC<CompanyEditFormProps> = ({ onSubmit, onCancel, initialValues = {} }) => {
  const [autoCompleteSelected, setAutoCompleteSelected] = useState<boolean>(false);
  const { t } = useTranslation();
  const apiKey = import.meta.env.REACT_APP_COUNTRY_STATE_API_KEY || '';
  const {
    CountrySelectorWrapper: CountryField,
    StateSelectorWrapper: StateField,
    IsStateFieldEnabled,
    setCountry,
    setStateValue,
  } = newUseCountrySelector({
    apiKey,
  });

  initialValues = {
    ...initialValues,
    companyPhone: getInternationalPhone(initialValues.companyPhone),
  };

  const validate = (values: CompanyFormValues) => {
    const errors: FormikErrors<CompanyFormValues> = {};

    if (!values.name || values.name.trim() === '') {
      errors.name = t('Company Name is required.');
    }

    if (!values.companyPhone) {
      errors.companyPhone = t('Company Phone is required.');
    } else if (!checkPhoneNumber(values.companyPhone)) {
      errors.companyPhone = t('Invalid phone number.');
    }

    if (values.websiteUrl) {
      if (!checkUrl(values.websiteUrl as string)) {
        errors.websiteUrl = t('Invalid web address.');
      }
    }

    validateSingleAddress(errors, t, values, '', IsStateFieldEnabled);

    return errors;
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={values => onSubmit(values, autoCompleteSelected)}
      validate={validate}>
      {({ setFieldValue, values, errors, setFieldTouched, touched, dirty }) => (
        <EditForm role='form' noValidate>
          <PageHeader title={t('Edit Company Info')} hideBorder />
          <Box marginX='large' width={500}>
            <p>{t('*Required fields')}</p>
            <Box width={170} marginBottom={['medium']}>
              <SelectField
                version='v2'
                marginBottom='medium'
                variant='active'
                label={t('Status *')}
                name='status'>
                <option value=''>{t('Select...')}</option>
                <option value='ACTIVE'>{t('Active')}</option>
                <option value='ARCHIVED'>{t('Archived')}</option>
              </SelectField>
            </Box>
            <Box marginBottom={['medium']}>
              <InputField
                version='v2'
                label={t('Company Name *')}
                marginBottom='medium'
                name='name'
                type='text'
              />
              {AUTOCOMPLETE_ADDRESS_FIELD ? (
                <Suspense>
                  <Autocomplete
                    initialValue={initialValues?.address1}
                    setCountry={setCountry}
                    setStateValue={setStateValue}
                    fieldName={'address1'}
                    label={t('Address')}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    touched={touched}
                    errors={errors}
                    setAutoCompleteSelected={setAutoCompleteSelected}
                  />
                </Suspense>
              ) : (
                <InputField
                  version='v2'
                  label={t('Street Address *')}
                  marginBottom='medium'
                  name='address1'
                  type='text'
                />
              )}
              <Box marginBottom={['medium']}>
                <InputField
                  label={t('Apt, Suite, etc.')}
                  version='v2'
                  marginBottom='medium'
                  name='address2'
                  type='text'
                />
              </Box>
              <Box marginBottom={['medium']}>
                <InputField version='v2' label={t('Zip / Postal Code *')} name='zip' type='text' />
              </Box>
              <Flex marginBottom={['medium']}>
                <Box paddingRight='medium' width={[1 / 2]}>
                  <InputField version='v2' id='city' label={t('City *')} name='city' type='text' />
                </Box>
                <Box width={[1 / 2]}>
                  <StateField
                    version='v2'
                    name='state'
                    id='state'
                    label={t('State / Province / Region')}
                    variant='primary'
                    required
                    initialValue={values.state}
                    value={values.state}
                    blankOption={t('Select State...')}
                    error={touched.state && errors.state}
                    onStateChange={val => {
                      setFieldValue('state', val);
                    }}
                  />
                </Box>
              </Flex>
              <Box marginBottom={['medium']}>
                <CountryField
                  version='v2'
                  onCountryChange={val => {
                    setFieldValue('country', val);
                  }}
                  initialValue={values.country}
                  blankOption={t('Select Country...')}
                  label={t('Country *')}
                  name='Country'
                  onBlur={() => {
                    setFieldTouched('country');
                  }}
                  error={touched.country && errors.country}
                />
              </Box>
              <Box marginBottom={['medium']}>
                <PhoneInputField
                  version='v2'
                  label={t('Company Phone *')}
                  name='companyPhone'
                  type='text'
                  phoneInputConfig={{
                    preferredCountries: ['us', 'ca'],
                    country: 'us',
                    countryCodeEditable: false,
                  }}
                />
              </Box>
              <Box marginBottom={['medium']}>
                <InputField
                  version='v2'
                  label={t('Website')}
                  marginBottom='medium'
                  name='websiteUrl'
                  type='text'
                />
              </Box>
            </Box>
          </Box>
          <ButtonContainer>
            <Button variant='secondary' marginRight={'medium'} type='button' onClick={onCancel}>
              {t('Cancel')}
            </Button>
            <Button variant='primary' type='submit' disabled={!dirty}>
              {t('Save')}
            </Button>
          </ButtonContainer>
        </EditForm>
      )}
    </Formik>
  );
};

CompanyInfoForm.displayName = 'CompanyInfoForm';

export default CompanyInfoForm;
