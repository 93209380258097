import React from 'react';
import { Button, useToastContext } from '@resideo/blueprint-react';
import { useTranslation } from 'react-i18next';
import { useRisClient } from 'hooks/useRisClient';
import { useMutation, useQueryClient } from '@tanstack/react-query';

const DeleteEmployeeModal = ({ user, company, closeModal }) => {
  const { addToast } = useToastContext();
  const { t } = useTranslation();
  const { client } = useRisClient();
  const queryClient = useQueryClient();

  const deleteEmployee = useMutation({
    mutationKey: ['deleteEmployee'],
    mutationFn: async (employeeId: string) => {
      await client.companies.companiesDeletePartnerUser(
        company?.partnerAccount?.id || '',
        employeeId,
      );
    },
    onSuccess: () => {
      closeModal();
      queryClient.invalidateQueries({ queryKey: ['getUserById'] });
      addToast({ toastType: 'Success', message: t('Employee removed') });
    },
    onError: () => {
      addToast({ toastType: 'Error', message: t('An error occurred. Please try again.') });
    },
  });

  const handleDeleteEmployee = () => {
    const idToDelete = user?.partnerUsers?.find(partnerUser => partnerUser?.id === company?.id)?.id;
    deleteEmployee.mutate(idToDelete);
  };

  return (
    <div>
      <p>
        <strong>{`${user?.firstName} ${user?.lastName}`}</strong>&nbsp;
        {t('will be removed from')} <strong>{company?.partnerAccount?.name}</strong>,
        {t(' however their Resideo Account will not be deleted')}.
      </p>

      <div style={{ display: 'flex', justifyContent: 'right' }}>
        <Button
          variant='secondary'
          type='button'
          onClick={() => closeModal()}
          style={{ marginRight: '15px' }}>
          {t('Cancel')}
        </Button>
        <Button variant='destructivePrimary' type='button' onClick={handleDeleteEmployee}>
          {t('Remove')}
        </Button>
      </div>
    </div>
  );
};

export default DeleteEmployeeModal;
