// This module contains all sections pertaining to companies.
import React from 'react';
import { Outlet, RouteObject } from 'react-router-dom';
import CompanyLookup from './CompanyLookupComponent';
import companyDetailsRoutes, { CompanyInfoLayout } from './CompanyDetailsComponent';
import CustomerAccount from './CompanyDetailsComponent/CustomersComponent/CustomerDetailsComponent';

export const CompaniesModule = () => <Outlet />;

const routes: RouteObject[] = [
  {
    index: true,
    element: <CompanyLookup />,
  },
  {
    path: ':id/customers/:customerId',
    element: <CustomerAccount />,
  },
  {
    path: ':id/*',
    element: <CompanyInfoLayout />,
    children: companyDetailsRoutes,
  },
];

export default routes;
