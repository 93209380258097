import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Breadcrumbs } from '@resideo/blueprint-react';
import { useTranslation } from 'react-i18next';
import ServiceDetails from './ServiceDetails';
import PageHeader from 'SharedComponentsModule/PageHeader';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useRisClient } from 'hooks/useRisClient';
import { PartnerProgramProductModel } from '@resideo/web-integration-services-api-client';

const ServicePage = () => {
  const { t } = useTranslation();
  const { client } = useRisClient();

  const { id = '' } = useParams<{ id: string }>();
  const [displayData, setDisplayData] = useState<PartnerProgramProductModel | null | undefined>(
    null,
  );
  const [initialValues, setInitialValues] = useState<any>({});
  const [showHeader, setShowHeader] = useState(true);

  const { data } = useSuspenseQuery({
    queryKey: ['partnerProgramProduct', id],
    queryFn: async () => {
      const res = await client.core.coreGetPartnerProgramProduct(id);
      return res.data;
    },
  });

  const getValue = value => {
    return value || '';
  };

  useEffect(() => {
    if (id !== 'new' && data) {
      setDisplayData(data);
    }
  }, [data]);

  useEffect(() => {
    setInitialValues({
      name: getValue(displayData?.name),
      programOffering: getValue(displayData?.programOffering),
      variation: getValue(displayData?.variation),
      billingCycle: getValue(displayData?.billingCycle),
      orderPrice: displayData?.orderPrice || '0.00',
      recurringPrice: getValue(displayData?.recurringPrice),
      recurringPriceDescription: getValue(displayData?.recurringPriceDescription),
      sku: getValue(displayData?.sku),
      skuType: getValue(displayData?.skuType),
      sapProductCode: getValue(displayData?.sapProductCode),
      sapProductId: getValue(displayData?.sapProductId),
      sapRatePlanId: getValue(displayData?.sapRatePlanId),
      partnerProgramId: getValue(displayData?.partnerProgram?.id),
      parentProductId: getValue(displayData?.parentPartnerProgramProduct?.id),
    });
  }, [displayData]);

  return (
    <>
      {showHeader && (
        <PageHeader
          title={initialValues?.name}
          breadcrumbs={
            <Breadcrumbs>
              <Link to='/services'>{t('Services')}</Link>
              <Link to='#'>{initialValues?.name}</Link>
            </Breadcrumbs>
          }
        />
      )}
      <ServiceDetails initialValues={initialValues} headerVisible={setShowHeader} />
    </>
  );
};

export default ServicePage;
