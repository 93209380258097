// This module pertains to all sections for users.
import React from 'react';
import { Outlet, RouteObject } from 'react-router-dom';
import UserResults from './UsersLookupComponent/UserResults';
import UserDetails from './UserDetailsComponent/UserDetails';
import FindUserAccount from './UsersLookupComponent/FindUserAccount';

export const UsersModule = () => <Outlet />;

const routes: RouteObject[] = [
  {
    index: true,
    element: <FindUserAccount />,
  },
  {
    path: ':id',
    element: <UserDetails />,
  },
  {
    path: 'results',
    element: <UserResults />,
  },
];

export default routes;
