import { format } from 'date-fns';
import i18n from 'i18next';

export const formatDateWithFallback = date => {
  if (!date) {
    return i18n.t('No Date');
  }

  return format(new Date(date), 'MM/dd/yyyy');
};
