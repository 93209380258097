import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomerInfoForm from '../Steps/CustomerInfoForm';
import { checkPhoneNumberForCountryCode, getInternationalPhone } from 'utils/common';
import { useToastContext } from '@resideo/blueprint-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { UpdatePartnerCustomerModel } from '@resideo/web-integration-services-api-client/dist';
import { useRisClient } from 'hooks/useRisClient';
import { useUser } from '../CustomerLookupComponent/useUser';

const EditCustomerForm = ({ closeModal, initialValues }) => {
  const { t } = useTranslation();
  const { addToast } = useToastContext();
  const { client } = useRisClient();
  const [{ partnerAccountId }] = useUser();
  const queryClient = useQueryClient();

  const editCustomer = useMutation({
    mutationKey: ['partnerCustomerUpdate'],
    mutationFn: async (vars: { partnerCustomerId; customer: UpdatePartnerCustomerModel }) => {
      return await client.companies.companiesUpdatePartnerCustomer(
        partnerAccountId as string,
        vars.partnerCustomerId,
        {
          firstName: vars.customer.firstName,
          lastName: vars.customer.lastName,
          email: vars.customer.email,
          phoneNumber: vars.customer.phoneNumber,
        },
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getPartnerCustomer'] });

      addToast({
        toastType: 'Success',
        message: t('Changes saved'),
      });
      closeModal();
    },
    onError: () => {
      addToast({
        toastType: 'Error',
        message: t('Something went wrong!'),
      });
    },
  });

  const onSubmit = values => {
    const countryCodeIsTheOnlyInput =
      values?.phoneNumber &&
      `+${checkPhoneNumberForCountryCode(values?.phoneNumber).toString()}` === values?.phoneNumber;

    editCustomer.mutate({
      partnerCustomerId: initialValues?.id,
      customer: {
        firstName: values?.firstName,
        lastName: values?.lastName,
        email: values?.email,
        phoneNumber: countryCodeIsTheOnlyInput ? '' : values?.phoneNumber,
      },
    });
  };

  return (
    <CustomerInfoForm
      {...{
        closeModal,
        onSubmit,
        isEditMode: true,
        initialValues: {
          ...initialValues,
          phoneNumber: getInternationalPhone(initialValues.phoneNumber),
        },
      }}
    />
  );
};

export default EditCustomerForm;
