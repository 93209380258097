import React, { FC, useEffect, useState, RefObject, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Flex, Text } from '@resideo/blueprint-react';
import { Link as RRLink } from 'react-router-dom';
import { useAuth } from 'utils/auth';
import ProfileAvatar from './ProfileAvatar';
import useOutsideClick from 'hooks/useOutsideClick';

const Menu = styled.div`
  position: relative;
  margin-right: 30px;
`;

const AvatarMenuButton = styled.button`
  appearance: none;
  box-sizing: border-box;
  cursor: pointer;
  padding: 0;
  border-radius: 50%;
  border: 1px solid #ccc;
  max-width: 2.25rem;
  max-height: 2.25rem;
  overflow: hidden;
  &:focus {
    outline: none;
    box-shadow: 0 0 0.1rem 0.1rem #ccc;
  }
`;

const AvatarMenuDropDownList = styled.div`
  display: block;
  white-space: nowrap;
  border: 0;
  background: #ffffff;
  box-shadow: -6px 5px 15px -3px #00000040;
  outline: none;
  padding: 1rem 0;
  position: absolute;
  top: calc(100% + 10px);
  right: -10px;
  text-align: left;
  z-index: var(--zidx-profile-menu);

  &:after {
    content: '';
    position: absolute;
    right: 20px;
    bottom: 100%;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid white;
    clear: both;
  }
`;

const StyledMenuLink = styled(RRLink)`
  display: block;
  text-decoration: none;
  padding: 5px 18px;
  color: ${({ theme }) => theme.colors.primary};

  &:hover {
    background: ${({ theme }) => theme.colors.primary};
    color: #fff;
  }
`;

export const AvatarMenu: FC = rest => {
  const { t } = useTranslation();
  const ref: RefObject<any> = useRef();
  const [isOpen, setIsOpen] = useState(false);

  useOutsideClick(ref, () => {
    setIsOpen(false);
  });

  const { getIdTokenClaims } = useAuth();
  const [contactEmail, setContactEmail] = useState();

  useEffect(() => {
    getIdTokenClaims().then(({ email }) => {
      setContactEmail(email);
    });
  }, [getIdTokenClaims]);

  return (
    <Menu>
      <AvatarMenuButton
        {...rest}
        ref={ref}
        onClick={() => {
          setIsOpen(!isOpen);
        }}>
        <ProfileAvatar firstName={contactEmail} variant='small' />
      </AvatarMenuButton>

      {isOpen && (
        <AvatarMenuDropDownList>
          <Flex flexDirection='column' paddingX='medium' paddingY='small'>
            <Text color='faded' fontSize='small'>
              {contactEmail}
            </Text>
          </Flex>

          <StyledMenuLink to='/sign-out'>{t('Sign Out')}</StyledMenuLink>
        </AvatarMenuDropDownList>
      )}
    </Menu>
  );
};
