import React, { FC } from 'react';
import { RouterProvider } from 'react-router-dom';
import router from 'routes';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import ReactQueryProvider from 'utils/ReactQueryProvider';

// Added flag provider
const App: FC = () => {
  return (
    <ReactQueryProvider>
      <RouterProvider router={router} />
      <ReactQueryDevtools initialIsOpen={false} />
    </ReactQueryProvider>
  );
};

export default App;
