// This module pertains to all sections for services.
import React from 'react';
import { Outlet, RouteObject } from 'react-router-dom';
import ServicesPage from './ServicesPageComponent/ServicesPage';
import ServicePage from './ServicePageComponent/ServicePage';

export const ServicesModule = () => <Outlet />;

const routes: RouteObject[] = [
  {
    index: true,
    element: <ServicesPage />,
  },
  {
    path: ':id',
    element: <ServicePage />,
  },
];

export default routes;
