import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Text, Button, useToastContext } from '@resideo/blueprint-react';
import { ButtonContainer, ModalContent } from '../DeviceModals/RemoveDeviceModal';
import { useRisClient } from 'hooks/useRisClient';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useUser } from '../../CustomerLookupComponent/useUser';
import {
  ActiveStatus,
  PartnerDeviceServiceType,
} from '@resideo/web-integration-services-api-client/dist';

const StrongText = styled(Text)`
  font-weight: bold;
`;

export const DeactivateServiceModal = ({ serviceName, location, closeModal, deviceId }) => {
  const { t } = useTranslation();
  const { addToast } = useToastContext();
  const { client } = useRisClient();
  const queryClient = useQueryClient();
  const [{ partnerAccountId }] = useUser();

  const deactivateDeviceService = useMutation({
    mutationKey: ['deactivateBrandingPartnerDeviceService'],
    mutationFn: async (variables: { partnerDeviceLocationId: string; deviceId: string }) => {
      await client.companies.companiesUpdatePartnerDeviceService(
        partnerAccountId as string,
        variables?.partnerDeviceLocationId as string,
        {
          type: PartnerDeviceServiceType.BRANDING_PARTNER_DEVICE_SERVICE,
          status: ActiveStatus.INACTIVE,
          deviceId: variables?.deviceId,
        },
      );
    },
    onError: () => {
      closeModal();
      addToast({ toastType: 'error', message: t('Something went wrong!') });
    },
    onSuccess: () => {
      // TODO: invalidate query coming from CustomerLocations.tsx once that's in
      queryClient.invalidateQueries({ queryKey: ['getPartnerCustomer'] });
      closeModal();
      addToast({
        toastType: 'success',
        message: t('Service deactivated'),
      });
    },
  });

  const handleDeactivateDeviceService = deviceId => {
    deactivateDeviceService.mutate({
      partnerDeviceLocationId: location?.id,
      deviceId: deviceId,
    });
  };
  return (
    <ModalContent>
      <Text data-test-deactivate-service-text1 marginBottom='small'>
        {t('The service:')}
      </Text>
      <StrongText data-test-deactivate-service-serviceName marginBottom='small'>
        {serviceName}
      </StrongText>
      <Text data-test-deactivate-service-text2 marginBottom='small'>
        {t('will be removed permanently from this location:')}
      </Text>
      <StrongText data-test-deactivate-service-location marginBottom='small'>
        {location?.name}
      </StrongText>
      <Text>
        {location?.address?.addressLine1} {location?.address?.addressLine2}
      </Text>
      <Text>
        {location?.address?.city} {location?.address?.state} {location?.address?.zipPostalCode}
      </Text>
      <Text marginBottom='small'>{location?.address?.countryCode}</Text>
      <ButtonContainer>
        <Button
          data-test-cancel-btn
          variant='secondary'
          onClick={() => {
            closeModal();
          }}>
          {t('Cancel')}
        </Button>
        <Button
          data-test-deactivate-btn
          variant='destructivePrimary'
          onClick={() => {
            handleDeactivateDeviceService(deviceId);
          }}>
          {t('Deactivate')}
        </Button>
      </ButtonContainer>
    </ModalContent>
  );
};
