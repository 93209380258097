import React, { useState } from 'react';
import { Button, Flex, useToastContext, useDeviceModal } from '@resideo/blueprint-react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import '../CustomerDetailsStyles.css';
import styled from 'styled-components';
import WarningIcon from '../../../../../SharedComponentsModule/Icons/alert.svg?react';
import { useRisClient } from '../../../../../hooks/useRisClient';
import { ADD_DEVICE_BASE_URL, ENABLE_VX_DEVICES } from '../../../../../config';

export const StyledWarningIcon = styled(WarningIcon)`
  height: 38px;
  margin-bottom: 11px;
`;

enum AddDeviceSteps {
  'AddDeviceSpinner',
  'AddDeviceType',
  'AddDevice',
  'UnableToAddDeviceServiceError',
  'DeviceAlreadyAddedError',
  'BrandingDeviceServiceError',
  'UFCDeviceMatchError',
  'Success',
}

interface AddDeviceProps {
  location: any;
  customerData: any;
}

const AddDeviceModal: React.FC<AddDeviceProps> = ({ location, customerData }) => {
  const { t } = useTranslation();
  const [searchError, setSearchError] = useState('');
  const [deviceInfo, setDeviceInfo] = useState({
    deviceTypeDisplayName: '',
    deviceSku: '',
    deviceName: '',
    deviceVariant: '',
    deviceType: '',
  });
  const [deviceId, setDeviceId] = useState<string>('');
  const [step, setStep] = useState<AddDeviceSteps>(AddDeviceSteps.AddDeviceType);
  const { openDeviceModal, AddDeviceModal, closeDeviceModal } = useDeviceModal();
  const { addToast } = useToastContext();

  const { id } = useParams<{ id: string }>();
  const { client } = useRisClient();
  const queryClient = useQueryClient();

  const deviceNewInput = useMutation({
    mutationFn: async (vars: { macId }) => {
      return client.companies.companiesPerformDeviceHandoff({
        deviceId: vars.macId,
        deviceName: deviceInfo.deviceName,
        deviceType: deviceInfo.deviceType,
        deviceVariant: deviceInfo.deviceVariant,
        firstName: customerData?.firstName,
        lastName: customerData?.lastName,
        sendToEmail: customerData?.email,
        sku: deviceInfo.deviceSku,
        templateLanguage: 'en',
        baseUrl: ADD_DEVICE_BASE_URL,
        partnerAccountId: id,
        partnerDeviceLocationId: location.id,
      });
    },
    onError: err => {
      setSearchError('');
      const parsedError = JSON.parse(JSON.stringify(err));
      const errorBody = parsedError?.body?.errors?.[0];
      if (
        parsedError.status === 409 &&
        errorBody?.code === 'ENTITY_CONFLICT' &&
        (errorBody?.reasonCode === 'ALREADY_REGISTERED' ||
          errorBody?.reasonCode === 'EXISTING_INSTALLATION_SERVICE')
      ) {
        setStep(AddDeviceSteps.DeviceAlreadyAddedError);
      } else if (
        parsedError.status === 404 &&
        errorBody?.code === 'ENTITY_NOT_FOUND' &&
        errorBody?.message === 'No device exists for the specified ID'
      ) {
        setSearchError(t('Invalid MAC ID'));
        setStep(AddDeviceSteps.AddDevice);
      } else if (
        parsedError.status === 409 &&
        errorBody?.code === 'emailDeviceIdMatch' &&
        errorBody?.reasonCode === 'ALREADY_REGISTERED'
      ) {
        setStep(AddDeviceSteps.UFCDeviceMatchError);
      } else {
        setStep(AddDeviceSteps.UnableToAddDeviceServiceError);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getPartnerCustomer'] });
      setSearchError('');
      addToast({
        toastType: 'success',
        message: `${t('New')} ${deviceInfo.deviceTypeDisplayName} ${t('Added')}`,
      });
      closeDeviceModal();
    },
  });

  const addDeviceRest = macId => {
    setDeviceId(macId);
    setStep(AddDeviceSteps.AddDeviceSpinner);
    deviceNewInput.mutate({ macId: macId });
  };

  const openAddDeviceModal = () => {
    openDeviceModal();
    setStep(AddDeviceSteps.AddDeviceType);
  };

  return (
    <>
      <div>
        <Flex className='DevicesHeader'>
          <h3 data-test-devices='true'>{t('Devices')}</h3>
          <Button
            data-test-add-device
            className='AddLocation'
            variant='secondary'
            onClick={openAddDeviceModal}>
            {t('Add Device')}
          </Button>
        </Flex>
      </div>
      <AddDeviceModal
        updateDeviceStep={(deviceStep, deviceDetails: any = {}, clearError) => {
          setStep(deviceStep);
          // When clicking on the back button, we are losing deviceDetails in blueprint so not replacing it when value is empty.
          if (deviceDetails.deviceSku && deviceDetails.deviceTypeDisplayName) {
            setDeviceInfo(deviceDetails);
          }
          if (clearError) {
            setSearchError('');
            setDeviceId('');
          }
        }}
        addDeviceResponse={step}
        inValidDeviceError={searchError}
        translationFunction={t}
        deviceId={deviceId}
        addDevice={addDeviceRest}
        closeDeviceModal={closeDeviceModal}
        appName={'Resideo-Central'}
        enableVXDevices={ENABLE_VX_DEVICES}
      />
    </>
  );
};

export default AddDeviceModal;
