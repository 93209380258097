import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text, Button, Flex } from '@resideo/blueprint-react';
import styled from 'styled-components';

export const ButtonContainer = styled(Flex)`
  flex-direction: row;
  gap: 16px;
  width: 100%;
  justify-content: flex-end;
  padding-top: 35px;
`;

export const ModalContent = styled(Flex)`
  line-height: 24px;
  flex-direction: column;
`;

export const UnableToRemoveLocationModal = ({ onSubmit }) => {
  const { t } = useTranslation();

  return (
    <ModalContent>
      <Text data-test-cannot-remove-location-text>
        {t('To remove this location, ensure that all associated devices are removed first.')}
      </Text>
      <ButtonContainer>
        <Button variant='primary' onClick={onSubmit} data-test-close-btn>
          {t('Close')}
        </Button>
      </ButtonContainer>
    </ModalContent>
  );
};
