import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text, Button, Flex } from '@resideo/blueprint-react';
import styled from 'styled-components';

export const ButtonContainer = styled(Flex)`
  flex-direction: row;
  gap: 16px;
  width: 100%;
  justify-content: flex-end;
  padding-top: 35px;
`;

export const ModalContent = styled(Flex)`
  line-height: 24px;
  flex-direction: column;
`;

export const LocationName = styled.span`
  font-weight: 500;
`;

export const RemoveLocationModal = ({ onCancel, onSubmit, locationName }) => {
  const { t } = useTranslation();

  return (
    <ModalContent>
      <Text data-test-remove-location-text marginBottom='large'>
        {t('Are you sure you want to remove')}
        <LocationName>{` ${locationName} `}</LocationName>
        {t('location?')}
      </Text>
      <ButtonContainer>
        <Button data-test-cancel-btn variant='secondary' onClick={onCancel}>
          {t('Cancel')}
        </Button>
        <Button data-test-remove-btn variant='destructivePrimary' onClick={onSubmit}>
          {t('Remove Location')}
        </Button>
      </ButtonContainer>
    </ModalContent>
  );
};
