import { Flex } from '@resideo/blueprint-react';
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import PartnerCustomersSearch from './PartnerCustomersSearch';

const Container = styled.div`
  display: grid;
  align-content: center;
  grid-gap: 2rem;
  margin-top: 10rem;
  padding: 0 1rem;
  h2,
  p,
  div {
    margin: 0;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    padding: 0 10rem;
  }
`;

const Page = styled(Flex)`
  min-height: calc(100vh - 62px);
  flex-direction: column;
  justify-content: space-between;
`;

const FindPartnerCustomersAccount = () => {
  const { t } = useTranslation();

  return (
    <Page data-test-partner-customers-title title={t('Partner Customers')}>
      <Container>
        <h2>{t('Partner Customer Search')}</h2>
        <p>{t('Find a partner customer that belongs to a partner company.')}</p>
        <PartnerCustomersSearch />
      </Container>
    </Page>
  );
};

export default FindPartnerCustomersAccount;
