import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatDateWithFallback } from '../../../utils/formatDateWithFallback';
import styled from 'styled-components';

const DescriptionListContainer = styled.dl`
  margin: 15px 0;
  font-size: 0.875rem;
  color: #303030;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
`;

const DescriptionTerm = styled.dt`
  font-weight: bold;
  margin-bottom: 5px;
`;

const DescriptionDetails = styled.dd`
  margin: 0 0 30px;
`;

const CompanyInfoDetails = ({ data }) => {
  const { t } = useTranslation();

  const rendererStatus = value => {
    if (value == 'ARCHIVED') {
      value = t('Archived');
    } else if (value == 'PENDING') {
      value = t('Pending');
    } else if (value == 'ACTIVE') {
      value = t('Active');
    }
    return value;
  };

  const renderAddress = address => {
    if (address) {
      const {
        addressLine1,
        addressLine2,
        city,
        stateProvinceRegionCode,
        zipPostalCode,
        countryCode,
      } = address;
      return (
        <>
          {addressLine1} <br />
          {addressLine2 && (
            <>
              {addressLine2} <br />
            </>
          )}
          {`${city} ${stateProvinceRegionCode} ${zipPostalCode}`} <br />
          {countryCode}
        </>
      );
    }
  };

  const renderWebsite = value => (value === 'https://' || value === '' ? '-' : value);

  return (
    <DescriptionListContainer>
      <div>
        <DescriptionTerm>{t('Company Name')}</DescriptionTerm>
        <DescriptionDetails>{data?.name || ''}</DescriptionDetails>
        <DescriptionTerm>{t('Address')}</DescriptionTerm>
        <DescriptionDetails>{renderAddress(data?.shipToAddress || {})}</DescriptionDetails>
        <DescriptionTerm>{t('Company Phone')}</DescriptionTerm>
        <DescriptionDetails>{data?.primaryPhoneNumber || ''}</DescriptionDetails>
        <DescriptionTerm>{t('Website')}</DescriptionTerm>
        <DescriptionDetails>{renderWebsite(data?.websiteUrl || '')}</DescriptionDetails>
      </div>
      <div>
        <DescriptionTerm>{t('Status')}</DescriptionTerm>
        <DescriptionDetails>{rendererStatus(data?.status || '')}</DescriptionDetails>
        <DescriptionTerm>{t('Date Submitted')}</DescriptionTerm>
        <DescriptionDetails>
          {data?.createdAt && formatDateWithFallback(data?.createdAt || '')}
        </DescriptionDetails>
      </div>
    </DescriptionListContainer>
  );
};

export default CompanyInfoDetails;
