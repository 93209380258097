import React from 'react';
import { createRoot } from 'react-dom/client';
import './i18n';
import App from './App';
import {
  ENABLE_MOCKS,
  IS_TEST,
  ENVIRONMENT,
  SENTRY_DSN,
  SENTRY_SAMPLE_RATE,
  SENTRY_ENABLE_LOCALHOST,
} from './config';

import * as Sentry from '@sentry/react';
import { browserTracingIntegration } from '@sentry/browser';

const isLocalhost = Boolean(window.location.hostname === 'localhost');

if (!IS_TEST && !ENABLE_MOCKS && (!isLocalhost || SENTRY_ENABLE_LOCALHOST) && SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    tracesSampleRate: SENTRY_SAMPLE_RATE,
    environment: ENVIRONMENT,
    integrations: [browserTracingIntegration()],
    ignoreErrors: ['Non-Error exception captured', 'Non-Error promise rejection captured'],
  });
}

if (ENABLE_MOCKS && !IS_TEST) {
  (async () => {
    const { initializeMocks } = await import('./utils/mocks');
    await initializeMocks();
  })();
}

const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);
  root.render(<App />);
} else {
  console.error('Root container not found');
}
