import React from 'react';
import { Form, Formik } from 'formik';
import { SelectField } from '@resideo/blueprint-formik';
import { Button, useToastContext } from '@resideo/blueprint-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useRisClient } from '../../../../../hooks/useRisClient';

const UpdateEmployeeModal = ({ employee, companyId, closeModal }) => {
  const { addToast } = useToastContext();
  const { t } = useTranslation();
  const { client } = useRisClient();
  const queryClient = useQueryClient();
  const [role] = employee?.roles || [];

  const update = useMutation({
    mutationFn: async (vars: { status; role }) => {
      return client.companies.companiesUpdatePartnerUser(companyId, employee?.id, {
        roles: [vars.role],
        status: vars.status,
      });
    },
    onError: () => {
      addToast({
        toastType: 'Error',
        message: t('An error occurred. Please try again.'),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getUserById'] });
      queryClient.invalidateQueries({ queryKey: ['allPartnerUsers'] });
      closeModal();
      addToast({ toastType: 'Success', message: t('Role/Status updated') });
    },
  });

  const updateEmployee = ({ status, role }) => {
    update.mutate({ status, role });
  };

  return (
    <div>
      <strong>{t('Employee Name')}</strong>
      <br />
      {employee?.user?.firstName} {employee?.user?.lastName}
      <Formik
        initialValues={{
          status: employee?.status || '',
          role,
        }}
        onSubmit={updateEmployee}>
        <Form>
          <div style={{ display: 'flex', margin: '25px 0', gap: '25px' }}>
            <div style={{ flexGrow: 1 }}>
              <SelectField label={t('Role')} name='role' version='v2'>
                <option value='COMPANY_ADMIN'>Company Admin</option>
                <option value='TECHNICIAN'>Technician</option>
                <option value='OWNER'>Owner</option>
              </SelectField>
            </div>
            <div style={{ flexGrow: 1 }}>
              <SelectField label={t('Status')} name='status' version='v2'>
                <option value='ACTIVE'>Active</option>
                <option value='ARCHIVED'>Archived</option>
              </SelectField>
            </div>
          </div>

          <div style={{ display: 'flex', justifyContent: 'right' }}>
            <Button
              variant='secondary'
              type='button'
              onClick={() => closeModal()}
              style={{ marginRight: '15px' }}>
              Cancel
            </Button>

            <Button type='submit' variant='primary'>
              Update
            </Button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default UpdateEmployeeModal;
