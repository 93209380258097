import React from 'react';
import styled from 'styled-components';

const Header = styled.header<{ $sticky?: boolean }>`
  position: ${({ $sticky }) => ($sticky ? 'sticky' : 'relative')};
  background-color: #fff;
  top: 0;
  margin-bottom: 22px;
`;

const BreadcrumbsContainer = styled.div`
  min-height: 22px;
  padding: 0 32px;
  display: flex;
  align-items: center;
`;

const HeaderContainer = styled.div`
  padding: 0 32px;
  min-height: 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 22px;
`;

const Title = styled.h1`
  display: inline-block;
  margin: 0;
`;

const SecondaryNavigationContainer = styled.div`
  margin: 0 32px;

  ul {
    margin: 0;
  }
`;

const Border = styled.div`
  margin: 0 16px;
  font-size: 0;
  height: 0;
  border-bottom: 1px solid #dadada;
`;

const PageHeader = ({
  breadcrumbs,
  title,
  controls,
  secondaryNavigation,
  sticky = false,
  hideBorder = false,
  ...rest
}: {
  breadcrumbs?: any;
  title?: any;
  controls?: any;
  secondaryNavigation?: any;
  sticky?: boolean;
  hideBorder?: boolean;
}) => {
  return (
    <Header $sticky={sticky} {...rest}>
      <BreadcrumbsContainer data-test-breadcrumbs>{breadcrumbs}</BreadcrumbsContainer>
      <HeaderContainer>
        <Title>{title}</Title>
        <div>{controls}</div>
      </HeaderContainer>
      <SecondaryNavigationContainer>{secondaryNavigation}</SecondaryNavigationContainer>
      {!hideBorder && <Border />}
    </Header>
  );
};

export default PageHeader;
