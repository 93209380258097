import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

import ResideoKnockoutLogo from './resideo-knockout-logo.svg?react';
import ResideoCentralLogo from './resideo_central-04.svg?react';

const StyledHeader = styled.header`
  width: 100%;
  height: 60px;
  background-color: #3c3c3c;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: 2;
`;

const StyledLogo = styled.div`
  display: flex;
  align-items: center;
`;

const StyledResideoKnockoutLogo = styled(ResideoKnockoutLogo)`
  fill: #d22730;
  background-color: white;
  margin-left: 30px;
`;

const StyledResideoCentralLogo = styled(ResideoCentralLogo)`
  margin-left: 12px;
  height: 24px;
`;

const Header: FC<{
  primaryNav: ReactNode;
}> = ({ primaryNav }): JSX.Element => {
  return (
    <StyledHeader>
      <StyledLogo>
        <StyledResideoKnockoutLogo />
        <StyledResideoCentralLogo />
      </StyledLogo>
      {primaryNav}
    </StyledHeader>
  );
};

export default Header;
