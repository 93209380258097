import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { Text, TableV2, useToastContext, useModal, Flex, Button } from '@resideo/blueprint-react';
import './CustomerDetailsStyles.css';
import { CustomerAccountModals as Modals } from '../types';
import { RemoveDeviceModal } from './DeviceModals/RemoveDeviceModal';
import { StyledWarningIcon } from './DeviceModals/AddDeviceModal';
import styled from 'styled-components';
import { ADD_DEVICE_BASE_URL, ENABLE_ADD_DEVICE_AND_RESEND_INVITE } from 'config';
import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { useRisClient } from 'hooks/useRisClient';

interface AccountDevicesType {
  locationData?: any;
  customerData?: any;
  openModal?: any;
}

const StyledText = styled(Text)`
  line-height: 1.3rem;
`;

const ButtonContainer = styled(Flex)`
  flex-direction: row;
  gap: 16px;
  width: 100%;
  justify-content: flex-end;
  padding-right: 20px;
  padding-top: 35px;
`;

const WarningContainer = styled(Flex)`
  gap: 16px;
  align-items: center;
  margin-bottom: 24px;
`;

const CustomerAccountDevices: React.FC<AccountDevicesType> = ({ locationData, customerData }) => {
  const { id = '' } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const { addToast } = useToastContext();
  const navigate = useNavigate();
  const { client } = useRisClient();
  const queryClient = useQueryClient();

  const { Modal, openModal: _openModal, closeModal } = useModal({
    newDesign: true,
  });
  const [activeModal, setActiveModal] = useState(Modals.RemoveDevice);
  const [deviceToDelete, setDeviceToDelete] = useState<
    { deviceId: string; typeName: string } | undefined
  >();

  const [deviceDeletingId, setDeviceDeletingId] = useState('');

  const openModal = (version: Modals) => {
    setActiveModal(version);
    _openModal();
  };

  const { data: companyData } = useSuspenseQuery({
    queryKey: ['getPartnerAccount'],
    queryFn: async () => {
      const res = await client.companies.companiesGetCompany(id);
      return res?.data;
    },
  });

  const deleteDeviceFromPartnerDeviceLocation = useMutation({
    mutationKey: ['deleteDeviceFromPartnerDeviceLocation'],
    mutationFn: async (variables: { partnerDeviceLocationId: string; deviceId: string }) => {
      await client.companies.companiesDeleteDeviceFromPartnerDeviceLocation(
        // TODO will need to replace this when we switch out the companyData query
        companyData?.id as string,
        variables?.partnerDeviceLocationId as string,
        variables?.deviceId as string,
      );
    },
    onError: () => {
      addToast({
        toastType: 'Error',
        message: t('An error occurred. Please try again.'),
      });
    },
    onSuccess: () => {
      // TODO: invalidate query coming from CustomerLocations.tsx once that's in
      queryClient.invalidateQueries({ queryKey: ['getPartnerCustomer'] });
      closeModal();
      navigate(`${window.location.pathname}`);
      addToast({
        toastType: 'Success',
        message: t('Device Removed'),
      });
    },
  });

  const handleRemoveDevice = () => {
    deleteDeviceFromPartnerDeviceLocation.mutate({
      partnerDeviceLocationId: locationData?.id,
      deviceId: deviceDeletingId,
    });
  };

  const deviceNewInput = useMutation({
    mutationFn: async (vars: { macId }) => {
      return client.companies.companiesPerformDeviceHandoff({
        deviceId: vars.macId,
        firstName: customerData?.firstName,
        lastName: customerData?.lastName,
        sendToEmail: customerData?.email,
        templateLanguage: 'en',
        baseUrl: ADD_DEVICE_BASE_URL,
        partnerAccountId: id,
        partnerDeviceLocationId: locationData.id,
      });
    },
    onError: () => {
      addToast({
        toastType: 'Error',
        message: t('An error occurred. Please try again.'),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getPartnerCustomer'] });
      addToast({
        toastType: 'Success',
        message: t('Invitation resent'),
      });
    },
  });

  const resendInvite = macId => {
    deviceNewInput.mutate({ macId: macId });
  };

  const getModalProps = v => {
    const versions = {
      [Modals.RemoveDevice]: {
        title: t('Remove Device From Location'),
        children: (
          <RemoveDeviceModal
            onCancel={closeModal}
            onSubmit={handleRemoveDevice}
            deviceData={deviceToDelete}
            locationData={locationData}
          />
        ),
      },
      [Modals.ResendInvite]: {
        title: t('Unable to Resend Invite'),
        children: (
          <Flex
            data-test-device-mismatch-error-message
            flexDirection='column'
            alignItems='left'
            width={[1]}>
            <WarningContainer>
              <StyledWarningIcon />
              <Text fontSize='large' fontWeight='bold'>
                {t('Data Mismatch Detected')}
              </Text>
            </WarningContainer>
            <StyledText marginBottom={['medium']}>
              {t(
                "The Device ID or Customer Email on file does not match our records. If the problem persists, verify both the Device ID and Resideo ID email used to sign in matches the Pro's record in this application.",
              )}
            </StyledText>
            <StyledText>
              {t(
                'The email and Device ID records must match in order to resend an invite to a customer.',
              )}
            </StyledText>
            <ButtonContainer>
              <Button variant='primary' onClick={() => closeModal()}>
                {t('Ok')}
              </Button>
            </ButtonContainer>
          </Flex>
        ),
      },
    };

    return versions[v];
  };

  const DeviceTypes = [
    {
      deviceTypeDisplayName: t('L1 Wifi Water Leak & Freeze Detector'),
      deviceSku: 'CHW3610W8001/U',
      deviceType: 'homes.d.l1smartwatersensor',
    },
    {
      deviceTypeDisplayName: t('L2 Wifi Water Sensor & Switch'),
      deviceSku: 'RWLD3001-001/U',
      deviceType: 'homes.d.l1smartwatersensor',
    },
    {
      deviceTypeDisplayName: t('L5 Leak Protection Valve'),
      deviceSku: 'WS0100',
      deviceType: 'homes.d.l5leakprotectionvalve',
    },
    {
      deviceTypeDisplayName: t('T5/T6 Thermostat'),
      deviceSku: 'RCHT8612WF2005',
      deviceType: 'homes.d.t6trade',
    },
    {
      deviceTypeDisplayName: t('T9/T10 or T10+Thermostat'),
      deviceSku: 'RCHT9610WFW2004',
      deviceType: 'homes.d.t10',
    },
    {
      deviceTypeDisplayName: t('VX1 Video Doorbell'),
      deviceSku: 'CAMW-WDB',
    },
    {
      deviceTypeDisplayName: t('VX3 HD Outdoor Camera'),
      deviceSku: 'CAMWE-WO',
    },
  ];

  // Getting device-type based on sku from response
  const getDeviceType = deviceType => {
    if (deviceType !== 'homes.d.l1smartwatersensor') {
      // As both L1 & L2 devices have same device type, we cannot distinguish between those 2 device names to display so for now displaying this default value
      return t('Connected Device');
    }
    const deviceTypeObject: any = DeviceTypes.filter(device => {
      return device.deviceType === deviceType;
    });

    if (deviceTypeObject.length > 0) {
      return deviceTypeObject[0].deviceTypeDisplayName;
    }
    return t('Connected Device');
  };

  const getRows = () => {
    return locationData?.devices?.map(item => {
      if (item) {
        const { __typename, deviceId, id, deviceType } = item;
        let typeName = __typename;
        let status = '';
        if (deviceId === null || deviceId === 'NULL') {
          typeName = t('Unknown');
          status = t('Pending');
        } else {
          typeName = getDeviceType(deviceType);
          status = t('Confirmed');
        }
        const actions: any = [];
        if (ENABLE_ADD_DEVICE_AND_RESEND_INVITE) {
          actions.push({
            name: t('Resend App Invitation'),
            ctaTag: t('Resend App Invitation'),
            text: t('Resend App Invitation'),

            action: () => {
              resendInvite(deviceId);
            },
          });
        }
        actions.push({
          name: t('Remove'),
          ctaTag: t('Remove'),
          text: t('Remove'),

          action: () => {
            setDeviceToDelete({ deviceId, typeName });
            setDeviceDeletingId(id);
            openModal(Modals.RemoveDevice);
          },
        });
        return {
          typeName,
          deviceId,
          status,
          actions,
        };
      }
      // adding in an empty row if item is null, otherwise it breaks
      if (item === null) {
        return {};
      }
    });
  };

  return (
    <>
      <div className='CustomerAccountDevices'>
        {locationData?.devices?.length ? (
          <TableV2
            dataTestAttribute='data-test-customer-devices'
            actionsButtonName={t('Options')}
            headers={[
              {
                displayName: t('Device Type'),
                isSortable: false,
              },
              {
                displayName: t('ID'),
                isSortable: false,
              },
              {
                displayName: t('Status'),
                isSortable: false,
              },
              { displayName: '', isSortable: false },
            ]}
            rows={getRows()}
          />
        ) : (
          <Text data-test-no-devices>
            {t('There are no devices associated with this location.')}
          </Text>
        )}
        <Modal {...getModalProps(activeModal)} />
      </div>
    </>
  );
};

export default CustomerAccountDevices;
