import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text, Button, Flex } from '@resideo/blueprint-react';
import styled from 'styled-components';

export const ButtonContainer = styled(Flex)`
  flex-direction: row;
  gap: 16px;
  width: 100%;
  justify-content: flex-end;
  padding-right: 20px;
  padding-top: 35px;
  border-top: 1px solid rgb(218, 218, 218);
`;

export const ModalContent = styled(Flex)`
  line-height: 24px;
  flex-direction: column;
`;

export const UnableToRemoveCustomerModal = ({ onCancel }) => {
  const { t } = useTranslation();

  return (
    <ModalContent>
      <Text>{t('Unable to Remove Customer Account')}</Text>
      <ButtonContainer>
        <Button variant='primary' onClick={onCancel}>
          {t('Ok')}
        </Button>
      </ButtonContainer>
    </ModalContent>
  );
};
