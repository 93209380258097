import React from 'react';
import { FlagsProvider } from 'flagged';
import { HOME_HERO_ONE_UPDATES } from 'config';
import './styles/fonts.css';
import './styles/app.css';
import { ThemeProvider, ToastContextProvider } from '@resideo/blueprint-react';
import colors from './themes/light';
import { ScrollToTop } from './SharedComponentsModule/ScrollToTop';
import SentryUser from './SharedComponentsModule/SentryUser';
import { GlobalStyle } from '@resideo/blueprint-react/dist/components/GlobalStyle/GlobalStyle';
import AuthProvider from 'context/AuthProvider';
import { Outlet } from 'react-router-dom';

const Root = () => {
  return (
    <AuthProvider>
      <ScrollToTop />
      <SentryUser />
      <FlagsProvider
        features={{
          homeHeroOneUpdates: HOME_HERO_ONE_UPDATES,
        }}>
        <ThemeProvider theme={colors}>
          <GlobalStyle />
          <ToastContextProvider marginTop={'60px'}>
            <Outlet />
          </ToastContextProvider>
        </ThemeProvider>
      </FlagsProvider>
    </AuthProvider>
  );
};

export default Root;
