import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { urlEnsureHttps } from 'utils';
import CompanyForm, { CompanyFormValues } from './CompanyInfoForm';
import { useToastContext, useVerificationModal } from '@resideo/blueprint-react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRisClient } from 'hooks/useRisClient';
import {
  PartnerType,
  UpdatePartnerAccountModel,
} from '@resideo/web-integration-services-api-client';
import { parseGeoAddress } from '../../../utils/common';
import { AddressType } from '../CustomersComponent/types';

const CompanyEditForm = ({ data, onCancel }) => {
  const { id = '' } = useParams<{ id: string }>();
  const { addToast } = useToastContext();
  const { t } = useTranslation();
  const { client } = useRisClient();
  const queryClient = useQueryClient();
  const [suggestedAddress, setSuggestedAddress] = useState<AddressType>({
    address1: '',
    address2: '',
    city: '',
    country: '',
    state: '',
    zip: '',
    locationName: '',
  });
  const [locationValues, setLocationValues] = useState<any>({});
  const {
    openAddressVerificationModal,
    closeAddressVerificationModal,
    AddAddressVerificationModal,
  } = useVerificationModal();

  const {
    status = '',
    name = '',
    shipToAddress = {},
    primaryPhoneNumber = '',
    websiteUrl = '',
    partnerType = [],
  } = data;
  const [primaryType, secondaryType] = partnerType;

  const initialValues: CompanyFormValues = {
    status,
    name,
    companyPhone: primaryPhoneNumber,
    address1: shipToAddress?.addressLine1 || '',
    address2: shipToAddress?.addressLine2 || '',
    city: shipToAddress?.city || '',
    state: shipToAddress?.stateProvinceRegionCode || '',
    zip: shipToAddress?.zipPostalCode || '',
    country: shipToAddress?.countryCode || 'US',
    websiteUrl: websiteUrl == 'https://' ? '' : websiteUrl,
    primaryBusinessType: primaryType || '',
    secondaryBusinessType: secondaryType || '',
  };

  const editCompanyInfo = useMutation({
    mutationKey: ['editCompanyInfo'],
    mutationFn: async ({ id, variables }: { id: string; variables: UpdatePartnerAccountModel }) => {
      await client.companies.companiesUpdatePartnerAccount(id, variables);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['GetCompany'] });
      onCancel();
      addToast({
        toastType: 'Success',
        message: t('Changes saved'),
      });
    },
    onError: () => {
      addToast({
        toastType: 'Error',
        message: t('Something went wrong!'),
      });
    },
  });

  const saveChanges = (values?: any, isSaveButtonClicked = false) => {
    let apiValues: any = {};
    if (!isSaveButtonClicked) {
      apiValues = values;
    } else {
      apiValues = values;
      apiValues.status = locationValues.status;
      apiValues.companyPhone = locationValues.companyPhone;
      apiValues.websiteUrl = locationValues.websiteUrl;
      apiValues.primaryBusinessType = locationValues.primaryBusinessType;
      apiValues.secondaryBusinessType = locationValues.secondaryBusinessType;
      apiValues.name = locationValues.name;
    }
    const {
      status,
      name,
      country,
      address1,
      address2,
      city,
      state,
      zip,
      companyPhone,
      websiteUrl,
      primaryBusinessType,
      secondaryBusinessType,
    } = apiValues;

    const partnerTypes: PartnerType[] = [];
    if (primaryBusinessType) partnerTypes.push(primaryBusinessType);
    if (secondaryBusinessType) partnerTypes.push(secondaryBusinessType);

    editCompanyInfo.mutate({
      id,
      variables: {
        status: status || 'PENDING',
        name,
        primaryPhoneNumber: companyPhone,
        websiteUrl: websiteUrl ? urlEnsureHttps(websiteUrl) : '',
        shipToAddress: {
          addressLine1: address1,
          addressLine2: address2,
          city: city,
          stateProvinceRegionCode: state,
          zipPostalCode: zip,
          countryCode: country,
        },
        partnerTypes,
      },
    });
  };

  const handleUpdateLocation = async values => {
    const {
      geoAddress,
      result,
      isLocationTypeRoofTop,
      partialMatch,
      shortNames,
    } = await parseGeoAddress(values);

    // check the response structure for geoAddress
    if ((result && isLocationTypeRoofTop && !partialMatch) || !geoAddress) {
      saveChanges(values);
    } else {
      // updating the formatted address similar to original address so that we can use the same to make the API call
      setSuggestedAddress(shortNames);
      openAddressVerificationModal();
    }
  };

  return (
    <>
      <CompanyForm
        initialValues={initialValues}
        onSubmit={(values, autoCompleteSelected) => {
          if (autoCompleteSelected) {
            saveChanges(values, true);
          } else {
            handleUpdateLocation(values);
            values.locationName = values.name;
            setLocationValues(values);
          }
        }}
        onCancel={onCancel}
      />
      <AddAddressVerificationModal
        suggestedAddress={suggestedAddress}
        originalAddress={locationValues}
        closeAddressVerificationModal={closeAddressVerificationModal}
        backButtonClicked={() => {
          closeAddressVerificationModal();
        }}
        saveButtonClicked={values => {
          saveChanges(values, true);
          closeAddressVerificationModal();
        }}
        translations={{
          ModalTitle: t('Edit Company Info'),
          ModalHeader: t('Verification'),
          ModalText: t('We are not able to verify the address you provided.'),
          ModalChooseText: t('Choose one of the following:'),
          OriginalAddressLabel: t('Original Address'),
          SuggestedAddressLabel: t('Suggested Address'),
          BackBtnLabel: t('Back'),
          SaveBtnLabel: t('Save'),
        }}
      />
    </>
  );
};

export default CompanyEditForm;
