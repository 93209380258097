import React from 'react';
import { useTranslation } from 'react-i18next';
import { TableV2, useToastContext } from '@resideo/blueprint-react';

import './CustomerDetailsStyles.css';
import { useFeature } from 'flagged';
import { DeactivateServiceModal } from './ServiceModals/DeactivateServiceModal';
import { formatDateTime } from 'utils/common';
import { useRisClient } from 'hooks/useRisClient';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useUser } from '../CustomerLookupComponent/useUser';
import {
  ActiveStatus,
  PartnerDeviceServiceType,
} from '@resideo/web-integration-services-api-client/dist';
interface AccountServicesType {
  data?: any;
  openModal?: any;
  partnerBrandProfile?: any;
  closeModal?: any;
  location?: any;
  hasActivePartnerBrandProfile?: boolean;
}

const CustomerAccountServices: React.FC<AccountServicesType> = ({
  data,
  openModal,
  hasActivePartnerBrandProfile,
  closeModal,
  location,
}) => {
  const { t } = useTranslation();
  const isHomeHeroOneUpdates = useFeature('homeHeroOneUpdates');
  const DeviceServiceStatusNameMap = {
    ACTIVE: t('Active'),
    INACTIVE: t('Inactive'),
  };
  const { client } = useRisClient();
  const queryClient = useQueryClient();
  const [{ partnerAccountId }] = useUser();

  const DeviceServiceNameMap = {
    BrandingPartnerDeviceService: t('Homeowner App Branding'),
  };

  const aggregateAppBrandingDeviceServices = () => {
    if (!hasActivePartnerBrandProfile) {
      return null;
    }

    const brandingServices = hasActivePartnerBrandProfile
      ? [...data].filter(val => val.type === 'BrandingPartnerDeviceService')
      : [];

    if (!brandingServices || brandingServices.length === 0) return null;

    //
    const activeBrandingDeviceServices = brandingServices
      .filter(serviceEdge => serviceEdge?.status === 'ACTIVE')
      .sort((a, b) => {
        return new Date(a?.updatedAt).getTime() - new Date(b?.updatedAt).getTime();
      });

    const inactiveBrandingDeviceServices = brandingServices
      .filter(serviceEdge => serviceEdge?.status === 'INACTIVE')
      .sort((a, b) => {
        return new Date(a?.updatedAt).getTime() - new Date(b?.updatedAt).getTime();
      });

    if (activeBrandingDeviceServices && activeBrandingDeviceServices.length > 0) {
      return activeBrandingDeviceServices[0];
    } else if (inactiveBrandingDeviceServices && inactiveBrandingDeviceServices.length > 0) {
      return inactiveBrandingDeviceServices[0];
    } else {
      return null;
    }
  };

  const activateDeviceService = useMutation({
    mutationKey: ['activateBrandingPartnerDeviceService'],
    mutationFn: async (variables: { partnerDeviceLocationId: string; deviceId: string }) => {
      await client.companies.companiesUpdatePartnerDeviceService(
        partnerAccountId as string,
        variables?.partnerDeviceLocationId as string,
        {
          type: PartnerDeviceServiceType.BRANDING_PARTNER_DEVICE_SERVICE,
          status: ActiveStatus.ACTIVE,
          deviceId: variables?.deviceId,
        },
      );
    },
    onError: () => {
      addToast({ toastType: 'error', message: t('Something went wrong!') });
    },
    onSuccess: () => {
      // TODO: invalidate query coming from CustomerLocations.tsx once that's in
      queryClient.invalidateQueries({ queryKey: ['getPartnerCustomer'] });
      addToast({
        toastType: 'success',
        message: t('Service reactivated'),
      });
    },
  });

  const { addToast } = useToastContext();

  const handleReactivateDeviceService = deviceId => {
    activateDeviceService.mutate({
      partnerDeviceLocationId: location?.id,
      deviceId: deviceId,
    });
  };

  const getDeviceServiceRows = () => {
    const appBrandingDeviceService = aggregateAppBrandingDeviceServices();

    if (!appBrandingDeviceService) return [];

    const { type, status, updatedAt, device } = appBrandingDeviceService;

    const appBrandingDeviceServiceDisplay = {
      serviceType: DeviceServiceNameMap[type],
      serviceStatus:
        status === 'ACTIVE'
          ? `${DeviceServiceStatusNameMap[status]} (${t('Activation Date')}: ${formatDateTime(
              updatedAt,
              {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              },
            )})`
          : `${DeviceServiceStatusNameMap[status]} (${t('Deactivation Date')}: ${formatDateTime(
              updatedAt,
              {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              },
            )})`,
    };
    if (status === 'ACTIVE' && isHomeHeroOneUpdates) {
      appBrandingDeviceServiceDisplay['actions'] = [
        {
          name: t('Deactivate'),
          ctaTag: t('Deactivate'),
          text: t('Deactivate'),
          action: () => {
            openModal({
              title: t('Deactivate Service'),
              children: (
                <DeactivateServiceModal
                  serviceName={DeviceServiceNameMap[type]}
                  location={location}
                  closeModal={closeModal}
                  deviceId={device?.id}
                />
              ),
            });
          },
        },
      ];
    }

    if (status === 'INACTIVE' && isHomeHeroOneUpdates) {
      appBrandingDeviceServiceDisplay['actions'] = [
        {
          name: t('Activate'),
          ctaTag: t('Activate'),
          text: t('Activate'),
          action: () => {
            handleReactivateDeviceService(device?.id);
          },
        },
      ];
    }
    return [appBrandingDeviceServiceDisplay];
  };

  return (
    <div data-test-customers-list>
      {!hasActivePartnerBrandProfile && (
        <p>{t('There are no services associated with this location.')}</p>
      )}
      {hasActivePartnerBrandProfile && (
        <div style={{ paddingRight: '30px' }}>
          <TableV2
            dataTestAttribute='data-test-services-table'
            actionsButtonName={t('Options')}
            headers={[
              { displayName: t('Service Type'), isSortable: false },
              {
                displayName: t('Service Status'),
                isSortable: false,
              },
              {
                displayName: '',
                isHiddenColumn: !isHomeHeroOneUpdates,
                isSortable: false,
              },
            ]}
            rows={getDeviceServiceRows()}
          />
        </div>
      )}
    </div>
  );
};

export default CustomerAccountServices;
