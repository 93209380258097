import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { useIdleTimer } from '../hooks/useIdleTimer';
import config, { ENABLE_MOCKS } from 'config';
import { MockAuth0Provider } from 'utils/auth/MockAuth0Provider';

const IdleLogout = ({ children }) => {
  const { logout } = useAuth0();

  //30 minutes
  useIdleTimer(30 * 60 * 1000, () => {
    window.sessionStorage.clear();
    window.localStorage.clear();
    logout({ returnTo: window.location.origin });
  });
  return children;
};

const AuthProvider = ({ children }: { children: any }) => {
  const navigate = useNavigate();
  const onRedirectCallback = appState => {
    navigate(appState?.returnTo || window.location.pathname, { replace: true });
  };
  if (ENABLE_MOCKS) {
    return <MockAuth0Provider>{children}</MockAuth0Provider>;
  } else {
    return (
      <Auth0Provider
        audience={config.audience}
        domain={config.domain}
        clientId={config.clientId}
        onRedirectCallback={onRedirectCallback}
        redirectUri={window.location.origin}
        scope={config.scope}
        useRefreshTokens={true}>
        <IdleLogout>{children}</IdleLogout>
      </Auth0Provider>
    );
  }
};

export default AuthProvider;
