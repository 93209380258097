import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import './CompanyInfoStyles.css';
import { Box, Button, Flex } from '@resideo/blueprint-react';
import { useTranslation } from 'react-i18next';
import { FaPen } from 'react-icons/fa';
import CompanyInfoDetails from './CompanyInfoDetails';
import CompanyInfoEditForm from './CompanyInfoEditForm';
import { useCompanyDetailsContext } from '..';

const PenIcon = styled(FaPen)`
  margin-right: 8px;
`;

const CompanyInfo = () => {
  const { t } = useTranslation();
  const [editMode, setEditMode] = useState(false);

  const { setShowHeader: headerVisible, partnerAccount } = useCompanyDetailsContext();

  useEffect(() => {
    //show sticky header if on edit mode, otherwise hide it
    if (headerVisible) {
      headerVisible(!editMode);
    }
  }, [editMode]);

  return (
    <div>
      {editMode ? (
        <CompanyInfoEditForm data={partnerAccount} onCancel={() => setEditMode(false)} />
      ) : (
        <Box marginX='large'>
          <Flex className='subheader'>
            <h3 color='#303030'>{t('Company Info')}</h3>
            <Button paddingRight='0rem' variant='tertiary' onClick={() => setEditMode(true)}>
              <PenIcon />
              {t('Edit')}
            </Button>
          </Flex>

          <CompanyInfoDetails data={partnerAccount} />
        </Box>
      )}
    </div>
  );
};

export default CompanyInfo;
