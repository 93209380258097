import React, { useState } from 'react';
import { TableV2, Box } from '@resideo/blueprint-react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import '../../SharedComponentsModule/TooltipStyle.css';
import { useIsMounted } from 'hooks/useIsMounted';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useRisClient } from 'hooks/useRisClient';

const MAX_PAGE_LOAD = 10;

const StyledLink = styled.a`
  text-decoration: none;
  color: #1c6fb9;
`;

const ServicesTable = () => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const { client } = useRisClient();
  const [orderBy, setOrderBy] = useState<any>();
  const [pagination, setPagination] = useState<any>({
    first: MAX_PAGE_LOAD,
    last: undefined,
    before: undefined,
    after: undefined,
  });

  const { data, error } = useSuspenseQuery({
    queryKey: ['programProductsTable', { orderBy, pagination }],
    queryFn: async () => {
      return client.core.coreSearchPartnerProgramProducts(
        JSON.stringify(undefined),
        JSON.stringify(orderBy),
        pagination.first,
        pagination.last,
        pagination.before,
        pagination.after,
      );
    },
  });

  const generateRows = () => {
    return (
      data?.data?.map(service => {
        return {
          name: <StyledLink href={`/services/${service?.id}`}>{service?.name}</StyledLink>,
          sku: service?.sku,
          offering: service?.programOffering,
          variation: service?.variation,
          billingCycle: service?.billingCycle,
        };
      }) ?? []
    );
  };

  const pageInfo = data?.pageInfo;

  return (
    <Box marginBottom='large'>
      <TableV2
        dataTestAttribute='data-test-services-table'
        actionsButtonName={t('Options')}
        headers={[
          {
            displayName: t('Name'),
            isSortable: false,
            onSortColumnName: 'name',
          },
          {
            displayName: 'SKU',
            isSortable: false,
          },
          {
            displayName: t('Offering'),
            isSortable: false,
            onSortColumnName: 'programOffering',
          },
          {
            displayName: t('Variation'),
            isSortable: false,
            onSortColumnName: 'variation',
          },
          {
            displayName: t('Billing Cycle'),
            isSortable: false,
            onSortColumnName: 'billingCycle',
          },
        ]}
        isError={
          error
            ? {
                errorHeader: t('Data Unavailable'),
                errorMessage: t('Table data unable to load. Try again later.'),
              }
            : undefined
        }
        rows={generateRows()}
        onSort={(col, dir) => {
          if (col && isMounted()) {
            setOrderBy({ [col]: dir });
          }
        }}
        hasPagination
        hasNext={pageInfo?.hasNextPage}
        hasPrevious={pageInfo?.hasPreviousPage}
        nextButtonLabel={t('Next')}
        previousButtonLabel={t('Previous')}
        onPaginationNextClick={() => {
          setPagination({
            after: pageInfo?.endCursor,
            before: null,
            first: MAX_PAGE_LOAD,
            last: null,
          });
        }}
        onPaginationPrevClick={() => {
          setPagination({
            after: null,
            before: pageInfo?.startCursor,
            first: null,
            last: MAX_PAGE_LOAD,
          });
        }}></TableV2>
    </Box>
  );
};

export default ServicesTable;
